import React, { useEffect, useState } from 'react';
import { RiFootballFill } from 'react-icons/ri';
import { getCaller } from '../../services/api';
import ErrorBoundary from '../../error/ErrorBoundary';
import '../eventManage/eventmanage.css';
import LeagueDetails from './leagueFromSport/LeagueDetails';

const HorseRacingManagement = () => {
  const [eventTab, setEventTab] = useState(null);
  const [getSportData, setGetSportData] = useState([]);
  const [leagueData, setLeagueData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCaller('api/v1/racing/sports');
        if (res?.data?.length) {
          setGetSportData(res.data);
          handleCountryVenueData(res.data[0]);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching sports data:", error);
      }
    };
    fetchData();
  }, []);

  const handleCountryVenueData = async (sport) => {
    setLoading(true);
    try {
      const res = await getCaller(`api/v1/racing/venue/country/all?sportId=${sport.id}`);
      setLeagueData(res.data || []);
      setEventTab(sport.id);
    } catch (error) {
      console.error("Error fetching venue data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="wrapper-head">
        <h3>RACING EVENTS MANAGEMENT</h3>
      </div>
      <div className="event-container">
        {getSportData.length ? (
          getSportData.map((sport) => (
            <div
              key={sport.id}
              className={`event-tab ${eventTab === sport.id ? 'active-event-tab' : ''}`}
              onClick={() => handleCountryVenueData(sport)}
            >
              <div className="event-body">
                <RiFootballFill style={{ fontSize: "1.5rem" }} />
                <p>{sport.name}</p>
              </div>
            </div>
          ))
        ) : (
          <ErrorBoundary />
        )}
      </div>
      <div className="event-content">
        {loading ? (
          <div className="loader-wrapper">
            <div className='loader'></div>
          </div>
        ) : (
          <LeagueDetails leagueData={leagueData} setLeagueData={setLeagueData} sportId={eventTab} />
        )}
      </div>
    </div>
  );
};

export default HorseRacingManagement;