/**
 * Convert utc into local timezone
 *
 * @param {string} inputTimeString - UTC timezone date in yyyy-mm-dd hh:m:s
 * @returns {string} Local timezone date in yyyy-mm-dd hh:m:s
 */
function timeConversion(inputTimeString) {
    const utcDate = new Date(inputTimeString + 'Z');
    const localOffset = utcDate.getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - (localOffset * 60000));
    const formattedLocalTime = localDate.toISOString().slice(0, 19).replace('T', ' ');
    return formattedLocalTime;

}

export default timeConversion