import '../customGames/custom.css';
import { motion } from "framer-motion";
import CustomGamesTab from './CustomGamesTab';
import { useNavigate } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import Button from '../../component/button/Button';
import { getCaller, updateCaller } from '../../services/api';
import Pagination from '../../component/pagination/Pagination';
import { showDeleteMessage, showConfirmationDialog, showToast } from "../../utilities/toastHelper";

const CustomLeague = () => {
    const navigate = useNavigate();
    const [customLeague, setCustomLeague] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchedPages, setFetchedPages] = useState({});
    const limit = 100;

    const getCustomLeague = async (page = 1) => {
        if (fetchedPages[page]) {
            setCustomLeague(fetchedPages[page]);
            return;
        }
        const res = await getCaller(`api/v1/leagues/showLeagues?custom=1&page=${page}&limit=${limit}`);
        const filteredData = res.data?.filter((el) => el.is_custom === 1);

        setCustomLeague(filteredData);
        setTotalPage(res?.pagination?.totalPages);
        setFetchedPages((prev) => ({ ...prev, [page]: filteredData }));
    };

    useEffect(() => {
        getCustomLeague();
    }, []);

    const handlePageChange = async(page) => {
        setCurrentPage(page);
        await getCustomLeague(page);
    };

    const deleteLeague = async (e) => {
        const result = await showConfirmationDialog(`You want to delete this league (${e.name})?`);
   
        if (result.isConfirmed) {
            const res = await updateCaller(`api/v1/leagues/deleteLeagues?id=${e.id}`);

            if (res.success === true) {
                showDeleteMessage("Your league deleted successfully!");
                setTimeout(() => {
                    setCustomLeague((prev) => prev.filter((el) => el.id !== e.id));
                }, 0);
            } else {
                showToast(res.message, true);
            }
        }
    };
    
    return (
        <>
            <CustomGamesTab/>
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className='table-border'>
                    <div className="wrapper-body">
                        <div className="wrapper-content">
                            <h3> Custom League </h3>
                            <div className="custom-btn">
                                <Button className={'submit'} name={'ADD LEAGUE'} type={'type'} onClick={()=> navigate('/custom_add_league')}/>
                            </div>
                        </div>
                    </div>

                    <table width="100%" >
                        <thead>
                            <tr className='custom-row'>
                                <th align='left'> # </th>
                                <th align='left'> SPORT NAME </th>
                                <th align='left'>LEAGUE NAME </th>
                                <th align='left'> ACTION </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customLeague?.length > 0 ? customLeague?.map((e, i) => (
                                    <tr className='custom-row' key={i}>
                                        <td data-label="#"> {e.id} </td>
                                        <td style={{textTransform:"capitalize"}} data-label="NAME"> {e.sport_name} </td>
                                        <td data-label="SPORT NAME">{e?.name}</td>
                                        <td data-label="ACTION" > 
                                            <div className='user-btn-action'>
                                                <div className='edit-btn' onClick={() => navigate('/custom_add_league', { state: { leagueData: e }})}>
                                                    <MdEdit style={{ fontSize: '1.5rem', color: 'white' }}/>
                                                </div>
                                            
                                                <div className='edit-btn delete-btn' onClick={()=> deleteLeague(e)}>
                                                    <MdDelete style={{ fontSize: '1.5rem', color: 'white' }}/>
                                                 </div>
                                            </div>
                                        </td>
                                    </tr>
                                )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange}/>
            </motion.div>
        </>
    )
};

export default CustomLeague;