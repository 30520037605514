import React from 'react'
import { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import { deleteCaller, getCaller, updateCaller, postCaller } from '../../../services/api';
import '../downline.css'
import { IoMdSave } from 'react-icons/io'
import Button from '../../../component/button/Button'
import FancyAssignAdd from './FancyAssignAdd'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Select from 'react-select';
import getLocalData from '../../../utilities/LocalStorageData.utilities';
import FancyAssignAddOdds from './FancyAssignAddOdds'
import { showConfirmationDialog, showSuccessMessage, showToast } from '../../../utilities/toastHelper';

const FancyAssignMarket = ({ userName }) => {
    const [fancyData, setFancyData] = useState([])
    const [fancyAddComponents, setFancyAddComponents] = useState([]);
    const [defaultLimits, setDefaultLimits] = useState({});
    const [resultData, setResultData] = useState(""); 
    const [resultValue, setResultValue] = useState(null);
    const [addF, setFddF] = useState(false)
    const [agentDetails, setAgentDetails] = useState({});
    const [endDate, setEndDate] = useState(null);
    const [operatorList, setOperatorList] = useState([]);
    const [operator, setOperator] = useState('');
    const [scope,setScope] = useState({})
    const [fancyAddOddsComponents, setFancyAddOddsComponents] = useState([]);

    useEffect(() => {
        const {markets, result} = getLocalData('scope')
        setScope({markets, result})
        setResultValue("");
      }, []);

    useEffect(() => {
        const getMarketList = async () => {
            setFddF(false)
            const response = await getCaller(`api/v1/fancies/custom/${userName.event_id}/type/${userName.market_id}?agent_id=${userName.id}`)
            const formattedData = response.data.map((item) => {

                let odds = typeof item.odds !== 'object'? JSON.parse(item.odds): item.odds;
                if (!Array.isArray(odds)) {
                    odds = [];
                }
                return {
                    ...item,
                    odds
                };
               
            });

            setFancyData(formattedData);
            let limits = (await getCaller(`api/v1/events/${userName.event_id}/limits/fancy`)).data;
            setDefaultLimits(limits[userName.market_id]);

        }
        getMarketList()

    }, [userName.event_id, userName.market_id, addF])

    useEffect(() => {
        const getAgentDetails = async () => {
          try {
            let agentData = await getCaller(`api/v1/users/${userName.id}`);
            setAgentDetails(agentData.data);
          } catch (error) {
    
          }
        };
    
        getAgentDetails();
      }, [])

      const handleSuspendAll = async (field, id, status) => {
        let updateResponse = await updateCaller(`api/v1/users/updateUser/${id}`, { [field]: status })
        let message = updateResponse.success ? status ? 'Enabled' : 'Disabled' : 'Failed!';
    
        if(updateResponse.success ){
            if(userName?.match_id){
              let updateFancyResponse = await updateCaller(`api/v1/fancies/updateFancy/${userName.event_id}/${id}`, { ['is_sus']: status });
              if(updateFancyResponse.success){
                if(userName?.event_id && userName?.market_id){
                    const response = await getCaller(`api/v1/fancies/custom/${userName.event_id}/type/${userName.market_id}?agent_id=${userName.id}`)
                    const formattedData = response.data.map((item) => {
                        let odds = typeof item.odds !== 'object'? JSON.parse(item.odds): item.odds;
                        if(!Object.keys(odds).length){
                            odds = {
                                runNo: item.odds.runNo || 0,
                                runYes: item.odds.runYes || 0,
                                oddsNo: item.odds.oddsNo || 0,
                                oddsYes: item.odds.oddsYes || 0,
                                size: item.odds.oddsYes || 0,
                                back: item.odds.back || 0,
                                lay: item.odds.lay || 0,
                            }
                        }
                        return ({
                            ...item,
                            odds: odds
                        })
                    });
        
                    setFancyData(formattedData);
        
                          let limits = (await getCaller(`api/v1/events/${userName.event_id}/limits/fancy`)).data;
                          setDefaultLimits(limits[userName.market_id]);
                }
                message = status ? 'Enabled' : 'Disabled';
              } else {
                message = "Failed"
              }
              toasterMessage(updateResponse, message);
            } else {
              toasterMessage(updateResponse, message)
            }
          }
      }
    
      const toasterMessage = (response, message) => {

        if(response.success === 'success'){
            showToast(message, false)
        } else {
            showToast(message, true)
        }
        
      }

    const handleInputChange = (index,oddsIndex, field, value) => {
        const updatedFancyData = [...fancyData];
        const [outerField, innerField] = field.split('.');
       
        if (innerField) {
            if (outerField === 'odds' && (innerField === 'runNo' || innerField === 'runYes'|| innerField === 'oddsNo'|| innerField === 'oddsYes'|| innerField === 'size'|| innerField === 'back'|| innerField === 'lay' )) {
                if (value < 0) {
                    return; 
                }
            }
           
            updatedFancyData[index][outerField][oddsIndex][innerField] = value;
        } else {
            if (['min_stake', 'max_stake', 'bet_delay', 'max_market_limit'].includes(field)) {
                if (value < 0) {
                    return
                }
            } else if(field === "end_date"){
                setEndDate(value);
            }
            if (field === 'selection_name') {
                updatedFancyData[index].odds[oddsIndex][field] = value;
            } else {
                updatedFancyData[index][field] = value;
            }
          
        }

        setFancyData(updatedFancyData);
    };

    const handleSelectResult = (value) => {
        setResultValue("");
        setResultData(value)
        if(value){
            const initialOperator  = operatorList?.map(el => ({ value: el, label: el }));
            setOperator(initialOperator);
        }
    }

    const handleChange = (value)=>{
        setResultValue(value)
    }
     
    async function handleResultAdd() {
 
        let value = resultData.data
        let postdata = {
            fancy_id: value.id,
            market_name: value.market_name,
            match_id: userName.match_id,
            result: resultValue,
            selection: value.selection?? '',
            sport_id: value.sport_id
        }
        
        await showConfirmationDialog(`You want to Submit this Result (${resultValue})`)
        .then(async (result) => {
            if (result.isConfirmed) {
                const response = await postCaller(`api/v1/results`, postdata);
                if (response.success) {
                    await showSuccessMessage( response.message)
                    .then(function () {
                        setResultData("");
                        setResultValue("");
                        setFddF(true);
                    })
                } else {
                    showToast(response.message, true)

                }
            }
        })


    }
    
    useEffect(() => {
        const getData = async() => {
          const response = await getCaller(`api/v1/results/operatorList`)
            const fetchedOperatorList = response?.data?.operatorList
            setOperatorList(fetchedOperatorList)
        }
        getData()
      },[] )
     
    const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

    const handleOperator = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'all')) {
            if (selectedOptions.length === options.length) {
                setOperator([]);
            } else {
                setOperator(operatorList.map(el => ({ value: el, label: el })));
            }
        } else {
            setOperator(selectedOptions);
        }
    };

    const getOptions = (selectedOptions) => {
        if (selectedOptions?.length === operatorList.length) {
            return operatorList.map((el) => ({ value: el, label: el }));
        }
        return options;
    };
    
    const handleSubmit = async (i) => {
        if(!scope?.markets?.update) return

        try {
            const response = await updateCaller(`api/v1/fancies/${i.id}`, {
                is_sus: i.is_sus,
                is_active: i.is_active,
                is_com: i.is_com,
                msg: i.msg,
                min_stake: i.min_stake,
                max_stake: i.max_stake,
                bet_delay: i.bet_delay,
                max_market_limit: i.max_market_limit,
                market_name: i.market_name,
                odds:i.odds,
                end_date: i.end_date
            });

            if (response.success === true) {
                showToast("Fancy updated Successfully", false)
            }  else  {     
                showToast(response.message, true)
            }
            
        } catch (error) {
            console.error('Error updating fancyData:', error);
        }
    };

    const handleDelete = async (item) => {
        if(!scope?.markets?.delete) return;

        try {
            await showConfirmationDialog(`You are about to delete a fancy.`)
            .then(async (result) => {
            if (result.isConfirmed){
            const deleteResponse = await deleteCaller(`api/v1/fancies/${item.id}`);
            if (deleteResponse.success === true) {
                await showSuccessMessage('Your fancy has been deleted successfully')
                .then((response) => {
                    if (response) {
                        const updatedFancyData = fancyData.filter((fancy) => fancy.id !== item.id);
                        setFancyData(updatedFancyData);
                        setResultData("");
                        setResultValue("");
                    }
                });
            } else  {
                showToast(deleteResponse.message,true)
            }}})
        } catch (error) {
            console.error('Error deleting fancy:', error);
        }
    };

    const addFancyAdd = () => {
        setFancyAddComponents((prevFancyAddComponents) => [
            ...prevFancyAddComponents,
            <FancyAssignAdd defaultLimits={defaultLimits} setFddF={setFddF} key={prevFancyAddComponents.length} marketType={userName.market_type} userName={userName} />
        ]);
    };
    
    const addFancyOdds = (index) => {
        const timestampInSeconds = new Date().getTime();
        const selectionID = timestampInSeconds / 1000  + Math.floor(Math.random() *1000);;

        setFancyAddOddsComponents((prevData) => ({
            ...prevData,
            [index]: [
                ...(prevData[index] || []),
                <FancyAssignAddOdds
                    key={prevData[index] ? prevData[index].length : 0}
                    fancy={fancyData[index]}
                    fancyId={fancyData[index].id}
                    market_type={fancyData[index].market_type}
                    si={parseInt(selectionID)}
                    onAddOdds={(newOdds) => handleAddOdds(index, newOdds)}
                    onRemoveOdds={() => removeFancyOdds(index, prevData[index] ? prevData[index].length : 0)}
                />
            ]
        }));
    };

    const removeFancyOdds = (index, key) => {
        setFancyAddOddsComponents((prevData) => {
            const updatedData = { ...prevData };
            updatedData[index] = updatedData[index].filter((_, i) => i !== key);
            return updatedData;
        });
    };

    const handleAddOdds = (index, newOdds) => {
        const updatedFancyData = [...fancyData];
        updatedFancyData[index].odds.push(newOdds);
        setFancyData(updatedFancyData);
        handleSubmit(updatedFancyData[index])
    };
    
    const handleDeleteOdds = async (item,fancyIndex, oddsIndex) => {
        await showConfirmationDialog(`You are about to delete this fancy Selection Odds.`)
        .then(async (result) => {
            if (result.isConfirmed){
                const updatedFancyData = [...fancyData];
                updatedFancyData[fancyIndex].odds = updatedFancyData[fancyIndex].odds.filter((_, index) => index !== oddsIndex);
                setFancyData(updatedFancyData);
                await handleSubmit(item)
            }
        })
    };

    return (
        <>
            <div className="book-maker-heading">
                <p> FANCY RESULT DECLARE </p>
                <div className='remove-market-container'>
                </div>
            </div>
             
                <div className="book-maker-content">
                    <div className="book-maker-select">
                        <label htmlFor=""> Select Fancy </label> <br />
                        <Select
                            id='result-dropdown'
                            value={resultData ? { value: resultData.value ,label:resultData.label} : '' }
                            onChange={(selectedOption) => handleSelectResult(selectedOption)}
                            options={fancyData?.map((el) => ({value:el.market_name,label:el.market_name, data:el}))}
                            placeholder="Select Result"
                          
                        />
                    </div>
                    <div className="book-maker-select">
                        <label htmlFor="">Select Result</label> <br />
                        {
                            (['odd_even_markets']).includes(fancyData[0]?.market_type) ? (
                            <select 
                                name="result_value" 
                                className="select-field" 
                                value={resultValue}  
                                onChange={(e) => handleChange(e.target.value)}
                            >
                                <option value="" disabled>Select Result</option>
                                <option value="even">Even</option>
                                <option value="odd">Odd</option>
                            </select>
                            ) : (
                            <Select
                                id='result-dropdown'
                                value={resultValue ? { value: resultValue, label: resultValue } : null}
                                onChange={(selectedOption) => handleChange(selectedOption.value)}
                                options={fancyData?.flatMap((el) => {
                                    let odds =  el.odds;
                                    console.log()
                                    if ( el.id === resultData.data?.id) {
                                        return odds.map((e) => ({ value: e.selection_name, label: e.selection_name }));
                                    }
                                    return []; 
                                })}
                               placeholder="Select Result"
                            />
                            )
                        }
                    </div>
                    <div className='book-maker-select'>
                        <label > Select Operators </label> <br />
                        <Select
                            onChange={ handleOperator }
                            options={getOptions(operator)}
                            value={ operator } 
                            placeholder="Select Operators"
                            isMulti
                        />
                    </div>
                    <div className="book-maker-btn" style={{ marginTop: "1.8rem" }}>
                        <Button type='submit' className='submit' name="Submit Result" onClick={handleResultAdd} disabled={!scope?.result?.add || resultData === "" || resultValue === null || resultValue.trim() === ''}/>
                    </div>
                </div>
            
            <div className="book-maker-match">
                <div> <p> {userName.market_id.split("_").join(" ").toUpperCase()} <span className='match-name'> {userName.event_name} </span>  </p> </div>
                <div className="book-input-submit-1">
                <div className="toggle-password">
                        <div className="suspend-container">
                            <div className="suspend-container-body">
                                <p className="suspend-para"> Suspend All</p>
                                <ToogleButton
                                    defaultChecked={agentDetails.is_suspend_all ? true : false} onChange={(value) => handleSuspendAll("is_suspend_all", agentDetails.id, value ? 1 : 0)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='book-input'> </div>
                    <div>
                        <Button className='submit' type="button" disabled={!scope?.markets?.add}  name="ADD FANCY" onClick={addFancyAdd} />
                    </div>
                </div>
            </div>
            
            <div className="market-match-container" style={{ overflowX: 'auto' }}>
                {fancyAddComponents.map((component, index) => (
                    <div key={index}>{component}</div>
                ))}
            </div>

            <div className="market-match-container" style={{ overflowX: 'auto' }}>
                <table width="100%">
                    <tbody>
                        {fancyData.map((item, index) => (
                            <div key={index}>
                                <div className="market-book-maker-border">
                                    <div className='market-bookmaker-popup' style={{ overflowX: "auto" }}>
                                        <div className="">
                                            <label htmlFor="marketName">Market Name</label> <br />
                                            <input type="text" autoComplete="off" name={`marketName-${index}`} className='fancy-stake-input' value={item.market_name} onChange={(e) => handleInputChange(index, null, 'market_name', e.target.value)} placeholder='Fancy Selection Name' /><br />
                                            <label htmlFor="stake_limit">Min Stake </label> <br />
                                            <input type="number" autoComplete="off" name="min_stake" className='fancy-stake-input ' value={item.min_stake} onChange={(e) => handleInputChange(index, null, 'min_stake', e.target.value)} placeholder="Min Stake" /> <br />
                                            <label htmlFor="stake_limit">MAx Stake </label> <br />
                                            <input type="number" autoComplete="off" name="max_stake" className='fancy-stake-input' value={item.max_stake} onChange={(e) => handleInputChange(index, null, 'max_stake', e.target.value)} placeholder='Max Stake' size="5" /><br />
                                            <label htmlFor="msg">Message</label> <br />
                                            <input type="text" autoComplete="off" name={`msg-${index}`} className='fancy-stake-input' value={item.msg} onChange={(e) => handleInputChange(index, null, 'msg', e.target.value)} placeholder='Message' /><br />
                                            <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                                            <input type="number" autoComplete="off" name="max_market_limit" className='fancy-stake-input' value={item.max_market_limit} onChange={(e) => handleInputChange(index, null, 'max_market_limit', e.target.value)} placeholder={'max_market_limit'} /><br />
                                            <label htmlFor="bet_delay">Bet delay</label> <br />
                                            <input type="number" autoComplete="off" name="Bet Delay" className='fancy-stake-input' value={item.bet_delay} onChange={(e) => handleInputChange(index, null, 'bet_delay', e.target.value)} placeholder='Bet Delay' /> <br />
                                            <label htmlFor="bet_delay">End Date</label> <br />
                                            <DatePicker
                                                name="end_date"
                                                selected={endDate}
                                                onChange={(e) => handleInputChange(index,null, 'end_date', e)}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                placeholderText="End Date & Time"
                                                timeIntervals={15}
                                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                                className='fancy-stake-input'
                                                value={item?.end_date != null ? moment(item.end_date).format('YYYY-MM-DD HH:mm:ss') : ""}
                                            /> <br />
                        
                                        </div>
                                        <div>
                                            <div className="suspend-container">
                                                <div className="suspend-container-body"> <p className='suspend-para'> Suspend </p>  <ToogleButton defaultChecked={item.is_sus === 1} value={item.is_sus} onChange={(e) => handleInputChange(index, null, 'is_sus', e ? 1 : 0)} />   </div>
                                                <div className="suspend-container-body"> <p className='suspend-para'> Comm </p>  <ToogleButton defaultChecked={item.is_com === 1} value={item.is_com} onChange={(e) => handleInputChange(index, null, 'is_com', e ? 1 : 0)} />   </div>
                        

                                                <div className="suspend-container-body"> <p className='suspend-para'> Active </p>  <ToogleButton defaultChecked={item.is_active === 1} value={item.is_active} onChange={(e) => handleInputChange(index, null, 'is_active', e ? 1 : 0)} />  </div>
                                                <div className="suspend-container-body" >
                                                    <div className="actions_tab">
                                                        <div className='fancy-submit' >
                                                            <IoMdSave className="icon" onClick={() => handleSubmit(item)} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) { e.preventDefault(); handleSubmit(item) } }} />
                                                        </div>   
                                                    </div>
                                                    <div className='fancy-cancel'>
                                                            <MdDelete className="icon" onClick={() => handleDelete(item)} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleDelete(item) } }} />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                                        <div className="book-maker-match">
                                            <div className="book-input-submit-1" key={index}>
                                                <div>
                                                    <Button className='submit' type={"button"} name={"ADD FANCY ODDS"}  onClick={() => addFancyOdds(index)}/>
                                                </div>
                                            </div> 
                                        </div>
                    
                                        <div>
                                        {fancyAddOddsComponents[index]?.map((component, idx) => (
                                            <div key={idx}>{component}</div>
                                        ))}
                                        </div>
                                        { item.odds.length > 0 && 
                                            <div  style={{ overflowX: "auto" }}>
                                                <table width="100%">
                                            
                                                    <thead id='fancy-head-coloumn'>
                                                        <tr className=''>
                                                        <th align='left'> SELECTION NAME </th>
                                                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> NO (OUTCOME) </th>)}
                                                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> YES (OUTCOME) </th>)}
                                                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> NO (ODDS) </th>)}
                                                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> YES (ODDS) </th>)}
                                                            {!["khado_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> SIZE </th>)}
                                                            {!["odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> BACK </th>)}
                                                            {!["odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (<th> LAY </th>)} 
                                                            <th> SUSPEND </th>
                                                            <th> ACTION </th>
                                                            
                                                        </tr>
                                                    </thead>
                                            
                                                    <tbody>
                                                    {item.odds.map((oddsItem, oddsIndex) => (
                                                        <tr key={oddsIndex}>
                                                            <td>
                                                                <input type="text" autoComplete="off" name={`marketName-${index}`} className='fancy-stake-input' value={oddsItem.selection_name} onChange={(e) => handleInputChange(index, oddsIndex, 'selection_name', e.target.value)} placeholder='Selection Name' />
                                                            </td>
                                                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                                                <input type="number" autoComplete="off" name="runNo" className=' fancy-input' value={oddsItem.runNo} onChange={(e) => handleInputChange(index, oddsIndex,'odds.runNo', e.target.value)} />
                                                            </td>)}

                                                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                                                <input type="number" autoComplete="off" name="runYes" className=' fancy-input' value={oddsItem.runYes} onChange={(e) => handleInputChange(index, oddsIndex,'odds.runYes', e.target.value)} />
                                                            </td>)}
                                                            
                                                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : (  <td >
                                                                <input type="number" autoComplete="off" name="oddsNo" className='fancy-input' value={oddsItem.oddsNo} onChange={(e) => handleInputChange(index, oddsIndex,'odds.oddsNo', e.target.value)} /><br />
                                                            </td>)}

                                                            {["khado_markets", "odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                                                <input type="number" autoComplete="off" name="oddsYes" className=' fancy-input' value={oddsItem.oddsYes} onChange={(e) => handleInputChange(index, oddsIndex,'odds.oddsYes', e.target.value)} />
                                                            </td>)}
                                                            
                                                            {!["khado_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                                                <input type="number" autoComplete="off" name="size" className=' fancy-input' value={oddsItem.size} onChange={(e) => handleInputChange(index, oddsIndex,'odds.size', e.target.value)} />
                                                            </td>)}

                                                            {!["odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                                                <input type="number" autoComplete="off" name="back" className=' fancy-input' value={oddsItem.back} onChange={(e) => handleInputChange(index, oddsIndex,'odds.back', e.target.value)} />
                                                            </td>)}

                                                            {!["odd_even_markets"].includes(fancyData.length > 0 && fancyData[0].market_type) ? null : ( <td >
                                                                <input type="number" autoComplete="off" name="lay" className=' fancy-input' value={oddsItem.lay} onChange={(e) => handleInputChange(index, oddsIndex,'odds.lay', e.target.value)} />
                                                            </td>)}
                                                            <td>
                                                                <ToogleButton defaultChecked={oddsItem.suspend === 1} value={oddsItem.suspend} onChange={(e) => handleInputChange(index, oddsIndex, 'odds.suspend', e ? 1 : 0)} />
                                                            </td>
                                                            <td>
                                                                <div className="toggle-password">
                                                                    <div className="actions_tab">
                                                                        <div className='fancy-submit' >
                                                                            <IoMdSave className="icon" onClick={() => handleSubmit(item)} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) { e.preventDefault(); handleSubmit(item) } }} />
                                                                        </div>
                                                                        <div className='fancy-cancel'>
                                                                            <MdDelete className="icon" onClick={() => handleDeleteOdds(item,index, oddsIndex)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                                
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default FancyAssignMarket