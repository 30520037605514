import React, { useEffect, useState, useRef } from 'react'
import { getCaller, postCaller } from '../../services/api';
import ResultTab from './WorliMatkaResultTab';
import { motion } from "framer-motion";
import MatchResultTable from './MatchResultTable';
import Button from '../../component/button/Button';
import Select from 'react-select';
import moment from 'moment';
import CONSTANTS from '../../utilities/constants';
import Result from '../result/result';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { showToast, showSuccessMessage } from '../../utilities/toastHelper';

const WorliMatkaMatchResult = () => {
    const [matchList, setMatchList] = useState([]);
    const [match, setMatch] = useState([]);
    const [matchType, setMatchType] = useState([]);
    const [selectedMatches, setSelectedMatches] = useState(0)
    const [matchTypesList, setMatchTypesList] = useState([])
    const [selectedMatchTypes, setSelectedMatchTypes] = useState("")
    const [resultValue, setResultValue] = useState("")
    const [resultData, setResultData] = useState([])
    const [matchDate, setMatchDate] = useState(moment().tz('Asia/Kolkata').format('YYYY-MM-DD'))
    const matchSelectRef = useRef();
    const matchTypeSelectRef = useRef();
    const [totalPage, setTotalPage] = useState(0)
    const [fetchedPages, setFetchedPages] = useState({});
    const [scope, setScope] = useState({})
    const  limit = 100;
    useEffect(() => {
        const fetchData = async () => {
            const Response = await getCaller(`api/v1/worli/matches/${selectedMatches}/markets/matchtype/${matchDate}`);
            if (Response.success === true)
                setMatchTypesList(Response.data.data)
            else {
                showToast (Response.message , true)
            }

        };
        if (selectedMatches !== 0){
            fetchData()
        }

    }, [selectedMatches]);

    useEffect(() => {
        const { result } = getLocalData('scope');
        const userType = getLocalData('user_role');
        setScope({result, userType})
        async function getMatchDataCb() {
            await getMatchData()
        }
        getMatchDataCb()
    }, [])



    const handleMatchTypes = async (event) => {
        setMatchType('')
        setResultValue('')
        setMatch(event.value)
        const selectedMatch = (event != 0) ? event.value : event
        setSelectedMatches(selectedMatch)
    }


    const handleMatchTypesSelected = (event) => {
        setResultValue('')
        const matchTypeSelected = event.value
        setMatchType(matchTypeSelected)
        setSelectedMatchTypes(matchTypeSelected)
    }

    const getMatchData = async (event) => {
        let dateToBeConsidered = `${new Date(matchDate).getFullYear()}-${(new Date(matchDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(matchDate).getDate().toString().padStart(2, '0')}`
        if (event) {
            const matchDate = event.target.value
            const formattedDate = `${new Date(matchDate).getFullYear()}-${(new Date(matchDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(matchDate).getDate().toString().padStart(2, '0')}`
            dateToBeConsidered = formattedDate
            setMatchDate(formattedDate)
        }
        
        const response = await getCaller(`api/v1/worli/matches/${dateToBeConsidered}/pendingresult`)

        if (response.success == true) {
            setMatchList(response.data.data)
        } else if (event) {
            showToast (response.message , true)
        }
    }

    const handleMatches = async (event) => {
        setMatchList([])
        setMatch('')
        setMatchType('')
        setMatchTypesList([])
        setResultValue('')
        setSelectedMatches(0)
        await getMatchData(event)
    }

    async function handleResultAdd() {
        if (CONSTANTS.WORLI.ALLOWED_RESULT.length > 0 && (!CONSTANTS.WORLI.ALLOWED_RESULT.includes(parseInt(resultValue)))){
            const Title = 'Invalid Result Value'
            showToast (Title , true)
            return
        }
        const response = await postCaller(`api/v1/worli/matches/result/${selectedMatchTypes}`, { matchTime: matchDate, result: resultValue })
        if (response.success) {
            setMatchList([]);
            setSelectedMatches(0);
            setMatchTypesList([]);
            setSelectedMatchTypes("");
            setResultValue("");
            setMatchDate("");
           
            const Title =  "Match result has been added"
            showSuccessMessage(Title)
            await allResult()
        }
        else {
            showToast (response.message , true)
        }

    }

    useEffect(() => {
        allResult()
    }, [])

    const allResult = async (page = 1) => {
        if (fetchedPages[page] ) {
            setResultData(fetchedPages[page]);
            if(page !==1) return
        }
        const resultResponse = await getCaller(`api/v1/worli/result/all?page=${page}&limit=${limit}`)
        const filteredData =resultResponse.data.result.sort((a, b) => b.id - a.id)
        setResultData(filteredData)
        setTotalPage(resultResponse.pagination?.totalPages)
        setFetchedPages((prev) => ({ ...prev, [page]: filteredData }));
        matchSelectRef.current.setValue(0);
        matchTypeSelectRef.current.setValue("");
    }
    const selectStyles ={
        control: (provided, state) => ({
            ...provided,
            outline: "none",
            height: "2.5rem",
            marginTop: "0.4rem",
            border: "2px solid #cbcbcb",
            borderRadius: "0.25rem",
            width: "100%"
        }),
    }
    return (
        <>  
            <Result />
            <ResultTab />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}>
                <div className="result-sport-container">
                    <div className="result-sport-content">
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Date </label> <br />
                            <input type='date' autoComplete="off"
                                name='event_date' value={matchDate} onChange={(event) => { handleMatches(event) }} className='select-field' placeholder='YYYY/MM/DD' />
                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Match </label> <br />
                            <Select
                                value={match ?  { value: match, label: matchList.find(el => el.id === match)?.match_title } : null}
                                ref={matchSelectRef}
                                onChange={(selectedOption) => handleMatchTypes(selectedOption)}
                                options={matchList.map(match => ({
                                    value: match.id,
                                    label: `${match.match_title}`
                                }))}
                                isSearchable={true}
                                placeholder="Select Match"
                                styles={selectStyles}
                            />

                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Match Type </label> <br />
                            <Select
                                value={matchType ?  { value: matchType, label: matchTypesList.find(el => el.id === matchType)?.match_type } : null}
                                ref={matchTypeSelectRef}
                                onChange={(selectedOption) => handleMatchTypesSelected(selectedOption.value ? selectedOption : "")}
                                options={matchTypesList.map(matchType => ({
                                    value: matchType.id,
                                    label: `${matchType.match_type}  ( ${matchType?.match_time})`
                                }))}
                                isSearchable={true}
                                placeholder="Select Match Type"
                                styles={selectStyles}
                            />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Match Result </label> <br />
                            <input type='number' autoComplete="off" className='select-field' value={resultValue} placeholder='Enter Result' onChange={(e) => setResultValue(e.target.value)} />
                        </div>
                    </div>
                    <div className="book-maker-btn">
                        <Button className='submit' type='button' name='Submit Result' disabled={!(resultValue !== "" && (scope?.userType !== 'manager' || scope?.result?.add))} onClick={handleResultAdd} />
                    </div>
                </div>
                <MatchResultTable resultData={resultData} setResultData={setResultData} allResult={allResult} totalPage={totalPage}/>
            </motion.div>


        </>
    )
}

export default WorliMatkaMatchResult