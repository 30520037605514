import React, { useState, useEffect } from 'react'
import ToogleButton from '../../../component/toggleButton/ToogleButton'
import { updateCaller, getCaller, postCaller } from '../../../services/api';
import '../downline.css'
import Button from '../../../component/button/Button';
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { showToast } from '../../../utilities/toastHelper';

const CustomBookmakerAdd = ({ uid, eventId, eventName, matchId, susTime }) => {
  const navigate = useNavigate();
  const [allowAddBookmaker, setAllowAddBookmaker] = useState(true);
  const [eventData, setEventData] = useState({});
  const [runners, setRunners] = useState([]);
  const [formMode, setFormMode] = useState('Add');
  const [bookmakerID, setBookmakerID] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData({ ...formData, end_date: date });
  };

  useEffect(() => {
    async function getSport() {
      let eventData = (await getCaller(`api/v1/events/${matchId}`)).data;
      setEventData(eventData || {});
      let limits = (await getCaller(`api/v1/events/${eventId}/limits/bookmaker`)).data;
      setFormData(oldData => ({ ...oldData, stake_limit: limits.stake_limit, bet_delay: limits.bet_delay, max_market_limit: limits.max_market_limit, is_com: limits.is_com || 0, }));
    }
    getSport()
  }, [matchId, eventId])

  const [formData, setFormData] = useState({
    match_id: matchId,
    runners: runners,
    is_com: 0,
    max_market_limit: 0,
    msg: '',
    is_active: 0,
    stake_limit: 0,
    is_sus: 0,
    sport_id: '',
    market_name: 'Bookmaker',
    agent_id: uid,
    bet_delay: 0,
    end_date: null,
    is_auto: 0
  });
  const handleSubmit = async () => {

    const formattedEndDate = endDate ? moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') : null;

    try {
      if (formMode === 'Add') {
        const response = await postCaller('api/v1/bookmakers/addBookMaker', {
          match_id: matchId,
          runners: runners,
          is_com: formData.is_com,
          max_market_limit: formData.max_market_limit ? formData.max_market_limit:"0",
          msg: formData.msg,
          is_active: formData.is_active,
          stake_limit: formData.stake_limit,
          is_sus: formData.is_sus,
          sport_id: formData.sport_id,
          market_name: formData.market_name,
          bet_delay: formData.bet_delay,
          end_date: formattedEndDate,
          agent_id: uid,
          sus_time: susTime,
          is_auto: formData.is_auto
        });
        if (response.success === true) {
          showToast("Bookmaker Added Successfully",false)
        }
        const marketID = response.data.market_id;
        const res = await getCaller(`api/v1/bookmakers/findByIdBookMaker?id=${marketID}`)
        setFormData(res.data[0])
        setBookmakerID(marketID);
        setFormMode('Update');
        navigate('/downline');
      } else if (formMode === 'Update' && bookmakerID) {
        const res = await updateCaller(`api/v1/bookmakers/${bookmakerID}`, {
          runners: runners,
          bet_delay: formData.bet_delay,
          end_date: formattedEndDate,
          is_com: formData.is_com,
          max_market_limit: formData.max_market_limit,
          msg: formData.msg,
          is_active: formData.is_active,
          stake_limit: formData.stake_limit,
          is_sus: formData.is_sus,
          market_name: formData.market_name,
          sus_time: susTime,
        });

        if (res.success === true) {
          showToast("Bookmaker updated Successfully", false)
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  useEffect(() => {
    if (eventData.selection_runners) {
      const selectionRunners = JSON.parse(eventData.selection_runners);
      const runners = [];
      for (const runner of selectionRunners) {
        runners.push({
          sln: runner.RN,
          si: runner.SID,
          ss: "SUSPEND",
          b: 0,
          l: 0,
        });
      }
      setRunners(runners)
      setFormData((prevFormData) => ({
        ...prevFormData,
        runners: runners,
      }));

    }
  }, [eventData.selection_runners]);
  useEffect(() => {
    if (eventData.sport_id) {
      setFormData((formData) => ({ ...formData, sport_id: eventData.sport_id }));
    }
  }, [eventData]);

  const updateRunnerSelectionStatus = (si, value) => {
    const newRunners = [...runners];
    const runnerIndex = newRunners.findIndex(r => r.si === si);
    if (runnerIndex > -1) {
      newRunners[runnerIndex] = { ...newRunners[runnerIndex], ss: !value ? 'ACTIVE' : 'SUSPEND' };
    }
    setRunners(newRunners)
  }
  const updateRunner = (si, value, type) => {
    const newRunners = [...runners];
    const runnerIndex = newRunners.findIndex(r => r.si === si);
    if (runnerIndex > -1) {
      newRunners[runnerIndex] = { ...newRunners[runnerIndex], [type]: value };
    }
    setRunners(newRunners)
  }

  const handleBackChange = (si, value) => {
    const newRunners = [...runners];
    const runnerIndex = newRunners.findIndex((r) => r.si === si);
    if(value < 0){
      return
    }
    if (runnerIndex > -1) {
      newRunners[runnerIndex] = {
        ...newRunners[runnerIndex],
        b: parseFloat(value) || "",
      };
    }
    setRunners(newRunners);
  };
  const handleLayChange = (si, value) => {
    const newRunners = [...runners];
    const runnerIndex = newRunners.findIndex((r) => r.si === si);
    if (runnerIndex > -1) {
      newRunners[runnerIndex] = {
        ...newRunners[runnerIndex],
        l: parseFloat(value) || ""
      };
    }
    setRunners(newRunners);
  };

  const handleToggleChange = (isEnabled) => {
    const updatedRunners = runners.map((runner) => ({
      ...runner,
      b: isEnabled ? 0 : runner.b,
      l: isEnabled ? 0 : runner.l,
    }));
    setRunners(updatedRunners);
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      is_auto: isEnabled ? 1 : 0,
    }));
  };
  
  return (
    <div>
      <div className="market-match-container">
        <div className="book-maker-match">
          <div className='span-book'> <p> BOOKMAKER </p><span className='match-name' >{eventName}  </span>  </div>
          <div className="book-input-submit-1">
            <div className='book-input'>  </div>
          </div>
        </div>

        {allowAddBookmaker ?
          <div>
            {

              <div>

                <div className="market-book-maker-border">
                  <div className='market-bookmaker-popup' style={{ overflowX: "auto" }}>
                    <div className="">
                      <label htmlFor="marketName">Market Name</label> <br />
                      <input type="text" autoComplete="off" name="marketName" className='odd-input' placeholder='Market name' value={formData.market_name} onChange={(e) => setFormData({ ...formData, market_name: e.target.value })} /> <br />
                      <label htmlFor="stake_limit">Stake Limit</label> <br />
                      <input type="number" autoComplete="off" name="stake_limit" className='odd-input' placeholder='Stake limit' value={formData.stake_limit} onChange={(e) => setFormData({ ...formData, stake_limit: e.target.value })} /> <br />
                      <label htmlFor="msg">Message</label> <br />
                      <input type="text" autoComplete="off" name="msg" className='odd-input' id="" placeholder='Message' value={formData.msg} onChange={(e) => setFormData({ ...formData, msg: e.target.value })} /> <br />
                      <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                      <input type="number" autoComplete="off" name="max_market_limit" className='odd-input' id="" placeholder='Max market limit' value={formData.max_market_limit} onChange={(e) => setFormData({ ...formData, max_market_limit: e.target.value })} /> <br />
                      <label htmlFor="bet_delay">Bet delay</label> <br />
                      <input type="number" autoComplete="off" name="bet_delay" className='odd-input' placeholder='Bet delay' value={formData.bet_delay}
                        onChange={(e) => setFormData({ ...formData, bet_delay: e.target.value })} /> <br />
                      <label htmlFor="end_date">End Date</label> <br />
                      <DatePicker
                        name="end_date"
                        selected={endDate}
                        onChange={handleEndDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        placeholderText="End Date & Time"
                        timeIntervals={15}
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                        className="odd-input"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      /> <br />
                    </div>

                    <div>
                      <div className="suspend-container">
                        <div className="suspend-container-body"> <p className='suspend-para'> Auto </p>  <ToogleButton defaultChecked={formData.is_auto === 1} value={formData.is_auto} onChange={(e) => handleToggleChange(e)} />   </div>
                        <div className="suspend-container-body"> <p className='suspend-para'> Suspend </p>  <ToogleButton defaultChecked={formData.is_sus === 1} value={formData.is_sus} onChange={(e) => setFormData({ ...formData, is_sus: e ? 1 : 0 })} />   </div>
                        <div className="suspend-container-body"> <p className='suspend-para'> Commission</p>  <ToogleButton defaultChecked={formData.is_com === 1} value={formData.is_com} onChange={(e) => setFormData({ ...formData, is_com: e ? 1 : 0 })} />   </div>

                        <div className="suspend-container-body"> <p className='suspend-para'> Active </p>  <ToogleButton defaultChecked={formData.is_active === 1} value={formData.is_active} onChange={(e) => setFormData({ ...formData, is_active: e ? 1 : 0 })} />  </div>
                        {/* <div className=""> <Button className='cancel' name="Remove" type={"button"} onClick={() => { setAllowAddBookmaker(false) }} />  </div> */}

                      </div>
                      <div className="">

                      </div>
                    </div>
                  </div>
                </div>
                <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                  <table width="100%">
                    <thead id='fancy-head-coloumn'>
                      <tr className=''>
                        <th align='left'> TEAM NAME </th>

                        <th align='left'> BACK </th>
                        <th align='left'> LAY </th>
                        <th align='left'> SUSPEND </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        runners.map((runner, index) => (
                          <tr className='fancy-head-row' key={runner.si}>
                            <td> <input type="text" autoComplete="off" name="selectionName" className='book-maker-column-input-1' placeholder='Team name' value={runner.sln} onChange={(event) => { updateRunner(runner.si, event.target.value, 'sln') }} /> </td>
                            <td> <input type="number" autoComplete="off" name="backOdds" className='book-maker-column-input' placeholder='Back' value={formData.is_auto === 1 ? 0 : runner.b}
                              onChange={(event) => { handleBackChange(runner.si, event.target.value); }} disabled={formData.is_auto === 1}
                            /> </td>
                            <td> <input type="number" autoComplete="off" name="layOdds" className='book-maker-column-input' placeholder='Lay' value={formData.is_auto === 1 ? 0 : runner.l}
                              onChange={(event) => { handleLayChange(runner.si, event.target.value); }} disabled={formData.is_auto === 1}
                            /> </td>
                            <td align='left' > <ToogleButton defaultChecked={runner.ss === 'SUSPEND' ? false : true} onChange={(value) => { updateRunnerSelectionStatus(runner.si, value) }} /> </td>
                          </tr>
                        )
                        )
                      }

                    </tbody>
                  </table>

                  <div className='bookmaker-btn'>
                    <Button className='submit' name={formMode === 'Add' ? 'Submit' : 'Update'} type="button" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            }
          </div>
          : null}
      </div>
    </div>


  )
}

export default CustomBookmakerAdd