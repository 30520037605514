import { motion } from "framer-motion";
import React, { useState } from "react";
import { MdDelete } from 'react-icons/md';
import '../leagueFromSport/leagueManage.css';
import CONSTANTS from "../../../utilities/constants";
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { getCaller, updateCaller } from "../../../services/api";
import ToogleButton from "../../../component/toggleButton/ToogleButton";
import { showToast, showConfirmationDialog, showDeleteMessage } from "../../../utilities/toastHelper";

const MatchAccordian = ({ match, setMatches, matches, index }) => {
  const [open, setOpen] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [openMain, setOpenMain] = useState(false);

  const getMarketFromMatches = async () => {
    if (markets.length === 0) {
      const matchResponse = await getCaller(`api/v1/markets/marketFindById?id=${match.id}`);
      setMarkets(matchResponse.data);
    }
    setOpen(!open);
  };

  const handleMarketAccordian = () => {
    setOpenMain(!openMain);
  };

  const handleMatchCity = (fieldName, index, value) => {
    let newFormValues = [...matches];
    newFormValues[index][fieldName] = value;
    setMatches(newFormValues);
  };

  const handleMatchStatuses = async (fieldName, index, value) => {
    let newFormValues = [...matches];
    newFormValues[index][fieldName] = value;
    setMatches(newFormValues);

    let title = "";
    switch (fieldName) {
      case "is_active":
        title = value ? "Active" : "Inactive";
        break;
      case "is_populer":
        title = value ? "Marked popular" : "Marked unpopular";
        break;
      default:
        title = CONSTANTS.LEAGUE_STATUS[fieldName].concat(value ? " Enabled" : " Disabled");
        break;
    }

    const res = updateCaller(`api/v1/racing/events/${matches[index].id}`, matches[index]);
    if (res) {
      showToast(title);
      const venueRes = await getCaller(`api/v1/racing/events/venue/${matches[index].venue_id}`);
      setMatches(venueRes.data);
    }
  };

  const updateCity = async (item) => {
    let response = await updateCaller(`api/v1/events/matchActive?id=${item.id}&sport_id=${item.sport_id}`, matches[index]);
    if (response) {
      showToast("City name has been updated Successfully!");
    } else {
      showToast("Could not update city name.", true);
    }
  };

  const handleDeleteMatch = async (match, index, matches, setMatches) => {
    const result = await showConfirmationDialog("You want to Delete this Match?");
  
    if (result.isConfirmed) {
      match.is_deleted = 1;
      const response = await updateCaller(`api/v1/racing/events/${match.id}`, match);
  
      if (response.success === true) {
        showDeleteMessage("Match deleted successfully?");

        const newList = [...matches];
        newList.splice(index, 1);
        setMatches(newList);
      }
    }
  };

  const updateMarket = async (j, fieldName = '') => {
    const market = markets[j];
    const { odd_limit, is_active, max_market_limit, inplay_stake_limit, max_stake_limit, bet_delay, inplay_max_odd_limit, inplay_max_market_limit } = market;
    const response = await updateCaller(`api/v1/racing/updateMarket?match_id=${match.id}&id=${market.marketId}`, { odd_limit, is_active, max_market_limit, inplay_stake_limit, max_stake_limit, bet_delay, inplay_max_odd_limit, inplay_max_market_limit });

    if (response.success) {
      const title = fieldName && markets[j].is_active === 1 ? `${fieldName} updated successfully!` : (markets[j].is_active === 1 ? "Active" : "Inactive");
      showToast(title);
    }
  };

  return (
    <>
      <div className="match-accordian-body">
        <table width="95%">
          <tbody>
            <tr>
              <td align="left" width="230px"> 
                <div className={`acc-head ${open ? 'active-match' : 'inactive'}`} onClick={getMarketFromMatches}>
                  <p style={{ display: "flex", alignItems: "center" }}> 
                    {!open ? <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }} /></> : <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }} /></>} {match.event_name ? match.event_name : "Events not available"}
                  </p> 
                </div>
              </td>

              <td align="left" width="160px" >
                <div className="acc-para"> 
                  <p> {match.is_custom === 1 ? (new Date(match?.event_date?.slice(0, match?.event_date?.length - 5)?.replace('T', " "))).toLocaleString('en-GB') : (new Date(match?.event_date)).toLocaleString('en-GB')} </p> 
                </div>
              </td>

              <td align="left">
                <div className="table-flex">
                  <p className='para-btn'> Active </p>
                  <ToogleButton defaultChecked={match.is_active === 0 ? false : true} onChange={(event) => handleMatchStatuses("is_active", index, event, match)} />
                </div>
              </td>

              <td align="left">
                <div className="table-flex">
                  <p className='para-btn'> Recommended </p>
                  <ToogleButton defaultChecked={match.is_populer === 0 ? false : true} onChange={(event) => handleMatchStatuses("is_populer", index, event, match)} />
                </div>
              </td>

              <td align="left">
                <div className="table-flex">
                  <p className='para-btn'>In play</p>
                  <ToogleButton defaultChecked={match.inplay_status !== 0 ? true : false} onChange={(event) => handleMatchStatuses("inplay_status", index, event, match)} />
                </div>
              </td>

              <td align="left">
                <div className="table-flex">
                  <p className='para-btn'> Volume check </p>
                  <ToogleButton defaultChecked={match.is_odds_check === 0 ? false : true} onChange={(event) => handleMatchStatuses("is_odds_check", index, event, match)} />
                </div>
              </td>

              <td align="left">
                <input type="text" autoComplete="off" name="city" placeholder="City" value={match?.city ?? ""} onChange={(event) => handleMatchCity("city", index, event.target.value)} onBlur={() => { updateCity(match) }} className="city-input" />
              </td>

              <td align="left">
                <div className="delete-icon">
                  <MdDelete style={{ fontSize: "1rem", color: "white" }}
                    onClick={() => handleDeleteMatch(match)} tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                        e.preventDefault(); handleDeleteMatch(match)
                      }
                    }}
                  />
                </div>
              </td>

              <td align="left"> 
                <div className="ten-one">{match.total_markets}</div>
              </td>
            </tr>
          </tbody>
        </table>
        
        <div className={`market-accordian-body`}>
          {open && (
            <motion.div className="league-setting-container" style={{ backgroundColor: '#c6c6c6' }} initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
              <div className={`market-accordian-body`} style={{ display: open ? 'block' : 'none' }}>
                <div className='market-accordian-body'>
                  <button className={`accordion ${openMain ? 'active' : 'inactive'}`} onClick={handleMarketAccordian}>
                    {!openMain ? <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /></> : <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} /></>}
                    All Markets
                  </button>
                </div>

                {openMain && (
                  <div className="table-border" >
                    <div className={`market-accordian-body`}>
                      <table width="100%" >
                        <tbody>
                          {(markets || []).map((market, j) => (
                            <tr className='league-setting-row' key={market.marketId}>
                              <td>{market.market_name}</td>
                              <td>
                                <ToogleButton
                                  defaultChecked={parseInt(market.is_active) === 1}
                                  onChange={(value) => {
                                    const newMarket = { ...market };
                                    newMarket.is_active = value ? 1 : 0;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; })
                                    updateMarket(j)
                                  }}
                                />
                              </td>

                              <td>
                                <label htmlFor="stake_limit">Stake Limit</label> <br />
                                <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.max_stake_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.max_stake_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; })
                                  }}
                                  onBlur={() => updateMarket(j, "Stake Limit")}
                                  placeholder='stake limit'
                                  className='league-detail-input'
                                />
                              </td>

                              <td>
                                <label htmlFor="odd_limit">Odd Limit</label> <br />
                                <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.odd_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.odd_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; })
                                  }}
                                  onBlur={() => updateMarket(j, "Odd Limit")}
                                  placeholder='odd limit'
                                  className='league-detail-input'
                                />
                              </td>

                              <td>
                                <label htmlFor="inplay_stake_limit">Inplay Stake Limit</label> <br />
                                <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.inplay_stake_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.inplay_stake_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; })
                                  }}
                                  onBlur={() => updateMarket(j, "Inplay Stake Limit")}
                                  placeholder='inplay stake limit'
                                  className='league-detail-input'
                                />
                              </td>

                              <td>
                                <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                                <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.max_market_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.max_market_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; })
                                  }}
                                  onBlur={() => updateMarket(j, "Max Market Limit")}
                                  placeholder='max market limit'
                                  className='league-detail-input'
                                />
                              </td>
                              
                              <td>
                                <label htmlFor="bet_delay">Delay</label> <br />
                                <input autoComplete="off" type='number' disabled={parseInt(market.is_active) === 0} value={market.bet_delay}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.bet_delay = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; })
                                  }}
                                  onBlur={() => updateMarket(j, "Delay")}
                                  placeholder='bet delay'
                                  className='league-detail-input'
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </>
  )
};

export default MatchAccordian;