import '../user/user.css';
import moment from 'moment';
import Select from 'react-select';
import '../addEvent/addevent.css';
import { DateRangePicker } from 'react-dates';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { getCaller, postCaller, updateCaller } from '../../services/api';
import { showSuccessMessage, showToast } from '../../utilities/toastHelper';

const AssignLeagueToManager = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const userId = state?.passData?.id;

    const [sport, setSport] = useState(null);
    const [sportList, setSportList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [leagueList, setLeagueList] = useState([]);
    const [allLeaguesId, setAllLeaguesId] = useState([]);
    const [focusedInput, setFocusedInput] = useState(null);
    const [endDate, setEndDate] = useState(moment().add(5, 'days'));
    const [selectedOptionEvents, setSelectedOptionEvents] = useState([]);
    const [selectedOptionSport, setSelectedOptionSport] = useState(null);
    const [selectedOptionLeagues, setSelectedOptionLeagues] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days'));

    const minDate = moment().subtract(7, 'days').toDate();
    const handleDate = (date) => moment(date).format('MM/DD/YYYY');

    useEffect(() => {
        const getSport = async () => {
            const sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse?.data);
        };
        getSport();
    }, []);

    const getLeague = useCallback(async (sportId) => {
        const leagueResponse = await postCaller(`api/v1/leagues/management/getLeaguesForManager?sport_id=${sportId}`);
        setLeagueList(leagueResponse.data);
        return leagueResponse.data;
    }, []);


    const getEvents = useCallback(async (leagueIds, sportId) => {
        try {
            let allEvents = [];

            for (const leagueId of leagueIds) {
                const leagueData = {
                    from: handleDate(startDate),
                    to: handleDate(endDate),
                    // sport_id: sportId,
                    league_id: leagueId
                };
    
                const eventResponse = await postCaller('api/v1/events/management/getEventsForManager', leagueData);

                if (eventResponse.success) {
                    allEvents = [...allEvents, ...eventResponse.data];
                } else {
                    showToast(eventResponse.message, true)
                }
            }
            setEventList(allEvents);
            return allEvents;
        } catch (err) {
            console.error(err);
        }
    }, [startDate, endDate]);


    useEffect(() => {
        const getLeagues = async () => {
            if (userId && sportList.length > 0) {
                const res = await getCaller(`api/v1/users/${userId}`);
                const leagueIds = res?.data?.league_ids || {}; 
                const selectedSport = sportList.find(sport => sport.id === res?.data?.sport_id);
    
                if (selectedSport) {
                    setSelectedOptionSport({ value: selectedSport.id, label: selectedSport.name });
                    setSport(selectedSport.id);
    
                    if (leagueIds[selectedSport.id]) {
                        await fetchLeagues(selectedSport.id, leagueIds[selectedSport.id]);
                    }
                }
                
                if (res?.data?.start_date && res?.data?.end_date) {
                    setStartDate(moment(res.data.start_date));
                    setEndDate(moment(res.data.end_date));
                }
            }
        };
    
        const fetchLeagues = async (sportId, leagueIds) => {
            const leagues = await getLeague(sportId);
            const selectedLeagues = Object.keys(leagueIds).map(id => {
                const league = leagues?.find(league => league.id === id);
                return league ? { value: league.id, label: league.name } : null;
            }).filter(e => e !== null);
    
            setSelectedOptionLeagues(selectedLeagues);
            setAllLeaguesId(Object.keys(leagueIds));

            if (selectedLeagues.length > 0) {
                const events = await getEvents(Object.keys(leagueIds), sportId);
                setEventList(events);

                const selectedEvents = [];
                Object.keys(leagueIds).forEach(leagueId => {
                    const leagueEvents = events.filter(({competition, event}) => competition.id === leagueId && leagueIds[leagueId].includes(event.id));
                    if (leagueEvents.length) {
                        selectedEvents.push(...leagueEvents.map(({event}) => {
                            return { id:leagueId, value: event.id, label: event.name };
                        }))
                    }
                })
            
                setSelectedOptionEvents(selectedEvents);
            }
        };
    
        getLeagues();
    }, [userId, sportList, startDate, endDate, getLeague, getEvents]);


    const handleSport = useCallback(async (event) => {
        setSelectedOptionSport(event);
        setSelectedOptionLeagues([]);
        setSelectedOptionEvents([]);
        setAllLeaguesId([]);
        setLeagueList([]);
        setEventList([]);
    
        if (event?.value) {
            setSport(event.value);
            const leagues = await getLeague(event.value);
            
            const res = await getCaller(`api/v1/users/${userId}`);
            const league_ids = res?.data?.league_ids || {}; 
            const selectedLeaguesForSport = league_ids[event.value] || [];

            setAllLeaguesId(Object.keys(selectedLeaguesForSport));

            const selectedLeagues = Object.keys(selectedLeaguesForSport).map(id => {
                const league = leagues.find(league => league.id === id);
                return league ? { value: league.id, label: league.name } : null;
            }).filter(e => e !== null);
    
            setSelectedOptionLeagues(selectedLeagues);

            if (selectedLeagues.length > 0) {
                let selectedLeaguesID = selectedLeagues.map(e => e.value);
                const events = await getEvents(selectedLeaguesID, event.value);
                setEventList(events);

                const selectedEvents = [];
                Object.keys(league_ids[event.value]).forEach(leagueId => {
                    const leagueEvents = events.filter(({competition, event: eventData}) => competition.id === leagueId && league_ids[event.value][leagueId].includes(eventData.id));
                    if (leagueEvents.length) {
                        selectedEvents.push(...leagueEvents.map(({event}) => {
                            return { id:leagueId, value: event.id, label: event.name };
                        }))
                    }
                })

                setSelectedOptionEvents(selectedEvents);
            }
        }
    }, [getLeague, getEvents, userId]);


    const handleLeagues = async (selectedLeagues) => {
        setSelectedOptionLeagues(selectedLeagues);
        const leagueIds = selectedLeagues.map(league => league.value);
        setAllLeaguesId(leagueIds);
        
        if (leagueIds.length > 0 && sport) {
            await getEvents(leagueIds, sport);
        }
    };


    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };


    const handleEvents = (selectedEvents) => {
        setSelectedOptionEvents(selectedEvents);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const league_ids = {};
            if (!league_ids[sport]) {
                league_ids[sport] = {};
            }
            
            for (let i = 0; i < allLeaguesId.length; i++) {
                const id = allLeaguesId[i];
                if (!league_ids[sport][id]) {
                    league_ids[sport][id] = [];
                }
            
                for (let j = 0; j < selectedOptionEvents.length; j++) {
                    if (id === selectedOptionEvents[j].id) {
                        league_ids[sport][id].push(selectedOptionEvents[j].value);
                    }
                }
            }

            const updateResponse = await updateCaller(`api/v1/users/updateUser/${userId}`, { sport_id: sport, league_ids: league_ids });
            
            if (updateResponse.success) {
                await showSuccessMessage('Leagues have been successfully assigned to the manager')
                navigate('/user');
            } else {
                showToast(updateResponse.message, true)
            }
        } catch (err) {
            console.error(err);
        }
    };


    return (
        <div className="sport-container">
            <div className="modal-container-one leagueStyle">
                <div className="add-agent-head">
                    <div>Assign League To Manager</div>
                    <div className="ai-close"><AiOutlineClose onClick={() => navigate('/user')} /></div>
                </div>
                <div className='agent-add-form'>
                    <form onSubmit={handleSubmit}>
                        <div className="agent-input-body">
                            <label htmlFor="sport">Select Date</label><br />
                            <DateRangePicker 
                                startDate={startDate} 
                                startDateId="s_id" 
                                endDate={endDate} 
                                endDateId="e_id" 
                                onDatesChange={handleDatesChange} 
                                focusedInput={focusedInput} 
                                onFocusChange={setFocusedInput} 
                                displayFormat="DD/MM/YYYY" 
                                isOutsideRange={() => false}
                                isDayBlocked={(d) => d.toDate() < minDate} 
                                minimumNights={0} 
                                showClearDates
                                readOnly
                            />
                        </div>
                        <div className="agent-input-body">
                            <label htmlFor="sport">Select Sport</label><br />
                            <Select 
                                value={selectedOptionSport} 
                                onChange={handleSport} 
                                options={sportList.map(el => ({ value: el.id, label: el.name }))}
                                placeholder="Select Sport"
                            />
                        </div>
                        <div className="agent-input-body selectWrap-main">
                            <label htmlFor="league">Select Leagues</label><br />
                            <Select 
                                isMulti
                                value={selectedOptionLeagues} 
                                onChange={handleLeagues} 
                                options={leagueList.map(el => ({ value: el.id, label: el.name }))}
                                placeholder="Select Leagues"
                            />
                        </div>
                        <div className="agent-input-body selectWrap-main">
                            <label htmlFor="events">Select Events</label><br />
                            <Select 
                                isMulti
                                value={selectedOptionEvents}
                                onChange={handleEvents}
                                options={eventList.map(el => ({ id: el.competition.id, value: el.event.id, label: el.event.name }))}
                                placeholder="Select Events"
                            />
                        </div>
                        <div className='change-password-container'>
                            <button type="submit" className="basic-button submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AssignLeagueToManager;
