import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../result/result.css'
const WorliMatkaResultTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const resultTabData = [
    { name: "Match Result", route: "/results/worli-matka-result/match_result" },
    { name: "Pending Match", route: "/results/worli-matka-result/pending_match" },

  ]
  return (
    <>
      <div className="wrapper" >
        <ul className="tab-container">
          {
            resultTabData.map((el, i) => (
              <li key={i} className={`tab-box ${location.pathname === el.route ? 'active-result-tab' : null}`} onClick={() => navigate(el.route)} >
                {el.name}
              </li>
            ))
          }
        </ul>
      </div>
    </>
  )
}

export default WorliMatkaResultTab