import React from 'react'
import '../matchOdssMarket/matchodds.css'
const MatchOddsMarket = ({ matchOdds, eventData }) => {

    return (
        <>
        <div className='match-odd-container'>
            <div className="match-odd-head">
                <div className="match-odd"> Match Odds </div>
                <div className="match-back-right">
                    <span>Back</span>
                    <span>Lay</span>
                </div>
            </div>
            <div className="match-odd-body">
                
                <div className="">
                    {
                        eventData?.market_runners?.map((runner, i) => (
                            <p className='runners-name' key={i}>{runner.runnerName}</p>
                        ))
                    }
                </div>
                <div className='match-odd-card' >
                    {
                        matchOdds?.runners?.length > 0 ? matchOdds?.runners?.map((el, i) => (
                            <div className="match-odds-back-lay" key={i}>
                                <div className="match-odds-back-lay-content">
                                    {
                                        el?.ex?.ml?.b?.length<3? <div className="all-box">
                                    </div>:<>
                                    {
                                        el?.ex?.b?.slice(0).reverse().map((item, index) => (
                                            <div className="all-box" key={index}>
                                                <div className="">
                                                    <p className='odd-price'>{item?.p??"-"}</p>
                                                    <p className='odd-price-1'>{item?.s + 'k'?? "-"}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    </>
                                    }
                                  
                                </div>
                                <div className="match-odds-back-lay-content">
                                    {
                                        el?.ex?.l?.map((item, index) => (
                                            <div className="all-box-lay" key={index} style={{ backgroundColor: "#feafba" }}>
                                                <div className="">
                                                    <p className='odd-price'>{item?.p??"-"}</p>
                                                    <p className='odd-price-1'>{item?.s + 'k' ?? "-"}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )) : null
                    }
                    <div className="min-max-match">
                        <p className='min-max'>Min:  | Max: </p>
                    </div>
                </div>
            </div>
        </div>
        {/* } */}
        </>
    )
}

export default MatchOddsMarket