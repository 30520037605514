import React from 'react'
import Navbar from './Navbar'

const UserLayout = ({children}) => {
  return (
   <>
    <Navbar/>
      <div>
          {children}
      </div>
   </>
       
    
  )
}

export default UserLayout