const CONSTANTS = {
    WORLI: {
        ALLOWED_RESULT: []
    },
    LEAGUE_STATUS: {
        has_bookmaker: "Bookmaker",
        has_fancy: "Fancy",
        has_premium_fancy: "Premium",
        match_is_active: "All Matches",
        is_odds_check: "Volume Check",
        is_inplay: "League",
        is_active: "Active",
        inplay_status: "Live Odds",
        is_populer: "Marked Popular",
        has_3rdparty_Bookmaker:"3rd Party Bookmaker",
        has_3rdparty_Fancy:"3rd Party Fancy",
        auto_add_match:'Auto Add Match'
    },
    AGENT_FIELDS: {
        user_id: "User Id",
        name: "Name",
        password: "Password",
        confirmPassword: "Confirm Password",
        managerPassword: "Manager Password",
        allow_declare_result: "Result",
        show_bet: "Show Bets",
        show_book: "Show Books"
    },
    MATCH_FIELDS : {
        openSuspend : "Open Suspend",
        closeSuspend: "Close Suspend",
        suspend: "Suspend",
    }
}

export default CONSTANTS