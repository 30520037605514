import BigModal from '../../component/modal/BigModal';
import React, { useState, useEffect } from 'react'
import { getCaller } from '../../services/api';
import './modal.css'

const SurveillanceModel = ({ matchID, marketID, marketType, onCloseModel, isOpen, setOpen }) => {
    const [data, setData] = useState([]);
    const [currentKeyIndex, setCurrentKeyIndex] = useState(0);
    const [modalsVisible, setModalsVisible] = useState(false);

    const keys = Object.keys(data);

    useEffect(() => {
        const fetchData = async () => {
            if (matchID && marketID && marketType && isOpen) {
                try {
                    const response = await getCaller(`api/v1/surveillance?match=${matchID}&market=${marketID}&market_type=${marketType}`);
                    if (Object.keys(response?.data).length) {
                        setData(response.data);
                    } else {
                        onClose();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchData()
    }, [marketID, marketType, matchID, isOpen]);

    useEffect(() => {
        if (isOpen) {
            setCurrentKeyIndex(0);
            setModalsVisible(true)
        }
    }, [isOpen]);

    const onDecline = async () => {
        try {
            setOpen(false);
            setModalsVisible(false);
        } catch (error) {
            console.error(new Error(error));
        }
    }

    const onClose = () => {
        if (currentKeyIndex < keys.length - 1) {
            setCurrentKeyIndex(currentKeyIndex + 1);
        } else {
            setOpen(false);
            setModalsVisible(false)
            setCurrentKeyIndex(0)
            onCloseModel();
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', handleKeyPress);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isOpen]);

    return (
        <>
            {keys.map((key, index) => (
                <BigModal key={key} isOpen={modalsVisible && index === currentKeyIndex} onClose={onClose} style1={{ minWidth: '100%', width: 'unset' }}>
                    <div style={{ overflow: 'scroll', height: '100vh' }}>
                        <div className="header-container">
                            <button className="side-button" onClick={onDecline}>Decline</button>
                            <h3 className="center-text">{key.replace('_', ' ')}</h3>
                            <button className="side-button" onClick={onClose}> {index === (keys.length - 1) ? 'Submit' : 'Next'}</button>
                        </div>
                        <table width="100%">
                            <thead>
                                <tr className='custom-row'>
                                    <th>Surveillance 1.</th>
                                    <th>Surveillance 2.</th>
                                    <th>Surveillance 3.</th>
                                    <th>Surveillance 4.</th>
                                    <th>Surveillance 5.</th>
                                    <th>Surveillance 6.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="custom-row">
                                    <td>{((data[key].filter(d => d.type === 1)).map(d => <p>{`${d.user_id || ''}|${d.ip || ''}`}</p>))}</td>
                                    <td>{((data[key].filter(d => d.type === 2)).map(d => <p>{`${d.user_id || ''}`}</p>))}</td>
                                    <td>{((data[key].filter(d => d.type === 3)).map(d => <p>{`${d.user_id || ''}`}</p>))}</td>
                                    <td>{((data[key].filter(d => d.type === 4)).map(d => <p>{`${d.user_id || ''}`}</p>))}</td>
                                    <td>{((data[key].filter(d => d.type === 5)).map(d => <p>{`${d.user_id || ''}`}</p>))}</td>
                                    <td>{((data[key].filter(d => d.type === 6)).map(d => <p>{`${d.user_id || ''}|${d.bet_slip_count || ''}`}</p>))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </BigModal>
            ))}
        </>
    );
};

export default SurveillanceModel;