import "../addEvent/addevent.css";
import { MdDelete } from "react-icons/md";
import React, { useState, useEffect } from "react";
import MarketSettingsTab from "./MarketSettingsTab";
import { postCaller, getCaller, deleteCaller } from "../../services/api";
import { showToast, showSuccessMessage, showConfirmationDialog, showDeleteMessage } from "../../utilities/toastHelper";

const OddsGap = () => {
    const [oddsYes, setOddsYes] = useState('');
    const [oddsNo, setOddsNo] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [gapList, setGapList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;

    const fetchOddsGapList = async () => {
        try {
            const response = await getCaller(`api/v1/settings/alloddGapSettings`);
            if (response.success) {
                setGapList(response.data || []);
            }
        } catch (error) {
            showToast("Failed to fetch odds gap list", true);
        }
    };

    useEffect(() => {
        fetchOddsGapList();
    }, []);

    useEffect(() => {
        setIsButtonEnabled(oddsYes !== '' && oddsNo !== '');
    }, [oddsYes, oddsNo]);

    const handleSubmit = async () => {
        const oddsGapData = { odds_yes: oddsYes, odds_no: oddsNo };

        try {
            const eventResponse = await postCaller(`api/v1/settings/addOddGapSettings`, oddsGapData);
            if (eventResponse) {
                showSuccessMessage("Odds Gap has been added successfully.");
                setOddsYes('');
                setOddsNo('');
                fetchOddsGapList();
            }
        } catch (error) {
            showToast("Failed to add odds gap", true);
        }
    };

    const handleDelete = async (id) => {
        const result = await showConfirmationDialog("You want to delete this odds gap setting?");
        if (result.isConfirmed) {
            try {
                const deleteResponse = await deleteCaller(`api/v1/settings/deleteOddsSettings?id=${id}`);
                if (deleteResponse && deleteResponse.success) {
                    showDeleteMessage("Odds Gap setting deleted successfully.");
                    fetchOddsGapList();
                }
            } catch (error) {
                showToast("Failed to delete odds gap setting", true);
            }
        }
    };

    const filteredGapList = gapList.filter(gap =>
        (gap.odds_no.toString().includes(searchQuery) || gap.odds_yes.toString().includes(searchQuery))
    );

    const totalPages = Math.ceil(filteredGapList.length / itemsPerPage);
    const currentItems = filteredGapList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <div className="wrapper">
                <MarketSettingsTab />
                <div className="wrapper-head" style={{ marginTop: "12px" }}>
                    <h3> Add New Odds Gap </h3>
                </div>

                <div className="input-container market-count" style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <input
                        type="number"
                        placeholder="Enter Odds No"
                        className="market-count-input"
                        value={oddsNo}
                        onChange={(e) => setOddsNo(e.target.value)}
                    />
                    <span style={{ fontSize: "24px", margin: "0px", verticalAlign: "middle" }}>:</span>
                    <input 
                        type="number"
                        placeholder="Enter Odds Yes"
                        className="market-count-input"
                        value={oddsYes}
                        onChange={(e) => setOddsYes(e.target.value)}
                    />
                    <button 
                        className="update-league-btn centerBtn" 
                        type="button" 
                        style={{ marginLeft: '0px' }} 
                        onClick={handleSubmit}
                        disabled={!isButtonEnabled}
                    >
                        Save
                    </button>
                </div>

                <div className="table-border">
                    <div className="wrapper-body">
                        <div className="wrapper-content" style={{ width: "100%" }}>
                            <h2>Odds Gap List</h2>
                            <input 
                                type="search" 
                                autoComplete="off" 
                                placeholder='Search Odds' 
                                className='result-input' 
                                style={{ width: '20%' }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                    {currentItems.length === 0 ? (
                        <div className="no-data">No List Found</div>
                    ) : (
                        <table className="odds-gap-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Odds No</th>
                                    <th>Odds Yes</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((gap, index) => (
                                    <tr key={index}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{gap.odds_no}</td>
                                        <td>{gap.odds_yes}</td>
                                        <td>
                                            <div className="user-btn-action">
                                                <button className="edit-btn delete-btn" onClick={() => handleDelete(gap.id)}>
                                                    <MdDelete style={{ fontSize: '1.5rem', color: 'white' }}/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="pagination">
                    <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Previous</button>
                    <span>Page {currentPage} </span>
                    <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>Next</button>
                </div>
            </div>
        </>
    );
};

export default OddsGap;