import { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';

const SocketComponent = ({ eventID, dataFromChild, setDataFromChild ,bookmakerOddsData,onDataReceived}) => {
    const URL = process.env.REACT_APP_BASE_URL
    const socketRef = useRef(null);
    const [marketData, setMarketData] = useState(null);

    useEffect(() => {
        const getAssignMarket = async () => {
            try {
                const response = await fetch(`${URL}/api/v1/events/matchDetails/${eventID}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setMarketData(data?.data)
                    setDataFromChild(data?.data?.match?.matchOddData?.[0]);
                } else {
                    const error = await response.json();
                    console.error('Error fetching market data:', error.message);
                }
            } catch (error) {
                console.error('Error fetching market data:', error.message);
            }
        };

        getAssignMarket();

        const intervalId = setInterval(getAssignMarket, 2000);

        return () => clearInterval(intervalId);
    }, [eventID]);

    useEffect(() => {
        if (!URL) return;

        socketRef.current = io(URL, {
            transports: ["websocket"],
            autoConnect: false,
        });

        const socket = socketRef.current;

        const handleConnect = () => {
            if (marketData?.match?.matchOddData[0]?.mid) {
                socket.emit('subMarket', marketData.match.matchOddData[0].mid);
            }
            if(onDataReceived?.bookmaker?.id){
                socket.emit('subMarket', onDataReceived?.bookmaker?.id.toString());
            }
        };

        const handleDisconnect = () => {
            console.warn('Disconnected from WebSocket');
        };

        const handleError = (error) => {
            console.error('Connection error:', error);
        };

        socket.on('connect', handleConnect);
        socket.on('disconnect', handleDisconnect);
        socket.on('connect_error', handleError);
        socket.on('App\\\\Events\\\\MarketBroadcastData', (data) => {
            // setDataFromChild(data);
            // setMarketData(data);
        });
        socket.on('App\\\\Events\\\\BroadcastBookmaker', (data) => {
            bookmakerOddsData(data)
        });
        
        socket.connect();

        return () => {
            if (socket) {
                socket.off('connect', handleConnect);
                socket.off('disconnect', handleDisconnect);
                socket.off('connect_error', handleError);
                socket.off('App\\\\Events\\\\MarketBroadcastData');
                socket.off('App\\\\Events\\\\BroadcastBookmaker');
                // socket.disconnect();
            }
        };
    }, [URL, marketData]);

    const convertToK = (value) => {
        if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
        }
        return value.toString();
    };

    return (
        <div>
            <div className="book-maker-market">
                <div className="market-match-container">
                    <div>
                        <div>
                            <div className="" style={{ overflowX: "auto" }}>
                                <div className="match-name-time"></div>
                                <div className="market-odds-body">
                                    <div className="span-book">
                                        <p> MAIN ODDS </p>
                                    </div>
                                    <div className="market-odds-body">
                                        {/* <p> {marketData?.marketName} </p> */}
                                    </div>
                                    <div className="market-odds-back">
                                        <div style={{ marginRight: ".5rem" }}>
                                            <p>BACK</p>
                                        </div>
                                        <div>
                                            <p>LAY</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="boder-odd">
                                    {marketData?.match?.matchOddData.map((market, marketIndex) => (
                                        market?.runnerName?.map((m, i) => (
                                            !['No', 'Yes'].includes(m.RN) ? (
                                                <div key={i} className="odds-container">
                                                    <div className="box-odd-name" data-label="#">
                                                        <h3>{m.RN}</h3>
                                                    </div>
                                                    {dataFromChild?.runners?.map((runner, runnerIndex) => (
                                                        m.SID === runner.sid ? (
                                                            <div key={runnerIndex} className="odds-container-1">
                                                                {runner?.ex?.b && (
                                                                    <div className="back-box-container-one" data-label="#">
                                                                        <div className="box" style={{ backgroundColor: "rgb(136, 205, 244)" }}>
                                                                            <p>{runner?.ex?.b?.length ? runner.ex.b.reduce((maxObj, obj) => (obj.p > maxObj.p ? obj : maxObj), runner.ex.b[0]).p : null}</p>
                                                                            <p style={{ fontSize: "10px" }}>{convertToK(runner?.ex?.b?.length ? runner.ex.b.reduce((maxObj, obj) => (obj.p > maxObj.p ? obj : maxObj), runner.ex.b[0]).s : 0)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="back-box-container-one">
                                                                    <div className="box" style={{ backgroundColor: "rgb(215, 120, 125)" }}>
                                                                        <p>{runner?.ex?.l?.length ? runner.ex.l.reduce((maxObj, obj) => (obj.p < maxObj.p ? obj : maxObj), runner.ex.l[0]).p : null}</p>
                                                                        <p style={{ fontSize: "10px" }}>{convertToK(runner?.ex?.l?.length ? runner.ex.l.reduce((maxObj, obj) => (obj.p < maxObj.p ? obj : maxObj), runner.ex.l[0]).s : 0)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    ))}
                                                </div>
                                            ) : null
                                        ))
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocketComponent;
