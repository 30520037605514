import React from 'react'
import '../layout/layout.css'

const Dashboard = () => {
  return (
    <div className='dashboard'>
        {/* <p className='status'>In progress</p> */}
      </div>
  )
}

export default Dashboard