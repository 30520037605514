import "../addEvent/addevent.css";
import { MdDelete } from "react-icons/md";
import React, { useState, useEffect } from "react";
import MarketSettingsTab from "./MarketSettingsTab";
import { postCaller, getCaller, deleteCaller } from "../../services/api";
import { showToast, showConfirmationDialog, showDeleteMessage, showSuccessMessage } from "../../utilities/toastHelper";

const ManageBackSetting = () => {
    const [backFieldValue, setBackFieldValue] = useState("");
    const [backFieldList, setBackFieldList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const fetchBackFields = async () => {
        try {
            const response = await getCaller(`api/v1/settings/allBackSettings`);
            if (response && response.success) {
                setBackFieldList(response.data);
            }
        } catch (error) {
            showToast("Failed to fetch back field list.", true);
        }
    };

    useEffect(() => {
        fetchBackFields();
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9]+$/;

        if (regex.test(value) && Number(value) > 0) {
            setBackFieldValue(value);
        } else {
            setBackFieldValue("");
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSubmit = async () => {
        if (backFieldList.length >= 10) {
            showToast("You cannot add more than 10 values. Please delete a value first.", true);
            return;
        }

        const isDuplicate = backFieldList.some(field => field.back_field === Number(backFieldValue));
        
        if (isDuplicate) {
            showToast("This back field value already exists!", true);
            return;
        }

        const backData = {
            type: 2,
            back_field: backFieldValue,
        };
        
        try {
            const eventResponse = await postCaller(`api/v1/settings/addBackSettings`, backData);
            if (eventResponse) {
                showSuccessMessage("Back field has been added successfully.");
                setBackFieldValue("");
                fetchBackFields();
            }
        } catch (error) {
            showToast("Failed to add back field", true);
        }
    };

    const handleDelete = async (id) => {
        const confirmed = await showConfirmationDialog("You want to delete this back field?");
        if (confirmed.isConfirmed) {
            try {
                const response = await deleteCaller(`api/v1/settings/deleteBackSettings?id=${id}`);
                if (response && response.success) {
                    showDeleteMessage("Back field setting deleted successfully.");
                    fetchBackFields();
                }
            } catch (error) {
                showToast("Failed to delete back field setting", true);
            }
        }
    };

    const filteredBackFields = backFieldList.filter((backField) => 
        backField.back_field.toString().includes(searchQuery)
    );

    return (
        <div className="wrapper">
            <MarketSettingsTab />
            <div className="wrapper-head" style={{ marginTop: "12px" }}>
                <h3> Add New Back Fields </h3>
            </div>

            <div className="input-container market-count" style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <input
                    type="text"
                    placeholder="Enter Back Field"
                    className="market-count-input"
                    value={backFieldValue}
                    onChange={handleInputChange}
                />
                <button
                    className="update-league-btn centerBtn"
                    type="button"
                    style={{ marginLeft: '0px' }}
                    onClick={handleSubmit}
                    disabled={!backFieldValue}
                >
                    Save
                </button>
            </div>

            <div className="table-border">
                <div className="wrapper-body">
                    <div className="wrapper-content" style={{ width: "100%" }}>
                        <h2>Back Fields List</h2>
                        <input
                            type="search"
                            autoComplete="off"
                            placeholder="Search Back Value"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="result-input"
                            style={{ width: '20%' }}
                        />
                    </div>
                </div>
                {filteredBackFields.length === 0 ? (
                    <div className="no-data">No List Found</div>
                ) : (
                    <table className="odds-gap-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Back</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredBackFields.map((backField, index) => (
                                <tr key={backField.id}>
                                    <td>{index + 1}</td>
                                    <td>{backField.back_field}</td>
                                    <td>
                                        <div className="user-btn-action">
                                            <button className="edit-btn delete-btn" onClick={() => handleDelete(backField.id)}>
                                                <MdDelete style={{ fontSize: '1.5rem', color: 'white' }} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default ManageBackSetting;
