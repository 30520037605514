import * as Yup from 'yup';
import Select from 'react-select';
import '../customGames/custom.css';
import { useFormik } from 'formik';
import { AiOutlineClose } from 'react-icons/ai';
import Button from '../../component/button/Button';
import React, { useEffect, useState } from 'react';
import { selectStyles } from '../../services/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCaller, postCaller, updateCaller } from '../../services/api';
import { showConfirmationDialog, showSuccessMessage, showToast } from '../../utilities/toastHelper';

const CustomAddLeague = () => {
    const [sportList, setSportList] = useState([]);

    const navigate = useNavigate();
    const { state } = useLocation();

    const updateLeagueData = state?.leagueData ?? {};
    const isAddMode = !updateLeagueData.id;

    const validationLeague = Yup.object().shape({
        name: Yup.string().required('League Name is Required'),
        ...isAddMode ? { sport_name: Yup.string().required('Sport Name is Required') } : {},
    });

    useEffect(() => {
        async function getSport() {
            let sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse?.data);
        }
        getSport();
    }, []);

    const formik = useFormik({
        initialValues: {
            name: updateLeagueData ? updateLeagueData.name : '',
            ...isAddMode ? { sport_name: "" } : {}
        },
        validationSchema: validationLeague,
    
        onSubmit: async (values) => {
            const data = {
                name: values.name,
                ...isAddMode ? { sport_name: JSON.parse(values.sport_name).name } : {},
                ...isAddMode ? { sport_id: JSON.parse(values.sport_name).id } : {},
                ...!isAddMode ? { sport_id: updateLeagueData.sport_id } : {},
                ...!isAddMode ? { slug: updateLeagueData.slug } : {}
            }

            const confirmationText = isAddMode ? `You want to add this League (${data.name})?` : `You want to update this League (${data.name})?`;
            const result = await showConfirmationDialog(confirmationText);

            if (result.isConfirmed) {
                const apiCaller = isAddMode ? postCaller : updateCaller;
                const endpoint = isAddMode  ? "api/v1/leagues/addLeagues" : `api/v1/leagues/updateLeagues?id=${updateLeagueData.id}`;
                const response = await apiCaller(endpoint, data);

                if (response.success) {
                    showSuccessMessage(`Your League was ${isAddMode ? "added" : "updated"} successfully.`);
                    navigate("/custom_games/custom_league");
                } else {
                    showToast(response.message, true);
                }
            }
        },
    });

    return (
        <>
            <div className="sport-container">
                <div className='modal-container-one'>
                    <div className='add-agent-head'>
                        <div> { isAddMode ? "Add League" : "Update League" } </div>
                        <div className="ai-close">
                            <AiOutlineClose onClick={() => navigate('/custom_games/custom_league')}/>
                        </div>
                    </div>

                    <form onSubmit={formik.handleSubmit} >
                        <div className="add-sport-content">
                            {
                                isAddMode ? (
                                    <div className="add-sport-input">
                                        <label htmlFor="sport"> Sport <span style={{ color: 'red' }}>*</span></label><br />
                                        <Select name="sport_name" onBlur={formik.handleBlur} onChange={(selectedOption) => formik.setFieldValue('sport_name', selectedOption.value)}
                                            options={sportList.map((el, i) => ({ value: JSON.stringify(el), label: el.name }))} isSearchable={true} placeholder="Select Sport" styles={selectStyles}
                                        />
                                        {formik.errors.sport_name && formik.touched.sport_name && <span className="error" style={{ color: "red" }}>{formik.errors.sport_name}</span>}
                                    </div>
                                ) : null
                            }
                            <div className="add-sport-input" style={{ padding: isAddMode ? '15px 0px' : '0px 0px' }}>
                                <label htmlFor="sport"> League Name <span style={{ color: 'red' }}>*</span> </label> <br />
                                <input type="text" autoComplete="off" name="name" value={formik.values.name || ''} onChange={formik.handleChange} onBlur={formik.handleBlur} id="" placeholder='League Name'/>
                                {formik.errors.name && formik.touched.name && <span className="error" style={{ color: "red" }}>{formik.errors.name} </span>}
                            </div>
                        </div>
                        <div className="sport-btn-container">
                            <div>
                                <Button type='submit' className='submit' name={"Submit"} disabled={ isAddMode ? !(formik.values.name?.trim() && formik.values.sport_name) : !formik.values.name?.trim()}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

export default CustomAddLeague;
