import React from 'react';
import '../customGames/custom.css';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomGamesTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="wrapper">
        <div className="tab-container">
          <div  className={`tab-box ${location.pathname === '/custom_games/custom_sport' ? 'active-result-tab' : null}`} onClick={() => navigate('/custom_games/custom_sport')}>
            <h4> Custom Sport </h4>
          </div>

          <div className={`tab-box ${location.pathname === '/custom_games/custom_league' ? 'active-result-tab' : null}`} onClick={() => navigate('/custom_games/custom_league')}>
            <h4> Custom Leagues </h4>
          </div>

          <div className={`tab-box ${location.pathname === '/custom_games/custom_matches' ? 'active-result-tab' : null}`} onClick={()=> navigate('/custom_games/custom_matches')}>
            <h4> Custom Matches </h4>
          </div>
        </div>
      </div>
    </>
  )
};

export default CustomGamesTab;