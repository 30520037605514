import React, { useState, useEffect } from 'react'
import { getCaller } from '../../../services/api'
import Button from '../../../component/button/Button';
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import { updateCaller } from '../../../services/api';
import { useNavigate } from 'react-router-dom'
import '../downline.css'
import { showToast } from '../../../utilities/toastHelper';

const MainResultMaker = ({ event, sport, susTime, uid, userName }) => {
    const matchID = event.id;
    const [runners, setRunners] = useState([]);
    const [formData, setFormData] = useState({});
    const [odds, setOdds] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        
        const getData = async () => {
            try {
                const res = await getCaller(`api/v1/markets/custom/${matchID}`)
                const formData = (res?.data && res?.data[0]) || {};
                if (formData.runners) {
                    const realOdds = (typeof formData.odds === 'object' ? formData.odds : JSON.parse(formData.odds)) || {};
                    if (Object.keys(realOdds).length) {
                        setRunners(realOdds);
                        setFormData({
                            ...formData
                        });
                        return;
                    }

                    const selectionRunners = JSON.parse(formData.runners);
                    const runners = [];
                    const size = event.inplay_status ? formData.inplay_stake_limit : formData.max_stake_limit;

                    for (const runner of selectionRunners) {
                        runners.push({
                            sln: runner.RN,
                            si: runner.SID,
                            ss: "SUSPENDED",
                            ex: {
                                b: [{ p: 0, s: size }, { p: 0, s: size }, { p: 0, s: size }],
                                l: [{ p: 0, s: size }, { p: 0, s: size }, { p: 0, s: size }],
                            },
                        });
                    }
                    setRunners(runners);
                    setFormData((prevFormData) => ({
                        ...formData,
                        odds: runners,
                    }));

                }

            } catch (error) {
                console.error(error.message);
            }
        }
        getData();
    }, [matchID, event.inplay_status]);

    const handleSubmit = async () => {
        
        try {
            const res = await updateCaller(`api/v1/markets/custom/${formData.marketId}/assign/${uid}`, {
                odds: runners,
                match_id: formData.match_id,
                sport_id: sport,
                min_stake_limit: formData.min_stake_limit,
                max_stake_limit: formData.max_stake_limit,
                max_market_limit: formData.max_market_limit,
                inplay_stake_limit: formData.inplay_stake_limit,
                is_active: formData.is_active,
                odd_limit: formData.odd_limit,
                is_sus: formData.is_sus,
                bet_delay: formData.bet_delay,
                msg: formData.msg,
                market_name: formData.market_name,
                sus_time: susTime
            });
            if (res.success === true) {
                showToast("Main Market Updated Successfully", false )
                navigate('/downline');
            }

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        
        const odds = {};
        for (const runner of runners) {
            odds[runner.si] = {
                b: runner.ex.b[runner.ex.b.length - 1].p,
                l: runner.ex.l[0].p,
            }
        }
        setOdds(odds);
    }, [runners])


    const updateRunnerSelectionStatus = (si, value) => {
        
        const newRunners = [...runners];
        const runnerIndex = newRunners.findIndex(r => r.si === si);
        if (runnerIndex > -1) {
            newRunners[runnerIndex] = { ...newRunners[runnerIndex], ss: newRunners[runnerIndex].ss === !value ? 'ACTIVE' : 'SUSPENDED' };
        }
        setRunners(newRunners)
    }

    const updateRunner = (si, val, field) => {
        
        const newRunners = [...runners];
        const runnerIndex = newRunners.findIndex(r => r.si === si);
        const oddDiff = formData.odd_diff;
        const size = event.inplay_status ? formData.inplay_stake_limit : formData.max_stake_limit;
        if (runnerIndex > -1) {
            if (field === 'sln') {
                newRunners[runnerIndex].sln = val;
            }
            if (field === 'b') {
                const value = val || 0;
                newRunners[runnerIndex].ex = {
                    ...newRunners[runnerIndex].ex,
                    b: [
                        { p: ((parseFloat(value) - (parseFloat(oddDiff) * 2)).toFixed(2)) || 0, s: size },
                        { p: ((parseFloat(value) - parseFloat(oddDiff)).toFixed(2)) || 0, s: size },
                        { p: value || 0, s: size }
                    ]
                };
                if (value === "00") {
                    newRunners[runnerIndex].ss = "SUSPENDED";
                    newRunners[runnerIndex] = { ...newRunners[runnerIndex] };
                }
                const newOdds = { ...odds };
                newOdds[si] = { ...newOdds[si], b: val };
                setOdds(newOdds);
            } else if (field === 'l') {
                const value = val || 0;
                newRunners[runnerIndex].ex = {
                    ...newRunners[runnerIndex].ex,
                    l: [
                        { p: value || 0, s: size },
                        { p: ((parseFloat(value) + parseFloat(oddDiff)).toFixed(2)) || 0, s: size },
                        { p: ((parseFloat(value) + (parseFloat(oddDiff) * 2)).toFixed(2)) || 0, s: size },
                    ]
                };
                const newOdds = { ...odds };
                newOdds[si] = { ...newOdds[si], l: val };
                setOdds(newOdds);
            }
        }
        setRunners(newRunners)
    }

    return (
        <div style={{ overflowX: 'auto' }}>
            <div>

                <div className="market-book-maker-border">
                    <div className='market-bookmaker-popup' style={{ overflowX: "auto" }}>
                        <div className="">
                            <label htmlFor="match_id">MARKET NAME</label> <br />
                            <input type="text" autoComplete="off" name="marketName" className='odd-input' placeholder='Market name' value={formData.market_name || ""}
                                onChange={(e) => setFormData((formData) => ({ ...formData, market_name: e.target.value }))}
                            /> <br />
                            <label htmlFor="min_stake_limit">MIN STAKE LIMIT</label> <br />
                            <input type="number" autoComplete="off" name="min_stake_limit" className='odd-input' placeholder='Min stake limit' value={formData.min_stake_limit || ""}
                                onChange={(e) => setFormData((formData) => ({ ...formData, min_stake_limit: e.target.value }))}
                            /> <br />
                            <label htmlFor="max_stake_limit"> MAX STAKE LIMIT </label> <br />
                            <input type="number" autoComplete="off" name="max_stake_limit" className='odd-input' placeholder='Max stake limit' value={formData.max_stake_limit || ""}
                                onChange={(e) => setFormData((formData) => ({ ...formData, max_stake_limit: e.target.value }))}
                            /> <br />
                            <label htmlFor="inplay_stake_limit">INPLAY STAKE LIMIT </label> <br />
                            <input type="number" autoComplete="off" name="inplay_stake_limit" className='odd-input' placeholder='Inplay stake limit' value={formData.inplay_stake_limit || ""}
                                onChange={(e) => setFormData((formData) => ({ ...formData, inplay_stake_limit: e.target.value }))}
                            /> <br />
                            <label htmlFor="max_market_limit"> MAX MARKET LIMIT </label> <br />
                            <input type="number" autoComplete="off" name="max_market_limit" className='odd-input' placeholder='Max market limit' value={formData.max_market_limit || ""}
                                onChange={(e) => setFormData((formData) => ({ ...formData, max_market_limit: e.target.value }))}
                            /> <br />
                            <label htmlFor="delayBetting">DELAY </label> <br />
                            <input type="number" autoComplete="off" name="delayBetting" className='odd-input' placeholder='Delay betting' value={formData.bet_delay || ""}
                                onChange={(e) => setFormData((formData) => ({ ...formData, bet_delay: e.target.value }))}
                            /> <br />
                            <label htmlFor="delayBetting"> ODDS LIMIT </label> <br />
                            <input type="number" autoComplete="off" name="odd limit" className='odd-input' placeholder='Odd limit' value={formData.odd_limit || ""}
                                onChange={(e) => setFormData((formData) => ({ ...formData, odd_limit: e.target.value }))}
                            /> <br />
                            <label htmlFor="delayBetting"> MESSAGE </label> <br />
                            <input type="text" autoComplete="off" name="msg" className='odd-input' placeholder='Message' value={formData.msg || ""}
                                onChange={(e) => setFormData((formData) => ({ ...formData, msg: e.target.value }))}
                            /> <br />

                        </div>

                        <div>
                            <div className="suspend-container">
                                <div className="suspend-container-body"> <p className='suspend-para'> Suspend </p>  <ToogleButton defaultChecked={formData.is_sus === 1} value={formData.is_sus}
                                    onChange={(e) => setFormData((formData) => ({ ...formData, is_sus: e ? 1 : 0 }))}
                                />   </div>

                                <div className="suspend-container-body"> <p className='suspend-para'> Active </p>  <ToogleButton defaultChecked={formData.is_active === 1} value={formData.is_active}
                                    onChange={(e) => setFormData((formData) => ({ ...formData, is_active: e ? 1 : 0 }))}
                                />  </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                    <table width="100%">
                        <thead id='fancy-head-coloumn'>
                            <tr className=''>
                                <th align='left'> TEAM NAME </th>
                                <th align='left'> BACK </th>
                                <th align='left'> LAY </th>
                                <th align='left'> SUSPEND </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                runners.map((runner) => (
                                    <tr className='fancy-head-row' key={runner.si}>
                                        <td> <input type="text" autoComplete="off" name="selectionName" className='book-maker-column-input-1' placeholder='Team name' value={runner.sln || ""}
                                            onChange={(event) => { updateRunner(runner.si, event.target.value, 'sln') }}
                                        /> </td>

                                        <td>
                                            <div>
                                                <input type="number" autoComplete="off" name="backOdds" className="book-maker-column-input" placeholder="Back Price" value={((odds[runner.si] && odds[runner.si].b) || "")}
                                                    onChange={(event) => {
                                                        updateRunner(runner.si, event.target.value, 'b')
                                                    }}
                                                />

                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input type="number" autoComplete="off" name="layOdds" className="book-maker-column-input" placeholder="Lay Price" value={((odds[runner.si] && odds[runner.si].l) || "")}
                                                    onChange={(event) => {
                                                        updateRunner(runner.si, event.target.value, 'l')
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td align='left' >
                                            <ToogleButton defaultChecked={runner.ss === 'SUSPENDED' ? true : false}
                                                onChange={(value) => { updateRunnerSelectionStatus(runner.si, value) }}
                                            />
                                        </td>
                                    </tr>
                                )
                                )
                            }

                        </tbody>
                    </table>

                    <div className='bookmaker-btn'>
                        <Button className='submit' name={'Update'} type="button" onClick={handleSubmit} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MainResultMaker
