import React, { useState } from 'react'
import '../layout.css'
import logo from '../../assets/logo.png'
import SideBar from '../sideBar/SideBar'
import {CgMenu} from 'react-icons/cg'
import {AiFillCloseCircle} from 'react-icons/ai'
const Header = () => {
   const [menuOpen,setMenuOpen] = useState(false)
   let userType = localStorage?.getItem('user_role')?.toLowerCase();
   return (
      <>
      {
      userType==='manager'||userType==='admin'?
      <div className="top-wrapper">
         <div className="sidebar">
         <div className="header-logo">
         <div className='header-logo-img'><img src={logo} alt="" />  </div>
         {
            menuOpen?<div className="menu-btn" onClick={()=>setMenuOpen(false)}><AiFillCloseCircle className='menu-icon'/></div>:
            <div className="menu-btn" onClick={()=>setMenuOpen(true)}><CgMenu className='menu-icon'/></div>
         }
            </div>
            <SideBar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
         </div>
      </div>:<div className="top-wrapper-agent">
         <div className="sidebar-agent">
         {
            menuOpen?<div className="menu-btn" onClick={()=>setMenuOpen(false)}><AiFillCloseCircle className='menu-icon'/></div>:
            <div className="menu-btn" onClick={()=>setMenuOpen(true)}><CgMenu className='menu-icon'/></div>
         }
            <SideBar  menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
         </div>
      </div>}
      </>
   )
}
export default Header