import React, { useState, useEffect } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { IoSearchOutline, } from 'react-icons/io5'
import Pagination from '../../component/pagination/Pagination'
import Button from '../../component/button/Button'
import { getCaller, updateCaller, postCaller } from '../../services/api';
import { callInInterval } from '../../services/utils'
import { BsChevronDown, BsChevronRight } from "react-icons/bs"
import moment from 'moment';
import getLocalData from '../../utilities/LocalStorageData.utilities.js'
import { showToast, showSuccessMessage, showConfirmationDialog} from '../../utilities/toastHelper';

const BookmakerResultTable = ({ bookmakerResultData, setBookmakerResultData, allBookmakerResult, pagination}) => {
  const [inputValue, setInputValue] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [rowOpen, setRowOpen] = useState(null)
  const [showResultStatus, setShowResultStatus] = useState(false);
  const [showRollbackStatus, setShowRollbackStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(pagination?.currentPage || 1);
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [scope,setScope] = useState({})
  let completeData = bookmakerResultData?.filter((el) => el?.event_name?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1);

  useEffect(()=>{
    const {rollback, result} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({rollback, userType, result})
  },[])

  const handlePageChange = async(page) => {
    setCurrentPage(page);
    await allBookmakerResult(page)
  };

  const handleFancyResultType = async (e) => {
    setInputValue(e)
    callInInterval(updateData, e)

  };

  const updateData = async (data) => {
    const resultResponse = await getCaller(`api/v1/results/bookmaker/${data}`)
    setBookmakerResultData(resultResponse.data)

  };

  const handleRollBack = async (resultID) => {
    
    await showConfirmationDialog('You want to Rollback the Result?')
    .then(async (result) => {
      if (result.isConfirmed) {
        const data = await updateCaller(`api/v1/results/${resultID}/rollback`);
        if (data.success === true) {
          await showSuccessMessage('The result was rollBacked successfully!')
          .then(async function () {
            setBookmakerResultData(bookmakerResultData.map(e => e.id !== resultID ? e : { ...e, is_rollback: 1 }))
          })
        }
        else {
          showToast ( data.message , true)
        }
      }
    })
  };

  const declareResult = async (id, operator) => {
    let data = {
      id: id,
      operator: operator
    };

    const formatOperator = operator.replace(/([A-Z]+)([A-Z][a-z]+)/, '$1 $2').toLowerCase().replace(/(^\w|\s\w)/g, char => char.toUpperCase());

    await showConfirmationDialog(`You want to Declare Result for ${formatOperator} market?`)
    .then(async (result) => {
      if (result.isConfirmed) {
        const res = await postCaller(`api/v1/results/declareResult`, data);
        if (res) {
          await showSuccessMessage('The result was declared successfully!')
          .then(() => {
            setDisabledButtons((prevDisabled) => [...prevDisabled, operator]);
          });
        } else {
          showToast ( 'Failed to declare the result. Please try again.' , true)
        }
      }
    });
  };

  const toggleRow = (index) => {
    if (rowOpen === index) {
      setRowOpen(null)
    } else {
      setRowOpen(index)
    }
  };

  const toggleResultStatus = () => {
    setShowResultStatus(!showResultStatus)
    setShowRollbackStatus(false)
  };

  const toggleRollbackStatus = () => {
    setShowRollbackStatus(!showRollbackStatus)
    setShowResultStatus(false)
  };

  const filteredManagers = completeData?.filter((market) => {
    const marketName = market.market_name ? market.market_name.toLowerCase() : "";
    const eventName = market.event_name ? market.event_name.toLowerCase() : "";
    
    return marketName.includes(searchValue.toLowerCase()) || eventName.includes(searchValue.toLowerCase());
});
  
  return (
    <>
      <div className="table-border" >
        <div className="wrapper-body">
          <div className="wrapper-content-1">
            <div className='searchcard'>
              <input type="text" autoComplete="off" name="" id="" placeholder='Search' className='result-input'
                // onChange={event => { handleFancyResultType(event.target.value) }}
                onChange={(event) => setSearchValue( event.target.value )}
                value={searchValue}
              />
              {searchValue ? <div className="ai-close">
                <AiOutlineClose onClick={(event) => setSearchValue('')}
                />
              </div> : <div className='ai-close'> <IoSearchOutline /></div>
              }
            </div>
          </div>
        </div>
        <div style={{ overflowX: "auto" }} >
          <table width="100%" >
            <thead>
              <tr className='custom-row' >
                <th style={{ textAlign:"center"}}> # </th>
                <th><div style={{ width: "105px" }}> Declared By </div></th>
                <th><div> SPORT </div> </th>
                <th><div> EVENT NAME </div></th>
                <th><div style={{ width: "115px" }} > MARKET NAME </div> </th>
                <th><div> RESULT </div> </th>
                <th><div> DATE </div></th>
                {
                  (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <th> <div> ROLLBACK </div> </th>
                }
              </tr>
            </thead>
            <tbody>
              {
                filteredManagers?.length > 0 ?
                filteredManagers?.map((e, i) => (
                    <React.Fragment key={i}>
                      <tr className='custom-row' key={i} onClick={() => toggleRow(i)}>
                        <td> <div className='result_status'>
                          {rowOpen === i ? (
                            <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold",  marginRight: "8px" }} />
                          ) : (
                            <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold",  marginRight: "8px" }} />
                          )} 
                          {e.id}
                        </div></td>   
                        <td > {e.created_by ? e.created_by : "No Data"} </td>
                        <td style={{ textTransform: "capitalize" }}> {e.sport_name ? e.sport_name : "No Data"} </td>
                        <td > <div style={{ width: "250px" }}>{e.event_name ? e.event_name : "No Data"} </div> </td>
                        <td ><div style={{ width: "200px" }}> {e.market_name ? e.market_name : "No Data"}</div> </td>
                        <td > <div style={{ width: "150px" }}>{e.selection ? e.selection : "No Data"}</div>  </td>
                        <td > <div style={{ width: "190px" }}>{(new Date(e.created_at)).toLocaleString()}</div> </td>
                        {
                         (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <td >
                            <div> <Button className = 'submit' type = 'button' name = 'RollBack' onClick = {() => handleRollBack(e.id)} disabled = {(scope?.userType === 'manager' && !scope?.rollback.add) || e.is_rollback} /> </div>
                          </td>
                        }
                      </tr>
                      {
                        rowOpen === i && (
                          <>
                            <tr className='custom-row' onClick={toggleResultStatus}>
                              <td colSpan={8}>
                                <div className='result_status'>&nbsp; &nbsp;
                                  {showResultStatus ? (
                                    <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  ) : (
                                    <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  )}
                                  Result Status
                                </div>
                              </td>
                            </tr>

                            {e.result_status && showResultStatus && (
                              <tr className='custom-row'>
                                <td colSpan={9}>
                                  <div className='result_status_table'>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Market</th>
                                          <th>Request Time</th>
                                          <th>Status</th>
                                          <th>Start Time</th>
                                          <th>End Time</th>
                                          <th>Process Count</th>
                                          <th>User Count</th>
                                          <th>Bet Count</th>
                                          <th>End Point</th>
                                          <th>Declare Result</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.entries(JSON.parse(e.result_status)).map(([market, data], index) => (
                                          <tr key={index}>
                                            <td>{market}</td>
                                            <td>{(data?.requestTime) ? moment.unix(data.requestTime).format('YYYY-MM-DD HH:mm'): '-'} </td>
                                            <td>{data.status}</td>
                                            <td>{(data.startTime === '-') ? data.startTime : moment.unix(data.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{(data.endTime === '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{data.processCount}</td>
                                            <td>{data.userCount}</td>
                                            <td>{data.betCount}</td>
                                            <td>{data.endpoint}</td>
                                            <td className='custom-row'>
                                              <button className='basic-button submit' style={{textWrap:"nowrap"}} 
                                                disabled={ (scope?.userType==='manager' && !scope?.result?.add) || disabledButtons.includes(market) || (e.operators_list ? JSON.parse(e.operators_list).includes(market) : false) || e.is_rollback} 
                                                onClick={() => declareResult(e.id, market)}>Declare Result
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                            { e.is_rollback ? (
                            <tr className='custom-row' onClick={toggleRollbackStatus}>
                              <td colSpan={8}>
                                <div className='result_status'> &nbsp; &nbsp;  
                                  {showRollbackStatus ? (
                                    <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  ) : (
                                    <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  )}
                                  Rollback Status
                                </div>
                              </td>
                            </tr>
                            ) : "" }
                            {e.rollback_status && showRollbackStatus && (
                              <tr className='custom-row'>
                                <td colSpan={9}>
                                  <div className='result_status_table'>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Market</th>
                                          <th>Request Time</th>
                                          <th>Status</th>
                                          <th>Start Time</th>
                                          <th>End Time</th>
                                          <th>Process Count</th>
                                          <th>User Count</th>
                                          <th>Bet Count</th>
                                          <th>End Point</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.entries(JSON.parse(e.rollback_status)).map(([market, data], index) => (
                                          <tr key={index}>
                                            <td>{market}</td>
                                            <td>{(data?.requestTime) ? moment.unix(data.requestTime).format('YYYY-MM-DD HH:mm'): '-'} </td>
                                            <td>{data.status}</td>
                                            <td>{(data.startTime === '-') ? data.startTime : moment.unix(data.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{(data.endTime === '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{data.processCount}</td>
                                            <td>{data.userCount}</td>
                                            <td>{data.betCount}</td>
                                            <td>{data.endpoint}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        )
                      }
                    </React.Fragment>

                  )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
   
       <Pagination
                    currentPage={currentPage}
                    totalPages={pagination?.totalPages }
                    onPageChange={handlePageChange} 
                />
    </>
  )
}

export default BookmakerResultTable