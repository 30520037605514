import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ToogleButton from "../../../component/toggleButton/ToogleButton";
import { getCaller, updateCaller } from "../../../services/api";
import { MdDelete } from 'react-icons/md';
import { motion } from "framer-motion";
import MatchAccordian from "./MatchAccordian";
import '../leagueFromSport/leagueManage.css';
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import getLocalData from "../../../utilities/LocalStorageData.utilities";
import { showSuccessMessage, showConfirmationDialog, showToast} from '../../../utilities/toastHelper';

const LeagueAccordian = ({ leagueData, setLeagueData, league, index }) => {
  const [open, setOpen] = useState(false);
  const [matches, setMatches] = useState([]);
  const [sportId, setSportId] = useState('');
  const [scope, setScope] = useState({})

  useEffect(() => {
    const { result, event } = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({ event, result, userType })
  }, [])

  const getMatchFromLeague = async (status, sportId) => {

    setSportId(sportId);
    if (!matches || matches.length === 0) {
      const leagueResponse = await getCaller(`api/v1/events/matchFindById?id=${league.id}`);
      setMatches(leagueResponse.data);
      setOpen(true);
    }
    setOpen(!open);
  };

  async function handleDeleteLeague(id, sport_id, i) {
    await showConfirmationDialog( `You want to Delete (${league.name}) `)
    .then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateCaller(`api/v1/leagues/deleteLeagues?id=${id}&sport_id=${sport_id}`);
        if (response.success) {
          await showSuccessMessage(`Your League ${league.name} is deleted Successfully`).then(() => {
            const newList = [...leagueData];
            newList.splice(i, 1);
            setLeagueData(newList);
          });
        } else {
          showToast( response.message, true)
        }
      }
    });
  }

  const handleMatchStatus = async (value, id) => {
    let newVal = JSON.parse(JSON.stringify(leagueData));
    newVal[index].match_is_active = value ? 1 : 0;
    setLeagueData(newVal);
    const res = await updateCaller(`api/v1/leagues/${id.id}`, { match_is_active: value });
    if (res.success === true) {
      showToast("Match Setting Changed successfully", false)
    }
  };

  return (
    <>
      <motion.div className="league-setting-container" initial={{ opacity: 0, x: '-100vh' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}>
        <div className="league-setting-body">
          <button className={`accordion ${open ? 'active' : 'inactive'}`} disabled={parseInt(league.is_active) === 0} onClick={() => getMatchFromLeague(league.is_active, league.sport_id)} >
            {!open ? <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /> : <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} />} {league.name} {!league.match_count && <span style={{ marginLeft: '5px' }}>🚩</span>}
          </button>
          <div className="league-setting-content">
            <div className='league-setting-btn'>
              <p className='league-para'> Match Active </p>
              <ToogleButton disabled={(scope?.userType === 'manager' && !scope?.event?.update)} defaultChecked={parseInt(league.match_is_active) === 0 ? false : true} onChange={(event) => handleMatchStatus(event, league)} />
            </div>

            <button
              className="delete-icon league_icon"
              disabled={(scope?.userType === 'manager' && !scope?.event?.delete) || parseInt(league.is_active) === 0}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                  handleDeleteLeague(league.id, league.sport_id, index);
                }
              }}
            >
              <MdDelete
                style={{ fontSize: "1rem", color: "white" }}
                onClick={() => handleDeleteLeague(league.id, league.sport_id, index)}
              />
            </button>
          </div>
        </div>
      </motion.div>
      <div className="league-accordian-container" style={{ display: open ? 'block' : 'none' }}>
        {
          matches?.length > 0 ?
            matches.map((match, i) => (
              <MatchAccordian
                match={match}
                key={i}
                index={i}
                setMatches={setMatches}
                matches={matches}
                sportId={sportId}
                leagueData={leagueData}
                setLeagueData={setLeagueData}
              />
            )) :
            <div className='no_data'>
              <p>No Data found</p>
            </div>
        }
      </div>
    </>
  );
};

LeagueAccordian.propTypes = {
  leagueData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sport_id: PropTypes.number.isRequired,
      is_active: PropTypes.number.isRequired,
      match_is_active: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setLeagueData: PropTypes.func.isRequired,
  league: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sport_id: PropTypes.number.isRequired,
    is_active: PropTypes.number.isRequired,
    match_is_active: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default LeagueAccordian;
