import React from 'react';
import moment from 'moment-timezone';

const ServerToBrowserTime = ({ serverTime }) => {
    // Parse the server time assuming it is in UTC
    const serverMoment = moment(serverTime);

    // Convert to the browser's local time zone
   // const browserMoment = serverMoment.tz(moment.tz.guess());

    // Format the browser's local date-time
    const formattedBrowserTime = serverMoment.format('YYYY-MM-DD HH:mm');

    return (
       
            <p>{formattedBrowserTime}</p>
        
    );
};

export default ServerToBrowserTime;
