import React from 'react'
import logo from '../assets/logo.png'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const navigate = useNavigate()
  return (
   <>
    <div className="top-wrapper-user">
         <div className="header-user">
         <div className='header-logo-img user-logo' onClick={()=>navigate('/home')}><img src={logo} alt="" />  </div>
         </div>
      </div>
    
   </>
  )
}

export default Navbar