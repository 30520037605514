import React from 'react'
import '../../../component/toggleButton/ToogleButton'
import { motion } from "framer-motion";
import CustomBookmakerAdd from '../ManagerManageMarket/CustomBookmakerAdd'
import Button from '../../../component/button/Button';
import '../downline.css'

const BookmakerResult = ({event, sport, setMarketOpen, uid, susTime}) => {
  return (
    <>
      {
          <motion.div className='book-maker-market' initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01]
            }}>
            <div className="book-maker-heading">
              <p className='book-result-name'> BOOKMAKER RESULT DECLARE </p>
              <div className='remove-market-container'>
               <Button className='cancel' name='Remove Market' onClick={() => setMarketOpen(false) } /> 

              </div>
            </div>
            <form >
              <div className="book-maker-content">
                <div className="book-maker-select">
                  <label> Select bookmaker </label> <br />
                  <select name="" id="" className='fancy-result-input' >
                    <option value=""> Select </option>
                  </select>
                </div>
                <div className="book-maker-select">
                  <label> Result </label> <br />
                  <select name="" id="" className='fancy-result-input'>
                    <option value=""> Select </option>
                    
                  </select>
                </div>
                <div className='book-maker-btn' style={{ marginTop: "1.8rem" }}>
                  <Button type='submit' disabled={true} name='Submit Result' className='submit' />
                </div>
              </div>
            </form>
            <CustomBookmakerAdd uid={uid} eventId={event.event_id} eventName={event.event_name} matchId={event.id} susTime={susTime} />
          </motion.div>
      }
    </>
  )
}

export default BookmakerResult