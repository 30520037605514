import React, { useEffect, useState, useRef } from 'react';
import ResultTab from './ResultTab';
import { getCaller, postCaller } from '../../services/api';
import { AiOutlineClose } from 'react-icons/ai'
import { IoSearchOutline, } from 'react-icons/io5'
import '../result/result.css';
import Button from '../../component/button/Button';
import { motion } from 'framer-motion';
import Pagination from '../../component/pagination/Pagination';
import SurveillanceModel from '../../component/modal/SurveillanceModel';
import Select from 'react-select';
import Result from './result';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { selectStyles } from '../../services/utils'
import { showToast, showSuccessMessage, showConfirmationDialog} from '../../utilities/toastHelper';

const PendingFancy = ({ submitResult }) => {
  const [pendingFancyData, setPendingFancyData] = useState([]);
  const [searchField, setSearchField] = useState('');
  const [manualResultList, setManualResultList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sportList, setSportList] = useState([]);
  const [sport, setSport] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventList, setEventList] = useState([]);
  const limit = 100;
  const [isOpen, setOpen] = useState(false);
  const [index, setIndex] = useState(-1);
  const intervalRef = useRef(null); // Ref to store the interval ID
  const [operatorList, setOperatorList] = useState([]);
  const [operator, setOperator] = useState('');
  const [scope,setScope] = useState()
  const [counter, setCounter] = useState(0);
  const [counterInterval, setCounterInterval] = useState(null);

  useEffect(() => {
    const {result} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({result, userType})
    async function getSport() {
      let sportResponse = await getCaller('api/v1/sports/management/getSport');
      setSportList(sportResponse.data);
    }
    getSport();
  }, []);

  const handleSport = async (event) => {
    setEventList([]);
    setEventId("");
    setPendingFancyData([]);
    setCounter(0);

    if (counterInterval) {
      clearInterval(counterInterval);
      setCounterInterval(null);
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    const selectedSport = event.value;
    setSport(selectedSport);
    const eventResponse = await getCaller(`api/v1/events/findEvents?id=${selectedSport}`);
    setEventList(eventResponse.data);
  };

  const getCount = () => {
    let timeleft = 30;
    setCounter(timeleft);
    let downloadTimer = setInterval(() => {
      timeleft -= 1;
      setCounter(timeleft);
      if (timeleft <= 0) {
        timeleft = 30;
      }
    }, 1000);

    setCounterInterval((oldDownloadTimer) => {
      if (oldDownloadTimer) {
        clearInterval(oldDownloadTimer);
      }
      return downloadTimer;
    });
  };

  const handleEvent = async (event) => {
    const selectedEventId = event.value;

    if (selectedEventId !== eventId) {
        setEventId(selectedEventId);
        setCurrentPage(1);
        
        await getPendingFancyData(selectedEventId, 1); 
        getCount();
    }

    if (event === "") {
        setCounterInterval((oldDownloadTimer) => {
            if (oldDownloadTimer) {
                clearInterval(oldDownloadTimer);
            }
            return null;
        });
        setCounter(0);

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        return;
    }
  };

  useEffect(() => {
    const getManualResultData = [
      { id: "even", name: "Even" },
      { id: "odd", name: "Odd" },
      { id: "abandoned", name: "Abandoned" },
    ];
    setManualResultList(getManualResultData);
  }, []);

  useEffect(() => {
    if (eventId) {
      getPendingFancyData(eventId, currentPage);
    }
    return () => clearInterval(intervalRef.current);
  }, [eventId, currentPage]);

  useEffect(() => {
    const getData = async() => {
      const response = await getCaller(`api/v1/results/operatorList`)
        const fetchedOperatorList = response?.data?.operatorList
        setOperatorList(fetchedOperatorList)
    }
    getData()
  },[])
  
  const getPendingFancyData = async (currentEventId, page = 1) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    } 
    const initialOperator = {};
    const result = async (currentEventId) => {
      if(currentEventId){
        const res = await getCaller(`api/v1/fancies/undeclared?event=${currentEventId}&sport=${sport}&page=${page}&limit=${limit}`)  
        const newData = res.data.map(el => ({
          ...el,
          result: el.result || "",  
        }));
        setPendingFancyData(prevData => {
          return newData.map((newItem, index) => {
              if (prevData[index]) {
                  return { ...newItem, result: newItem.result };
              }
              return newItem; 
          });
        });
        setTotalPage(res?.pagination?.totalPages)
       
        newData.forEach(e => {
          initialOperator[e.id] = operatorList.map(el => ({ value: el, label: el }));
        });  
      }
    }
    setOperator(initialOperator);
    result(currentEventId);
    intervalRef.current = setInterval(async () => {
      result(currentEventId);
    }, 30000);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getPendingFancyData(eventId, page);
  };

  const handleChange = (event, i) => {
    const newValue = [...pendingFancyData];
    const val =  newValue[i].is_custom === 1 ? event : event?.target?.value ;
    if (val === 'a') {
      newValue[i].result = "abandoned";
    } else {
      newValue[i].result = val;
    }
    setPendingFancyData(newValue);
  };

  const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

  const handleOperator = (selectedOptions,id) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (selectedOptions.length === options.length) {
        setOperator(prevState => ({ ...prevState, [id]: [] }));
      } else {
        setOperator(prevState => ({ ...prevState, [id]: operatorList.map(el => ({ value: el, label: el })) }));
      }
    } else {
  
      setOperator(prevState => ({ ...prevState, [id]: selectedOptions }));
    }
  };

  const getOptions = (selectedOptions) => {
    if (selectedOptions?.length === operatorList.length) {
      return operatorList.map((el) => ({ value: el, label: el }));
    }
    return options;
  }; 

  const handleSubmitResult = async () => {
    if (index === -1) return;
    const item = pendingFancyData[index];

    if (item.market_type !== "odd_even_markets" && ( item?.is_custom===0 && (!item.result || (item.result !== 'abandoned' && !isNumeric(item.result))))) {
      let newValue = [...pendingFancyData];
      newValue[index].error = "Only numeric values or 'abandoned' are allowed.";
      setPendingFancyData(newValue);
      return;
    } else {
      let newValue = [...pendingFancyData];
      newValue[index].error = "";
      setPendingFancyData(newValue);
    }
    
    await showConfirmationDialog(`You want to Submit this Result (${item.result})?`)
    .then(async (result) => {
      if (result.isConfirmed) {
        const values = operator[item.id].map(item => item.value);
        const data = {
          sport_id: parseInt(item.sport_id),
          match_id: parseInt(item.match_id),
          fancy_id: parseInt(item.id),
          result: item.result,
          market_name: item.market_name,
          selection: "",
          operators_list:values
        };
        const response = await postCaller(`api/v1/results`, data);
        if (response.success) {
          await showSuccessMessage('Your Result is Submitted Successfully')
          .then(() => {
            getPendingFancyData(eventId, currentPage); 
          });
        } else {
          showToast (response.message , true)
        }
      }
    });
  };

  const isNumeric = (value) => {
    return /^\d+$/.test(value);
  };

  const filteredManagers = pendingFancyData.filter((market) => {
    const marketName = market.market_name ? market.market_name.toLowerCase() : "";
    const eventName = market.event_name ? market.event_name.toLowerCase() : "";
    const fancyName = market.market_type ? market.market_type.toLowerCase() : "";

    return marketName.includes(searchField.toLowerCase()) || eventName.includes(searchField.toLowerCase()) || fancyName.includes(searchField.toLowerCase());
  });
 
  return (
    <> 
      <Result />
      <ResultTab />
      <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}>

        <div className="result-sport-content">
          <div className="result-event-select">
            <label htmlFor="date"> Select Sport </label> <br />
            <Select
              onChange={handleSport}
              options={sportList.map((el) => ({ value: el.id, label: el.name }))}
              styles={selectStyles}
              placeholder="Select Sport"
            />
          </div>
          <div className="result-event-select">
            <label htmlFor="date"> Select Events </label> <br />
            <Select
              value={eventId ? {   
                 value: eventId,
                  label: `${eventList.find(el => el.event_id === eventId)?.event_name} (${eventList.find(el => el.event_id === eventId)?.event_date.split('.')[0]})`  } : null}
              onChange={handleEvent}
              options={eventList.map((el) => ({ value: el.event_id, label: `${el.event_name} (${el.event_date.split('.')[0]})` }))}
              styles={selectStyles}
              placeholder="Select Event"
            />
          </div>
          <div className="">
              <div className='refresh-fancies' >Refresh {counter} </div>
          </div>
          <div className="result-event-select"></div>
          <div className="result-event-select"></div>
          <div className="result-event-select"></div>
        </div>

        <div className="table-border">
          <div className="wrapper-body">
            <div className="wrapper-content-1">
              <div className='searchcard'>
                <input 
                  type="text" 
                  autoComplete="off" 
                  placeholder='Search' 
                  className='result-input'
                  onChange={(e) => setSearchField(e.target.value)}
                  value={searchField}
                />
                {searchField ? <div className="ai-close">
                  <AiOutlineClose onClick={(event) => setSearchField('')}/>
                  </div> : <div className='ai-close'> <IoSearchOutline /></div>
                }
              </div>
            </div>
          </div>
          <div >
            <table width="100%">
              <thead>
                <tr className='custom-row'>
                  <th>#</th>
                  <th>Sport</th>
                  <th>Match</th>
                  <th>Fancy Name</th>
                  <th>Fancy Type</th>
                  <th width="250">Result</th>
                  <th width="250">Operator</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredManagers.length > 0 ?
                    filteredManagers.map((e, i) => (
                      <tr className='custom-row' key={e.id}>
                        <td>{e?.id}</td>
                        <td style={{ textTransform: "capitalize" }}>{e.sport_name}</td>
                        <td style={{ textTransform: "capitalize" }}>{e.event_name}</td>
                        <td style={{ textTransform: "capitalize" }}>{e.market_name}</td>
                        <td style={{ textTransform: "capitalize" }}>{e?.market_type ?? "No Data"}</td>
                        <td>
                          {e.market_type === 'odd_even_markets' ?
                            <div className="select-pending-container">
                              <select value={e.result} className='result-input' onChange={(event) => handleChange(event, i)}>
                                <option value="">Select Result</option>
                                {manualResultList.map((el, i) => (
                                  <option key={i} value={el.id}>{el.name}</option>
                                ))}
                              </select>
                            </div>
                            : e?.is_custom === 1 ? <div style={{width: '200px'}}>
                              <Select
                                id='result-dropdown'
                                onChange={(selectedOption) => handleChange(selectedOption.value ,i)}
                                options={(() => {
                                  const odds = JSON.parse(e.odds);
                                  return odds.map((e) => ({ value: e.selection_name, label: e.selection_name }));
                                })()}
                              /> 
                            </div> :
                            <div className="select-pending-container">
                              <input type="text" className='result-input' autoComplete="off" value={pendingFancyData[i]?.result || ""} onChange={(event) => handleChange(event, i)} />
                            </div>
                          }
                          <span className="error" style={{ color: "red" }}>
                            {e.error}
                          </span>
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          <div className="select-pending-container">
                          <Select
                              tabIndex={-1}
                              onChange={selectedOptions => handleOperator(selectedOptions, e.id)}
                              options={getOptions(operator[e.id])}
                              value={operator[e.id] || ''} 
                              placeholder="Select Operators"
                              isMulti
                          />

                          </div>
                        </td>
                        <td>
                          <div className='pending-result-btn'>
                            <div>
                              <Button 
                                className='submit' 
                                disabled={!operator[e.id]?.length || (scope?.userType === 'manager' && !scope?.result?.add) || !e.result} 
                                name='Set Result'
                                type='button' 
                                onClick={() => { setIndex(i); setOpen(true); }} 
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )) :
                    <tr><td className='no-data-coloumn' align='center' colSpan={7}>No Data</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      
        <Pagination
          currentPage={currentPage}
          totalPages={totalPage}
          onPageChange={handlePageChange}
        />
      </motion.div>
      <SurveillanceModel
        isOpen={isOpen}
        setOpen={setOpen}
        onCloseModel={handleSubmitResult}
        matchID={pendingFancyData[index]?.event_id}
        marketID={pendingFancyData[index]?.external_id}
        marketType={3}
      />
    </>
  );
}

export default PendingFancy;
