import React, { useState } from 'react'
import Fancy1Market from './Fancy1Market'
import FancyMarketOdds from './FancyMarketOdds'
import '../fancyMarket/fancyTab.css'

const FancyMarketTab = ({fancy}) => {
  
  const [fancyIndex,setFancyIndex] = useState(0)
  // const [fancyType,setFancyType] = useState([])
  // const getAllFancyType = useCallback(async () => {
  //   try {
  //     const res = await getCaller('api/v1/markets/showFancyMarket');
  //     setFancyType(res)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [])
  // useEffect(() => {
  //   getAllFancyType();
  // }, [getAllFancyType]);
  // console.log(fancyType)
  return (
    <>
    <div className="fancy-market-container">
      <div className="fancy-market-head">
        <div className={`fancy-tab ${fancyIndex===0?'fancy-active':''}`} onClick={()=> setFancyIndex(0) }> <p>All</p> </div>
        <div className={`fancy-tab ${fancyIndex===1?'fancy-active':''}`} onClick={()=> setFancyIndex(1) }> <p>Fancy Market</p> </div>
        <div className={`fancy-tab ${fancyIndex===2?'fancy-active':''}`} onClick={()=> setFancyIndex(2) }> <p>Fancy 1</p> </div>
        <div className={`fancy-tab ${fancyIndex===3?'fancy-active':''}`} onClick={()=> setFancyIndex(3) }> <p>Ball By Ball</p> </div>

      </div>
    </div>
   <div className="all-fancy-market-border">
   <div hidden={fancyIndex!==0}>
       <FancyMarketOdds fancy={fancy}/>
       <Fancy1Market />
      </div>
      <div hidden={fancyIndex!==1}>
       <FancyMarketOdds fancy={fancy}/>
      </div>
      <div hidden={fancyIndex!==2}>
      <Fancy1Market/>
      </div>
   </div>
  
    </>
  )
}

export default FancyMarketTab