import React from 'react'
import '../button/button.css'
const Button = (props) => {
  return (
    <button
    className={`basic-button ${props.className || 'blue'}`}
    onClick={props.onClick}
    type={props.type}
    disabled={props.disabled}
    >
        {props.name||'button'}
    </button>
  )
}

export default Button