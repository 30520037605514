import React from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { showToast, showSuccessMessage } from "../../utilities/toastHelper";
import '../user/user.css';
import { AiOutlineClose } from 'react-icons/ai';
import { postCaller } from '../../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../component/button/Button';

const UserChangePassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const updatePassData = state?.passData ?? {};

  const Schema = Yup.object().shape({
    password: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .when("password", {
        is: val => (val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref("password")], "Both passwords need to be the same"),
      }),
    managerPassword: Yup.string().required("Your Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      managerPassword: ''
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      delete values.confirmPassword;

      const ChangePasswordResponse = await postCaller(`api/v1/users/changePasswordAgent?id=${updatePassData.id}`, values);

      if (ChangePasswordResponse.success) {
        showSuccessMessage(ChangePasswordResponse.message);
        setTimeout(() => {
          navigate('/user');
        }, 2000);
      } else {
        showToast(ChangePasswordResponse.message, true);
      }
    },
  });

  const checkButtonState = () => {
    let flag = true;
    for (const key in formik?.values) {
      if (formik?.values[key] !== "") {
        flag = false;
      } else {
        flag = true;
        break;
      }
    }
    if (!flag && formik?.values?.confirmPassword !== formik?.values?.password) return flag;
  };

  return (
    <>
      <div className="sport-container">
        <div className="modal-container-one">
          <div className='add-agent-head'>
            <div>
              Change Password <span style={{ color: "#018990" }}>{updatePassData.name}</span>
            </div>
            <div className="ai-close">
              <AiOutlineClose onClick={() => navigate('/user')} />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='agent-add-form'>
              <div className="agent-input-body">
                <label htmlFor="password"> NEW PASSWORD <span style={{ color: 'red' }}>*</span> </label>
                <input
                  type="password"
                  autoComplete="off"
                  name='password'
                  onBlur={formik.handleBlur}
                  value={formik.values.password || ""}
                  onChange={formik.handleChange}
                  className='agent_input'
                />
                {formik.errors.password && formik.touched.password && (
                  <span className="error" style={{ color: "red" }}>
                    {formik.errors.password}
                  </span>
                )}
              </div>
              <div className="agent-input-body">
                <label htmlFor="confirmPassword"> CONFIRM PASSWORD <span style={{ color: 'red' }}>*</span> </label>
                <input
                  type="password"
                  autoComplete="off"
                  name='confirmPassword'
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword || ""}
                  onChange={formik.handleChange}
                  className='agent_input'
                />
                {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                  <span className="error" style={{ color: "red" }}>
                    {formik.errors.confirmPassword}
                  </span>
                )}
              </div>
              <div className="agent-input-body">
                <label htmlFor="managerPassword"> YOUR PASSWORD <span style={{ color: 'red' }}>*</span> </label>
                <input
                  type="password"
                  autoComplete="off"
                  name='managerPassword'
                  onBlur={formik.handleBlur}
                  value={formik.values.managerPassword || ""}
                  onChange={formik.handleChange}
                  className="agent_input"
                  placeholder='Password'
                />
                {formik.errors.managerPassword && formik.touched.managerPassword && (
                  <span className="error" style={{ color: "red" }}>
                    {formik.errors.managerPassword}
                  </span>
                )}
              </div>
              <div className="change-password-container">
                <Button disabled={checkButtonState()} type='submit' name="Submit" className="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UserChangePassword;
