import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../../error/ErrorBoundary';
import '../leagueFromSport/leagueManage.css';
import LeagueAccordian from './LeagueAccordian';

const LeagueDetails = ({ leagueData, setLeagueData }) => {
  return (
    <div>
      {
        leagueData?.length > 0 
          ? leagueData.map((league, i) => (
              <LeagueAccordian
                leagueData={leagueData}
                setLeagueData={setLeagueData}
                league={league}
                index={i}
                key={league.id}
              />
            ))
          : <ErrorBoundary/>
      }
    </div>
  );
};

LeagueDetails.propTypes = {
  leagueData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setLeagueData: PropTypes.func.isRequired,
};

export default LeagueDetails;
