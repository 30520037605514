import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Button from '../../component/button/Button';
import { getCaller, updateCaller } from '../../services/api';
import Paginations from '../../component/pagination/Paginations';
import { MdDelete } from 'react-icons/md';
import BigModal from '../../component/modal/BigModal';
import Select from 'react-select'
import timeConversion from '../../utilities/timeConversion';
import Bets from '../bets/betsTab';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { selectStyles } from '../../services/utils'
import { showSuccessMessage, showToast } from '../../utilities/toastHelper';
const WorliMatkaBets = () => {

    const  getISTDate= ()=> {
        const currentDate = new Date();
        const istOffset = 5 * 60 + 30; 
        const localOffset = currentDate.getTimezoneOffset();
        const istDate = new Date(currentDate.getTime() + (istOffset - localOffset) * 60 * 1000);
        const istDateString = istDate.toISOString().split('T')[0];

        return istDateString;
    }

    const [sportList, setSportList] = useState([]);
    const [sport, setSport] = useState("");
    const [betType, setBetType] = useState("");
    const [marketList, setMarketList] = useState([]);
    const [marketID, setMarketID] = useState("");
    const [selected, setSelected] = useState([]); 
    const [marketTypeList, setMarketTypeList] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [betList, setBetList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [total, setTotal] = useState(1);
    const [deleteType, setDeleteType] = useState("");
    const [openDeleteBetModal, setOpenDeleteBetModal] = useState(false);
    const [openReasonModal, setReasonModal] = useState(false);
    const [marketType, setMarketType] = useState("");
    const [eventDate, setEventDate] = useState(getISTDate());
    const [user, setUser] = useState(null);
    const [ipAddress, setIpAddress] = useState("");
    const [scope,setScope] = useState({});

    useEffect(() => {
        const { bets } = getLocalData('scope');
        const userType = getLocalData('user_role');
        setScope({ bets, userType })
    }, []);

    const fetchBets = async ({ otherPage }) => {
        try {
            if(!(betType && sport && marketType)) return
            const result = await getCaller(`api/v1/worli-void-bets/${betType}/${sport}/${marketType}/${marketID}?page=${otherPage || page}&limit=${perPage}&user=${user}&ip=${ipAddress}`);
            setBetList(result.data);

            const { pages: total, page: pageNo, per_page } = result.pagination;
            setPage(pageNo);
            setPerPage(per_page);
            setTotal(total);
        } catch (error) {
            console.error(error);
        }
    }

    const setPageLocal = async (page) => {
        try {
            setPage(page);
            await fetchBets({ otherPage: page });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getMatches(eventDate)
        if (allSelected) {
            const betIds = betList.map(m => parseInt(m.id));
            setSelected(betIds);
        } else {
            setSelected([]);
        }
    }, [allSelected]);

    const markCheck = (event) => {
        let updatedList = [...selected];
        if (event.target.checked) {
            updatedList = [...updatedList, parseInt(event.target.value)];
        } else {
            updatedList.splice(updatedList.indexOf(parseInt(event.target.value)), 1);
        }
        setSelected(updatedList);
    }

    const deleteBets = async () => {
        try {
            setOpenDeleteBetModal(true);
            setDeleteType("");
        } catch (error) {
            console.error(error);
        }
    }

    const setDelete = (type) => {
        setDeleteType(type);
        setOpenDeleteBetModal(false);
        setReasonModal(true);
    }

    const voidBets = async (reason) => {
        const body = {
            reason,
            ids: selected,
            type: deleteType,
            marketType,
            marketID
        };

        const res = await updateCaller(`api/v1/worli-void-bets/CLICKBET`, body);
       
        if (res.success) {
            showSuccessMessage('Your Match Added Successfully')
            setReasonModal(false);
            setSelected([]);
            await fetchBets({ otherPage: 1 });
        } else {
            showToast(res.message, true)

        }


    }

    const handleDateChange = async (event) => {
        setEventDate(event.target.value);
        await getMatches(event.target.value)
    }

    const getMatches = async (date) => {
        const matchData = await getCaller(`api/v1/worli/matches/${date}/all`)
        setSportList(matchData.data)
    }

    const getMarket = async (matchId) => {
        setSport(matchId)
        let dataType = sportList.filter(x => x.id === matchId)
        let data1 = JSON.parse(dataType[0].match_type)
        const keys = Object.keys(data1);
        setMarketTypeList(keys)
        const marketData = await getCaller(`api/v1/worli/matches/${matchId}/markets?eventDate=${eventDate}`)
        setMarketList(marketData.data)
    }

    const setMarket = async (marketID) => {
        setMarketID(marketID)
    }
    
    return (
        <>
            <Bets />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}>

                <div className="result-sport-container">
                    <div className="result-sport-content">
                        <div className="result-event-select" id='bookmaker-select'>
                            <label htmlFor="date"> Date </label> <br />
                            <input type='date' autoComplete="off"
                                value={eventDate}
                                name='event_date' onChange={(e) => handleDateChange(e, eventDate)}
                                className='select-field' placeholder='DD/MM/YYYY' />
                        </div>
                        <div className="result-event-select" id='bookmaker-select' >
                            <label htmlFor="date"> Bet Type </label> <br />
                            <Select
                                onChange={(selectedOption) => setBetType(selectedOption.value)}
                                options={[
                                    { value: '1', label: 'Open' },
                                    { value: '2,3', label: 'Settled' },
                                    { value: '4', label: 'Void' },
                                    // { value: '5', label: 'Delete' },
                                ]}
                                styles={ selectStyles }
                                placeholder="Select bet type"
                            />
                        </div>

                        <div className="result-event-select" id='bookmaker-select'>
                            <label htmlFor="date"> Select Match </label> <br />
                            <Select
                                onChange={(selectedOption) => getMarket(selectedOption.value)}
                                options={sportList?.map((el) => ({ value: el.id, label: el.match_title }))}
                                styles={ selectStyles }
                                placeholder="Select match"
                            />
                        </div>
                    </div>
                    <div className="result-sport-content" style={{ marginTop: "10px" }}>
                        <div className="result-event-select" id='bookmaker-select'>
                            <label htmlFor="date"> Select Market Type</label> <br />
                            <Select
                                onChange={(selectedOption) => setMarketType(selectedOption.value)}
                                options={marketList?.map((el) => ({ value: el.id, label: el.match_type.toUpperCase() }))}
                                styles={ selectStyles }
                                placeholder="Select Market"
                            />
                        </div>
                         
                        <div className="result-event-select" id='bookmaker-select'>
                            <label htmlFor="date">USER ID </label> <br />
                            <input type="text" autoComplete="off" className='select-field' value={user||""} onChange={(e) => setUser(e.target.value)} name="user" />

                        </div>
                        <div className="result-event-select" id='bookmaker-select'>
                            <label htmlFor="date"> IP ADDRESS </label> <br />
                            <input type="text" autoComplete="off" className='select-field' value={ipAddress||""} onChange={(e) => setIpAddress(e.target.value)} name="ip_address" />

                        </div>
                    </div>
                    <div className="book-maker-btn">
                        <Button className='submit' name='Search' type='button' disabled={!(sport && betType && marketType)} onClick={fetchBets} />
                    </div>
                </div>

                { betList?.length > 0 && <div className="table-border">
                    <div style={{ overflowX: "auto" }} >
                        <table width="100%" >
                            <thead>
                                <tr className='custom-row' >
                                    <th>
                                        <input type="checkbox" checked={allSelected} onChange={() => setAllSelected(v => !v)} className='check-container' />
                                        <button
                                            style={{background:'transparent', border:'none',outline:'none', cursor: (!selected.length || (scope?.userType?.toLowerCase() === 'manager' && !scope?.bets?.delete)) ? 'not-allowed':'pointer'}}
                                            disabled={!selected.length || (scope?.userType?.toLowerCase() === 'manager' && !(scope?.bets?.delete))}
                                        >
                                            <MdDelete
                                                style={{ fontSize: "1.5rem", color: (!selected.length || (scope?.userType?.toLowerCase() === 'manager' && !scope?.bets?.delete)) ? "#ccc" : "red" }}
                                                onClick={deleteBets}
                                            />
                                        </button>
                                    </th>
                                    <th> # </th>
                                    <th>Parent</th>
                                    <th>User</th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Stake</th>
                                    <th>Profit</th>
                                    <th>Loss</th>
                                    <th>Digit</th>
                                    
                                    <th>Commission</th>
                                    {/* <th>Total P&L</th> */}
                                    <th>IP</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    betList?.length > 0 ?
                                        betList.map((e, i) => (
                                            <React.Fragment key={i}>
                                                <tr key={e.id} className={e.selection_type + ' custom-row'}>
                                                    <td><input type="checkbox" checked={selected.includes(parseInt(e.id))} value={e.id} onChange={markCheck} className='check-container' /></td>
                                                    <td>{e.id}</td>
                                                    <td>{e.parent}</td>
                                                    <td>{e.child}</td>
                                                    <td>{timeConversion(e.created_at)}</td>
                                                    <td>{e.selection}</td>
                                                    <td>{e.stake}</td>
                                                    <td>{e.profit}</td>
                                                    <td>{e.loss}</td>
                                                    <td>{e.odds}</td>
                                                    <td>{e.commission}</td>
                                                    {/* <td>{e.p_l}</td> */}
                                                    <td>{e.ip}</td>
                                                </tr>
                                            </React.Fragment>

                                        )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {betList?.length > 0 && <Paginations page={page} perPage={perPage} setPage={setPageLocal} setPerPage={setPerPage} maximo={total} />}
                    {/* <Paginations page={page} setPage={setPageLocal} setPerPage={setPerPage} maximo={total} /> */}
                </div>
                }

            </motion.div>
            <BigModal isOpen={openDeleteBetModal} onClose={() => setOpenDeleteBetModal(false)}>
                <>
                    <h3>DELETE BET</h3>
                    <Button className="green" style={{ margin: "3px" }} name="VOID" onClick={() => setDelete('void')}></Button>
                    {/* <Button className={"yellow"} name="INVALID" onClick={() => setDelete('invalid')}></Button> */}
                    {/* <Button className={"red"} name="DELETE" onClick={() => setDelete('delete')}></Button> */}
                </>
            </BigModal>
            <BigModal isOpen={openReasonModal} onClose={() => setReasonModal(false)} style={{ width: "400px" }}>
                <>
                    <h3>REASON?</h3>
                    <Button className="green" style={{ margin: "3px" }} name="Change Timing" onClick={() => voidBets(1)}></Button>
                    <Button className="red" style={{ margin: "3px" }} name="Technical issue" onClick={() => voidBets(2)}></Button>
                    {/* <Button className={"red"} style={{ margin: "3px" }} name="Wrong odd" onClick={() => voidBets(3)}></Button>
                    <Button className={"green"} style={{ margin: "3px" }} name="Technical issue" onClick={() => voidBets(4)}></Button>
                    <Button className={"yellow"} style={{ margin: "3px" }} name="Agent mistake" onClick={() => voidBets(5)}></Button>
                    <Button className={"red"} style={{ margin: "3px" }} name="Cheating" onClick={() => voidBets(6)}></Button>
                    <Button className={"green"} style={{ margin: "3px" }} name="Late Suspended" onClick={() => voidBets(7)}></Button>
                    <Button className={"yellow"} style={{ margin: "3px" }} name="Wrong result" onClick={() => voidBets(8)}></Button>
                    <Button className={"red"} style={{ margin: "3px" }} name="2nd inning bet" onClick={() => voidBets(9)}></Button>
                    <Button className={"green"} style={{ margin: "3px" }} name="Player Not Opened" onClick={() => voidBets(10)}></Button>
                    <Button className={"yellow"} style={{ margin: "3px" }} name="Wicket Not Fall" onClick={() => voidBets(11)}></Button>
                    <Button className={"red"} style={{ margin: "3px" }} name="Over Not Completed" onClick={() => voidBets(12)}></Button> */}
                </>
            </BigModal>
        </>
    );
}

export default WorliMatkaBets