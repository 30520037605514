import React, { useEffect, useRef, useState } from 'react'
import { disablePast3Date } from '../../services/utils';
import ManageSkyFancyTable from './ManageSkyFancyTable'
import '../manageOutsideMarket/manageskymarket.css'
import { getCaller } from '../../services/api'
import { motion } from "framer-motion";

const ManageSkyFancy = () => {
    const [eventDate, setEventDate] = useState((new Date()).toISOString().split('T')[0]);
    const [counterInterval, setCounterInterval] = useState(null);
    const [getFancyList, setGetFancyList] = useState({});
    const [fancyOpen, setFancyOpen] = useState(false);
    const [interval, setIntervalData] = useState();
    const [eventlist, setEventList] = useState([]);
    const [sportList, setSportList] = useState([]);
    const [counter, setCounter] = useState(0);
    const [sport, setSport] = useState("");
    const [match, setMatch] = useState("");

    const mounted = useRef(false);
    let timerInerval;

    useEffect(() => {
        mounted.current = true;

        async function getSport() {
            let sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse.data)
            setSport("");
        }
        getSport()

        return () => {
            mounted.current = false;
        };
    }, []);

    const handleSport = async (event) => {
        setSport(event.target.value);
        setMatch("");
        setEventList([]);
        handleEventChange(null);
    }

    const handleDateChange = async (event) => {
        setEventDate(event.target.value);
        setSport("");
        setMatch("");
        setEventList([]);
        handleEventChange(null);
    }

    useEffect(() => {
        const getEventsList = async (sport, date) => {
            if (!sport || !date) { setEventList([]); return }
            const eventResponse = await getCaller(`api/v1/events/searchEvent?event_date=${date}&sport=${sport}`)
            setEventList(eventResponse.data)
        }
        getEventsList(sport, eventDate)
    }, [sport, eventDate])

    const getCount = () => {
        let timeleft = 10;
        setCounter(timeleft);
        let downloadTimer = setInterval(function () {
            timeleft -= 1;
            setCounter(timeleft);
        }, 1000);
        setCounterInterval((oldDownloadTimer) => {
            if (oldDownloadTimer) { clearInterval(oldDownloadTimer) }
            return downloadTimer;
        });
    }

    const handleEventChange = async (event) => {
        event = event ? event.target.value: '';
        setMatch(event);

        if (event =="") {
            setCounterInterval((oldDownloadTimer) => {
                if (oldDownloadTimer) { clearInterval(oldDownloadTimer) }
                return null;
            });
            setCounter(0);
            if (interval) {
                clearInterval(interval);
            }
            return;
        }
        await getFancyOdds(event);
    }

    const getFancyOdds = async (event_id) => {
        getCount()
        clearInterval(interval);
        const res = await getCaller(`api/v1/fancies/external/${event_id}`)
        setGetFancyList(res)
        setFancyOpen(true)
        const intervalData = setInterval(async () => {
            getCount();
            if (mounted.current === false) {
                clearInterval(timerInerval);
            }
            const res = await getCaller(`api/v1/fancies/external/${event_id}`)
            setGetFancyList(res)
            setFancyOpen(true)
        }, 10000)
        timerInerval = intervalData;
        setIntervalData(intervalData);
    }

    return (
        <motion.div className="wrapper" initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
            <div className="wrapper-head">
                <h3>MANAGE MARKET OF <span style={{ color: "#018990" }}>Sky Exchange Fancy</span>  </h3>
            </div>
            <div className="result-sport-container">
                <div className="sky-fancy-content">
                    <div className="sky-fancy-container" style={{marginLeft: '8px'}}>
                        <label htmlFor="date"> Date </label> <br />
                        <input type='date' autoComplete="off"
                            value={eventDate}
                            min={disablePast3Date()}
                            name='event_date' onChange={(e) => handleDateChange(e)}
                            className='select-field' placeholder='DD/MM/YYYY' />
                    </div>

                    <div className="sky-fancy-container" style={{marginLeft: '15px'}}>
                        <label htmlFor="bookmaker"> Sport </label> <br />
                        <select className='select-field manager_downline'
                            value={sport} onChange={handleSport}>
                            <option value="" disabled> Select Sport</option>
                            {sportList?.length > 0 ? sportList?.map((el) => <option
                                key={el.id} value={el.id}>{el.name}</option>) : null}
                        </select>
                    </div>

                    <div className="sky-fancy-container" id='fancy-one-container'>
                        <label htmlFor="date"> Select Event </label> <br />
                        <select className='select-field manager_downline' name='event_name' value={match} onChange={handleEventChange}>
                            <option value="" disabled> Select Event</option>
                            {eventlist?.length > 0 ? eventlist?.map((el) => <option key={el.event_id} value={el.event_id}>{el.event_name}</option>) : null}
                        </select>
                    </div>

                    <div className="">
                        <div className='refresh-fancy' >Refresh {counter} </div>
                    </div>
                </div>
            </div>
            {
                fancyOpen && <ManageSkyFancyTable getFancyList={getFancyList} setGetFancyList={setGetFancyList} />
            }

        </motion.div>
    )
}

export default ManageSkyFancy