import React from 'react'
import UserSportTab from './inplayAllMatches/UserSportTab'
import UserLayout from './UserLayout'


const Home = () => {
  return (
    <UserLayout>
     <UserSportTab/>
    </UserLayout>
  )
}

export default Home