const getLocalData = (key) => {
    let data = localStorage.getItem(key);

    if(!data) return null

    if((data.trim().startsWith('{') && data.trim().endsWith('}')) || (data.trim().startsWith('[') && data.trim().endsWith(']')))
    {
        return JSON.parse(data)
    }
    
    return data?.toLowerCase() ;
};

export default getLocalData;
