import React from 'react';

const TopBetsBox = ({ data }) => {
    return (

        <div className="main-box">
            <h2>TOP 20 BETS</h2>
            <hr style={{ height: "5px" }} />
            <br />
            <table className="list-type">
                <thead>
                    <tr>
                        <th>CHILD</th>
                        <th>STAKE</th>
                        <th>SELECTION TYPE</th>
                        <th>ODDS</th>
                        <th>SELECTION</th>
                        <th>CREATED AT</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((e, index) => (
                        <tr key={index}>
                            <td>{e.child ?? "N/A"}</td>
                            <td>{e.stake ?? "N/A"}</td>
                            <td>{e.selection_type ?? "N/A"}</td>
                            <td>{e.odds ?? "N/A"}</td>
                            <td>{e.selection ?? "N/A"}</td>
                            <td>{e.created_at ?? "N/A"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default TopBetsBox;
