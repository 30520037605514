import React, { useState, useEffect } from 'react';
import '../toggleButton/toggleButton.css';

const ToogleButton = ({ defaultChecked, disabled, onChange }) => {
    const [toggle, setToggle] = useState(defaultChecked || false);

    useEffect(() => {
        setToggle(defaultChecked);
    }, [defaultChecked]);

    const triggerToggle = () => {
        if (disabled) return

        setToggle(!toggle);
       
        if (typeof onChange === 'function') {
            onChange(!toggle);
        }
    };

    return (
        <div 
            role="switch"
            aria-checked={toggle}
            tabIndex={0}
            className={`checkbox ${(disabled && 'disableButtons')} ${(!toggle && 'checkbox--off')}`}
            onClick={triggerToggle}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ' || e.keyCode === 32) {
                    e.preventDefault();
                    triggerToggle();
                }
            }}
            aria-disabled={disabled}
        >
            <div className="checkbox__ball"></div>
            <span className="checkbox__text"></span>
        </div>
    );
};
export default ToogleButton;
