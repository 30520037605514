import React, { useEffect, useState, useCallback } from 'react';
import { RiFootballFill } from 'react-icons/ri';
import { getCaller } from '../../services/api';
import ErrorBoundary from '../../error/ErrorBoundary';
import LeagueDetails from './leagueFromSport/LeagueDetails';
import '../eventManage/eventmanage.css';

const SportManagement = () => {
  const [eventTab, setEventTab] = useState('');
  const [sportsData, setSportsData] = useState([]);
  const [leagueData, setLeagueData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSportsData = async () => {
      setLoading(true);
      try {
        const { data } = await getCaller('api/v1/sports/management/getSport');
        
        if (data) {
          const cricketSport = data.find(sport => sport.slug === 'cricket');
          const orderedSports = cricketSport
            ? [cricketSport, ...data.filter(sport => sport.slug !== 'cricket')]
            : data;
          
          setSportsData(orderedSports);
          
          const initialSport = cricketSport || data[0];
          if (initialSport) {
            await fetchLeagueData(initialSport.id);
            setEventTab(initialSport.id);
          }
        }
      } catch (error) {
        console.error('Error fetching sports data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSportsData();
  }, []);

  const fetchLeagueData = useCallback(async (sportId) => {
    setLoading(true);
    try {
      const { data } = await getCaller(`api/v1/leagues/leaguesFindById?id=${sportId}`);
      setLeagueData(data);
    } catch (error) {
      console.error('Error fetching league data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleTabClick = (sport) => {
    setEventTab(sport.id);
    fetchLeagueData(sport.id);
  };

  return (
    <div className="wrapper">
      <div className="wrapper-head">
        <h3>EVENTS MANAGEMENT</h3>
      </div>
      <div className="event-container">
        {sportsData.length ? (
          sportsData.map(sport => (
            <div
              key={sport.id}
              className={`event-tab ${eventTab === sport.id ? 'active-event-tab' : ''}`}
              onClick={() => handleTabClick(sport)}
            >
              <div className="event-body">
                <RiFootballFill style={{ fontSize: "1.5rem" }} />
                <p>{sport.name}</p>
              </div>
            </div>
          ))
        ) : (
          <ErrorBoundary />
        )}
      </div>
      <div className="event-content">
        {loading ? (
          <div className="loader-wrapper">
            <div className="loader" />
          </div>
        ) : (
          <LeagueDetails leagueData={leagueData} setLeagueData={setLeagueData} />
        )}
      </div>
    </div>
  );
};

export default SportManagement;