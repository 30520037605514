import React, { useEffect, useState, useRef } from "react";
import "../downline.css";
import { updateCaller, getCaller, postCaller } from "../../../services/api";
import ToogleButton from "../../../component/toggleButton/ToogleButton";
import timeConversion from '../../../utilities/timeConversion';
import Select from 'react-select';
import Button from '../../../component/button/Button';
import SocketComponent from "../../../socket/socketConnection";
import "../admin-style.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { showToast, showSuccessMessage, showConfirmationDialog} from '../../../utilities/toastHelper';

const AgentGetAutoBookmaker = ({
    marketAssignData,
    setMarketAssignData,
    setUpdateTime,
    isSus,
}) => {
    let [doUpdate, setDoUpdate] = useState(false);
    const [isOpen, setOpen] = useState(false);
    let [voidBets, setVoidBets] = useState([]);
    const [operatorList, setOperatorList] = useState([]);
    const [operator, setOperator] = useState('');
    let [runnerType, setRunnerType] = useState("");
    let [result, setResult] = useState("");
    const [localMarketData, setLocalMarketData] = useState(marketAssignData);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchOperator, setSearchOperator] = useState('');
    const [searchSelection, setSearchSelection] = useState('');
    const [minStake, setMinStake] = useState('');
    const [minOdds, setMinOdds] = useState('');
    const [maxStake, setMaxStake] = useState('');
    const [maxOdds, setMaxOdds] = useState('');
    const [selectionType, setSelectionType] = useState('');
    const intervalRef = useRef(null);
    const [endDate, setEndDate] = useState(null);
    const [isLive, setIsLive] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    const [totalBets, setTotalBet] = useState(false);
    const [dataFromChild, setDataFromChild] = useState(null);
    const [dataFromBookmaker, setDataFromBookmaker] = useState([]);
    const [inputBackValue, setInputBackValue] = useState([]);
    const [inputLayValue, setInputLayValue] = useState([]); 

    useEffect(() => {
        if (marketAssignData?.bookmaker?.runners) {
            const initialBackValues = marketAssignData.bookmaker.runners.map(runner => runner.b || '');
            setInputBackValue(initialBackValues);

            const initialLayValues = marketAssignData.bookmaker.runners.map(runner => runner.l || '');
            setInputLayValue(initialLayValues);

            const updatedLocalBookmaker = {
                ...localMarketData.bookmaker,
                runners: marketAssignData?.bookmaker?.runners,
            };

            setLocalMarketData({ ...localMarketData, bookmaker: updatedLocalBookmaker });
        }
    }, [marketAssignData]);

    useEffect(() => {
        document.body.classList.add('agentGetMarket');
        return () => {
            document.body.classList.remove('agentGetMarket');
        };
    }, []);

    const toggleVisibility = () => {
        setIsVisible(prevIsVisible => !prevIsVisible);
    };

    const handleDataFromChild = (childData) => {
        setDataFromChild(childData)
    };

    const handleDataFromBookmaker = (childData) => {
        let runner = JSON.parse(childData[0].runners)
        setDataFromBookmaker(runner)
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        const updatedLocalBookmaker = {
            ...localMarketData.bookmaker,
            end_date: date,
        };

        setLocalMarketData({ ...localMarketData, bookmaker: updatedLocalBookmaker });

        const updatedBookmaker = {
            ...marketAssignData.bookmaker,
            end_date: date,
        };

        setMarketAssignData({ ...marketAssignData, bookmaker: updatedBookmaker });
        updateBookmaker(updatedBookmaker);
    };


    const updateBookmaker = async (bookmakerData) => {
        setUpdateTime()
        if ((bookmakerData?.id && !bookmakerData?.runners) || (!bookmakerData?.id && doUpdate === false)) {
            return;
        }

        let data = {};
        setDoUpdate(false);

        if (bookmakerData?.id) {
            data = {
                id: bookmakerData.id,
                is_active: bookmakerData.is_active,
                is_sus: bookmakerData.is_sus,
                market_name: bookmakerData.market_name,
                market_time: bookmakerData.market_time,
                odd_gap: bookmakerData.odd_gap,
                runners: bookmakerData.runners,
                msg: bookmakerData.msg,
                end_date: bookmakerData.end_date,
                back_modifier: bookmakerData.back_modifier,
                lay_modifier: bookmakerData.lay_modifier
            }
        } else {
            data = {
                id: localMarketData.bookmaker.id,
                is_active: localMarketData.bookmaker.is_active,
                is_sus: localMarketData.bookmaker.is_sus,
                market_name: localMarketData.bookmaker.market_name,
                market_time: localMarketData.bookmaker.market_time,
                odd_gap: localMarketData.bookmaker.odd_gap,
                runners: localMarketData.bookmaker.runners,
                msg: localMarketData.bookmaker.msg,
                end_date: localMarketData.bookmaker.end_date,
                back_modifier: localMarketData.bookmaker.back_modifier,
                lay_modifier: localMarketData.bookmaker.lay_modifier
            };
        }

        const response = await updateCaller(`api/v1/bookmakers/${marketAssignData.bookmaker.id}/agent`, data);

        if (response.success) {
            showToast(response.message, false)
        }

    };

    useEffect(() => {
        const getData = async () => {
            const response = await getCaller(`api/v1/results/operatorList`)
            const fetchedOperatorList = response?.data?.operatorList
            setOperatorList(fetchedOperatorList)
        }
        getData()
    }, [])

    const handleBookmakerResult = async (event) => {
        setRunnerType(event.value)
        setOperator('')
        setResult(event)
        if (event?.value) {
            const initialOperator = operatorList?.map(el => ({ value: el, label: el }));
            setOperator(initialOperator);

        }
    }

    useEffect(() => {
        if (isSus) {
            const updatedBookmaker = {
                ...marketAssignData.bookmaker,
                is_sus: 1
            };
            setMarketAssignData({
                ...marketAssignData,
                bookmaker: updatedBookmaker
            });

            const updatedLocalBookmaker = {
                ...localMarketData.bookmaker,
                is_sus: 1
            };
            setLocalMarketData({ ...localMarketData, bookmaker: updatedLocalBookmaker });
        } else if (isSus === null) {
            const updatedBookmaker = {
                ...marketAssignData.bookmaker,
                is_sus: 0
            };
            setMarketAssignData({
                ...marketAssignData,
                bookmaker: updatedBookmaker
            });

            const updatedLocalBookmaker = {
                ...localMarketData.bookmaker,
                is_sus: 0
            };
            setLocalMarketData({ ...localMarketData, bookmaker: updatedLocalBookmaker });
        }
    }, [isSus]);

    const handleToggleChange = (event) => {
        const sus = event ? 1 : 0;

        const updatedLocalBookmaker = {
            ...localMarketData.bookmaker,
            is_sus: sus
        };

        setLocalMarketData({ ...localMarketData, bookmaker: updatedLocalBookmaker });

        const updatedBookmaker = {
            ...marketAssignData.bookmaker,
            is_sus: sus
        };

        setMarketAssignData({
            ...marketAssignData,
            bookmaker: updatedBookmaker
        });
        updateBookmaker(updatedBookmaker);
    };

    const handleToggleActive = (event) => {
        const active = event ? 1 : 0;

        const updatedLocalBookmaker = {
            ...localMarketData.bookmaker,
            is_active: active
        };

        setLocalMarketData({ ...localMarketData, bookmaker: updatedLocalBookmaker });

        const updatedBookmaker = {
            ...marketAssignData.bookmaker,
            is_active: active
        };
        setMarketAssignData({
            ...marketAssignData,
            bookmaker: updatedBookmaker
        });
        updateBookmaker(updatedBookmaker);
    };

    const handleToggleSS = (event, index) => {
        const newSSValue = event ? 'SUSPEND' : 'ACTIVE';
        const updatedRunners = marketAssignData.bookmaker.runners.map((runner, i) => {
            if (i === index) {
                return { ...runner, ss: newSSValue };
            }
            return runner;
        });

        const updatedLocalBookmaker = {
            ...localMarketData.bookmaker,
            runners: updatedRunners,
        };

        setLocalMarketData({ ...localMarketData, bookmaker: updatedLocalBookmaker });

        const updatedBookmaker = {
            ...marketAssignData.bookmaker,
            runners: updatedRunners,
        };

        setMarketAssignData({ ...marketAssignData, bookmaker: updatedBookmaker });
        updateBookmaker(updatedBookmaker);
    };


    useEffect(() => {
        const fetchVoidBets = async () => {
            try {
                let response = await getCaller(
                    `api/v1/void-bets/3,2,1/4/${localMarketData.match_id}/bookmaker/${localMarketData?.bookmaker?.external_id}?page=1&limit=20&child=${searchQuery}&operator=${searchOperator}&selectionType=${selectionType === 'all' ? '' : selectionType}&selection=${searchSelection}&stakeMin=${minStake}&stakeMax=${maxStake}&oddsMin=${minOdds}&oddsMax=${maxOdds}`, false
                );
                setTotalBet(response?.pagination?.total || 0);
                setVoidBets(response.data)
            } catch (error) {

            }
        };

        fetchVoidBets();
        if (!intervalRef.current) {
            intervalRef.current = setInterval(fetchVoidBets, 2000);
        }
        return () => clearInterval(intervalRef.current);

    }, []);

    // const filteredClients = voidBets.filter((client) => {
    //     return client.parent.toLowerCase().includes(searchField.toLowerCase());
    // });

    const onSubmit = async(e) => {
        e.preventDefault();
        const result = JSON.parse(runnerType);
        const data = {
            sport_id: parseInt(marketAssignData?.sport_id),
            match_id: parseInt(marketAssignData?.match_id),
            bookmaker_id: marketAssignData?.bookmaker?.id,
            result: { sln: result?.sln, si: result?.si },
            market_name: marketAssignData?.bookmaker?.market_name,
            selection: result?.sln,
            operators_list: operator
        };
        await showConfirmationDialog(`You want to Submit this Result (${result.sln})`)
        .then(async (result) => {
            if (result.isConfirmed) {
                const response = await postCaller(`api/v1/results`, data);
                if (response.success) {
                    await showSuccessMessage(response.message)
                    .then(function () {
                        setRunnerType('')
                        setOperatorList([])
                        setOperator('')
                        setResult('')
                        setOpen(true)
                    })
                } else {
                    showToast (response.message , true)

                }
            }
        })

    }
    const handleClearField = () => {
        setIsLive(true);
        setSearchQuery('');
        setSearchOperator('');
        setSearchSelection('');
        setMinStake('');
        setMaxStake('');
        setMinOdds('');
        setMaxOdds('');
        setSelectionType('');

        const searchQuery = ''
        const searchOperator = '';
        const searchSelection = '';
        const minStake = '';
        const maxStake = '';
        const minOdds = '';
        const maxOdds = '';
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        const fetchVoidBets = async () => {
            try {
                if (marketAssignData?.match_id) {
                    let response = await getCaller(
                        `api/v1/void-bets/3,2,1/4/${localMarketData.match_id}/bookmaker/${localMarketData?.bookmaker?.external_id}?page=1&limit=20&`, false
                    );
                    setTotalBet(response?.pagination?.total || 0);
                    setVoidBets(response.data)
                }
            } catch (error) {

            }
        };

        fetchVoidBets();
        if (!searchQuery && !searchOperator && !searchSelection && !minStake && !maxStake && !minOdds && !maxOdds) {
            intervalRef.current = setInterval(fetchVoidBets, 2000);
        }
    }

    const handleSearchBet = async () => {
        setIsLive(false);
        clearInterval(intervalRef.current);
        intervalRef.current = null;

        const fetchVoidBets = async () => {
            try {
                if (marketAssignData?.match_id) {
                    let response = await getCaller(
                        `api/v1/void-bets/3,2,1/4/${localMarketData.match_id}/bookmaker/${localMarketData?.bookmaker?.external_id}?page=1&limit=20&child=${searchQuery}&operator=${searchOperator}&selectionType=${selectionType === 'all' ? '' : selectionType}&selection=${searchSelection}&stakeMin=${minStake}&stakeMax=${maxStake}&oddsMin=${minOdds}&oddsMax=${maxOdds}`, false
                    );
                    setTotalBet(response?.pagination?.total || 0);
                    setVoidBets(response.data)
                }
            } catch (error) {

            }
        };
        await fetchVoidBets();

        if (!searchQuery && !searchOperator && !searchSelection && !minStake && !maxStake && !minOdds && !maxOdds && !selectionType) {
            intervalRef.current = setInterval(fetchVoidBets, 2000);
        }
    };

    const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

    const handleOperator = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'all')) {
            if (selectedOptions.length === options.length) {
                setOperator([]);
            } else {
                setOperator(operatorList.map(el => ({ value: el, label: el })));
            }
        } else {

            setOperator(selectedOptions);
        }
    };

    const getOptions = (selectedOptions) => {
        if (selectedOptions?.length === operatorList.length) {
            return operatorList.map((el) => ({ value: el, label: el }));
        }
        return options;
    };

    return (
        <div className="book-maker-market pageMain-container">
            <div className="market-match-container pageMain-wrap">
                <div className="pageMain-left">
                    <div className="bookMaker-container">
                        {isVisible && (
                            <div className="book-maker-match bookMaker-header">
                                <div className="span-book">
                                    <div className="result-event-select" id='bookmaker-select'>
                                        <p>Name</p>
                                        <input type="search" autoComplete="off" id="" className="book-maker-column-input"
                                            value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="User" />
                                    </div>
                                    <div className="result-event-select" id='bookmaker-select'>
                                        <p>Operator</p>
                                        <input type="search" autoComplete="off" id="" className="book-maker-column-input"
                                            value={searchOperator} onChange={(e) => setSearchOperator(e.target.value)}
                                            placeholder="Operator" />
                                    </div>
                                    <div className="result-event-select" id='bookmaker-select'>
                                        <p>Selection</p>
                                        <input type="search" autoComplete="off" id="" className="book-maker-column-input"
                                            value={searchSelection} onChange={(e) => setSearchSelection(e.target.value)}
                                            placeholder="Selection" />
                                    </div>
                                    <div className="result-event-select" id='bookmaker-select'>
                                        <p>Selection Type</p>
                                        <select value={selectionType} onChange={(e) => setSelectionType(e.target.value)}
                                            className="book-maker-column-input">
                                            <option value="all"> All </option>
                                            <option value="back"> Back </option>
                                            <option value="lay"> Lay </option>
                                        </select>
                                    </div>
                                    <div className="result-event-select widthFix" id='bookmaker-select'>
                                        <p style={{ display: 'flex', justifyContent: "space-around" }}>Stake</p>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <input type="number" autoComplete="off" id="" className="book-maker-column-input"
                                                value={minStake} onChange={(e) => setMinStake(e.target.value)} placeholder="Min "
                                                style={{ width: "70px" }} />
                                            <input type="number" autoComplete="off" id="" className="book-maker-column-input"
                                                value={maxStake} onChange={(e) => setMaxStake(e.target.value)} placeholder="Max "
                                                style={{ width: "70px" }} />
                                        </div>
                                    </div>
                                    <div className="result-event-select widthFix" id='bookmaker-select'>
                                        <p style={{ display: 'flex', justifyContent: "space-around" }}>Odds</p>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <input type="number" autoComplete="off" id="" className="book-maker-column-input"
                                                value={minOdds} onChange={(e) => setMinOdds(e.target.value)} placeholder="Min "
                                                style={{ width: "70px" }} />
                                            <input type="number" autoComplete="off" id="" className="book-maker-column-input"
                                                value={maxOdds} onChange={(e) => setMaxOdds(e.target.value)} placeholder="Max "
                                                style={{ width: "70px" }} />
                                        </div>
                                    </div>
                                    <Button type='submit' className='submit' name={'Search Bet'} onClick={handleSearchBet} />
                                    <Button type='submit' className='submit' name={'Clear Bet'} onClick={handleClearField} />
                                </div>
                            </div>
                        )}
                        <div className="bookMaker-body">
                            <div className="bookMaker-grid">
                                <div className="boder-odd">
                                    <SocketComponent eventID={localMarketData.event_id} dataFromChild={dataFromChild} setDataFromChild={setDataFromChild} bookmakerOddsData={handleDataFromBookmaker} onDataReceived={localMarketData} />
                                </div>
                                {marketAssignData.show_bet === 1 &&
                                    <div className="bookMaker-table">
                                        <table width="100%">
                                            <thead id="fancy-head-coloumn">
                                                <tr className="">
                                                    <th align="left"> OPERATOR</th>
                                                    <th align="left"> PARENT </th>
                                                    <th align="left"> USER </th>
                                                    <th align="left"> MARKET NAME </th>
                                                    <th align="left"> SELECTION </th>
                                                    <th align="left"> ODDS </th>
                                                    <th align="left"> STAKE </th>
                                                    {/* <th align="left"> P & L </th> */}
                                                    <th align="left"> DATE & TIME </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {voidBets?.length > 0 ? (
                                                    voidBets?.map((e, i) => (
                                                        <tr className={e.selection_type + ' custom-row'} key={i}>
                                                            <td>{e.operator}</td>
                                                            <td>{e.parent}</td>
                                                            <td>{e.child}</td>
                                                            <td>{e.event_name}</td>
                                                            <td>{e.selection}</td>
                                                            <td>{e.odds}</td>
                                                            <td>{e.stake}</td>
                                                            {/* <td>{e.p_l}</td> */}
                                                            <td>{timeConversion(e.created_at)}</td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <div>No data</div>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bookMaker-container">
                        <div className="bookMaker-header">
                            <div className="span-book gridCrad-box">
                                <div className="result-event-select flex-data" id='bookmaker-select'>
                                    <p> Message </p>
                                    <input type="text" autoComplete="off" name="msg" id="" className="book-maker-column-input width100" placeholder="Message" value={localMarketData.bookmaker.msg || ""} onChange={(event) => { const msg = event.target.value; const updatedBookmaker = { ...localMarketData.bookmaker, msg }; setLocalMarketData({ ...localMarketData, bookmaker: updatedBookmaker }); setDoUpdate(true); }} onBlur={updateBookmaker} />
                                </div>
                                <div className="result-event-select flex-data" id='bookmaker-select'>
                                    <p> End Date </p>
                                    <DatePicker
                                        name="end_date"
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        placeholderText="End Date & Time"
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        className='book-maker-column-input width100'
                                        value={localMarketData?.bookmaker?.end_date != null ? moment(localMarketData.bookmaker.end_date).format('YYYY-MM-DD HH:mm:ss') : ""}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                                <div className="result-event-select flex-data" id="bookmaker-select">
                                    <p>Back Modifier</p>
                                    <input type="number" autoComplete="off" name="back_modifier" id="back_modifier" className="book-maker-column-input width100" placeholder="Back Modifier"
                                        value={localMarketData.bookmaker.back_modifier}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            if (value === "" || (Number(value) >= 0 && !isNaN(value))) {
                                                let back_modifier = value === "" ? "" : Number(value);
                                                const updatedBookmaker = { ...localMarketData.bookmaker, back_modifier };
                                                setLocalMarketData({ ...localMarketData, bookmaker: updatedBookmaker });
                                                setDoUpdate(true);
                                            }
                                        }}
                                        onBlur={updateBookmaker}
                                    />
                                </div>
                                <div className="result-event-select flex-data" id="bookmaker-select">
                                    <p>Lay Modifier</p>
                                    <input type="number" autoComplete="off" name="lay_modifier" id="lay_modifier" className="book-maker-column-input width100" placeholder="Lay Modifier"
                                        value={localMarketData.bookmaker.lay_modifier}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            if (value === "" || (Number(value) >= 0 && !isNaN(value))) {
                                                let lay_modifier = value === "" ? "" : Number(value);
                                                const updatedBookmaker = { ...localMarketData.bookmaker, lay_modifier };
                                                setLocalMarketData({ ...localMarketData, bookmaker: updatedBookmaker });
                                                setDoUpdate(true);
                                            }
                                        }}
                                        onBlur={updateBookmaker}
                                    />
                                </div>
                                <div className="result-event-select flex-data" id='bookmaker-select' style={{ marginLeft: "auto" }}>
                                    <p className="suspend-para"> Suspend </p>
                                    <ToogleButton defaultChecked={marketAssignData.bookmaker.is_sus}
                                        onChange={handleToggleChange} />
                                </div>
                                <div className="result-event-select flex-data" id='bookmaker-select'>
                                    <p className="suspend-para"> Active </p>
                                    <ToogleButton defaultChecked={marketAssignData.bookmaker.is_active}
                                        onChange={handleToggleActive} />
                                </div>
                            </div>
                        </div>
                        <div className="bookMaker-body">
                            <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                                <table width="100%">
                                    <thead id="fancy-head-coloumn">
                                        <tr className="">
                                            <th align="left"> TEAM NAME </th>
                                            <th align="left"> BACK </th>
                                            <th align="left"> LAY </th>
                                            <th align="left"> SUSPEND </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {localMarketData?.bookmaker?.runners?.length > 0 ? (
                                            localMarketData?.bookmaker?.runners?.map((e, i) => (
                                                <tr className="fancy-head-row" key={i}>
                                                    <td>
                                                        <input type="text" autoComplete="off" disabled id="" name="selectionName" value={e.sln || ""} className="book-maker-column-input-1" placeholder="Team Name" />
                                                    </td>
                                                    <td>
                                                        <input type="number" autoComplete="off" name="back" className="book-maker-column-input" placeholder="Back" disabled value={inputBackValue[i] || ''} />
                                                    </td>
                                                    <td>
                                                        <input type="number" autoComplete="off" name="lay" className="book-maker-column-input" placeholder="Lay" disabled value={inputLayValue[i] || ''} />
                                                    </td>
                                                    <td align="left">
                                                        <ToogleButton defaultChecked={e.ss === 'SUSPEND'} value={e.ss}
                                                            onChange={(event) => handleToggleSS(event, i)} />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5">No data</td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                    {marketAssignData.allow_declare_result === 1 &&
                        <div className="bookMaker-container">
                            <div className="bookMaker-header">
                                <div className="bookMaker-heading">
                                    <p> BOOKMAKER RESULT DECLARE </p>
                                </div>
                            </div>
                            <div className="bookMaker-body">
                                <form>
                                    <div className="book-maker-content">
                                        <div className='book-maker-select-container'>
                                            <div className='book-maker-select'>
                                                <label htmlFor="bookmaker-select"> Select bookmaker </label>
                                                <select id="bookmaker-select">
                                                    <option value="">{localMarketData.bookmaker.market_name}</option>
                                                </select>

                                            </div>
                                            <div className="book-maker-select" id='bookmaker-select'>
                                                <label htmlFor="date"> Select Result </label>
                                                <Select
                                                    value={result ? { value: result.value, label: result.label } : null}
                                                    onChange={(selectedOption) => handleBookmakerResult(selectedOption)}
                                                    options={localMarketData?.bookmaker?.runners?.length > 0 ? [...localMarketData?.bookmaker?.runners?.map((el) => ({ value: JSON.stringify(el), label: el.sln })), { value: JSON.stringify({ 'sln': 'tie', 'si': 'tie' }), label: 'Tie' }, { value: JSON.stringify({ 'sln': 'abandoned', 'si': 'abandoned' }), label: 'Abandoned' },] : []}
                                                    disabled={isOpen}
                                                    placeholder="Select Result"
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            outline: "none",
                                                            border: "1px solid #cbcbcb",
                                                            height: '40px',
                                                        }),
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            zIndex: 20,
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided,
                                                            marginTop: "-7px"
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='book-maker-select'>
                                                <label> Select Operators </label>
                                                <Select
                                                    onChange={handleOperator}
                                                    options={getOptions(operator)}
                                                    value={operator}
                                                    placeholder="Select Operators"
                                                    isMulti
                                                />

                                            </div>
                                            <div className="book-maker-btn-container">
                                                <div>
                                                    <Button className='submit' name='Submit Result' disabled={isOpen}
                                                        type='submit' onClick={onSubmit} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }

                </div>
                <div className="pageMain-right">
                    <div className='filter-container'>
                        <div className='filter-title-wrap'>
                            <span className='filter-title'>Bets</span>
                            <span className='betsCount'>{totalBets}</span>
                        </div>
                        <div className='filter-wrap'>
                            <div className="filter-icon" onClick={toggleVisibility}>
                                <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.94 22.65C10.4537 22.6492 9.97555 22.5254 9.55003 22.29C9.12221 22.0573 8.76529 21.7133 8.51707 21.2943C8.26885 20.8753 8.13857 20.397 8.14003 19.91V14.61C8.08574 14.0169 7.86376 13.4516 7.50003 12.98L3.76003 9.02001C3.10439 8.33721 2.71123 7.44463 2.65003 6.50001V4.20001C2.63964 3.83132 2.70293 3.46426 2.8362 3.12034C2.96947 2.77642 3.17003 2.46256 3.42614 2.19714C3.68225 1.93171 3.98875 1.72007 4.3277 1.57461C4.66664 1.42915 5.0312 1.35279 5.40003 1.35001H18.6C18.9612 1.34974 19.319 1.4207 19.6527 1.5588C19.9865 1.69691 20.2897 1.89946 20.5452 2.15488C20.8006 2.41029 21.0031 2.71355 21.1412 3.04732C21.2793 3.38108 21.3503 3.7388 21.35 4.10001V6.30001C21.2891 7.35753 20.8571 8.35967 20.13 9.13001L15.8 12.96C15.3332 13.4204 15.0642 14.0445 15.05 14.7V19C15.0368 19.4612 14.916 19.913 14.6972 20.3193C14.4784 20.7255 14.1678 21.0751 13.79 21.34L12.41 22.23C11.9689 22.5046 11.4596 22.6501 10.94 22.65ZM5.40003 2.85001C5.22876 2.85486 5.06018 2.89383 4.90417 2.96465C4.74815 3.03546 4.60783 3.13669 4.49142 3.26241C4.37502 3.38813 4.28486 3.53581 4.22624 3.69681C4.16762 3.8578 4.14171 4.02887 4.15003 4.20001V6.50001C4.21548 7.0496 4.45356 7.56428 4.83003 7.97001L8.64003 11.98C9.24265 12.7261 9.59591 13.6425 9.65003 14.6V19.9C9.64844 20.1199 9.70729 20.336 9.82015 20.5246C9.93302 20.7133 10.0956 20.8674 10.29 20.97C10.4917 21.0841 10.7197 21.1432 10.9514 21.1414C11.183 21.1396 11.4101 21.0771 11.61 20.96L13 20.07C13.1621 19.9399 13.2954 19.7776 13.3918 19.5935C13.4882 19.4094 13.5455 19.2073 13.56 19V14.7C13.565 14.1589 13.6801 13.6244 13.8985 13.1292C14.1168 12.6341 14.4338 12.1886 14.83 11.82L19.11 8.03001C19.5424 7.54689 19.8057 6.93608 19.86 6.29001V4.10001C19.86 3.76849 19.7283 3.45054 19.4939 3.21612C19.2595 2.9817 18.9415 2.85001 18.61 2.85001H5.40003Z" fill="currentColor" />
                                    <path d="M6.00001 10.746C5.85952 10.7437 5.7219 10.7059 5.60001 10.636C5.43116 10.5292 5.31126 10.36 5.26633 10.1653C5.2214 9.97061 5.25506 9.76605 5.36001 9.59601L10.29 1.69601C10.3968 1.53214 10.5636 1.41665 10.7546 1.37429C10.9456 1.33193 11.1456 1.36607 11.3117 1.4694C11.4778 1.57272 11.5968 1.73701 11.6432 1.92704C11.6896 2.11708 11.6598 2.31774 11.56 2.48601L6.63001 10.386C6.56489 10.4952 6.47268 10.5857 6.36231 10.6488C6.25193 10.7119 6.12713 10.7453 6.00001 10.746V10.746Z" fill="currentColor" />
                                </svg> {`${isVisible ? 'Hide' : 'Show'} Filter`}
                            </div>
                            <div className={`filter-live ${isLive ? 'isLive' : 'isNotLive'}`} >Live Bets<span></span></div>
                            <div className={`filter-clear ${!isLive ? 'filterOn' : 'filterOff'}`} onClick={handleClearField}>Clear Filter</div>
                        </div>
                    </div>
                    <div className='market-dataWrap'>
                        <div className="market-odds-body">
                            <div className="">
                                <p> {marketAssignData?.bookmaker?.market_name} </p>
                            </div>
                            <div className="market-odds-back">

                                <div style={{ marginRight: ".5rem" }}>
                                    <p>BACK</p>
                                </div>{" "}
                                <div>
                                    <p>LAY</p>
                                </div>{" "}
                            </div>
                        </div>
                        <div className="boder-odd">
                            {marketAssignData?.bookmaker?.is_sus === 1 ? (
                                <div className="suspend-boxx"> Suspended </div>
                            ) : null}

                            {(dataFromBookmaker.length ? dataFromBookmaker : localMarketData?.bookmaker?.runners)?.map((e, i) => (
                                <div className="odds-container" key={i}>
                                    <div className="box-odd-name" data-label="#">
                                        <h3> {e.sln} </h3>
                                    </div>

                                    <div className="odds-container-1">
                                        {e?.ss === 'ACTIVE' ? null : (
                                            <div className="suspend-box"> Suspended </div>
                                        )}{" "}
                                        <div className="back-box-container-one" data-label="#">
                                            <div className="box" style={{ backgroundColor: "#88cdf4" }}>{e?.b}</div>
                                        </div>
                                        <div className="back-box-container-one">
                                            <div className="box" style={{ backgroundColor: "#d7787d" }}>{e?.l}</div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgentGetAutoBookmaker;
