import React from 'react'
import '../bookmakerMarketOdds/bookmakerodd.css'
const BookmakerOddsMarket = ({ bookmaker }) => {
    // console.log(bookmaker)
    return (
        <div className='bookmaker-container'>
            {
                bookmaker?.ml?.length > 0 ? bookmaker?.ml?.map((item, index) => (
                    <div className='all-market-container' key={index}>
                        <div className="all-market-head">
                            <div className=""> <p> {item.mn} </p> </div>
                            <div className="all-back-lay">
                                <span>Back</span>
                                <span>Lay</span>
                            </div>
                        </div>
                        <div className='all-market-body-container' >
                            {
                                item?.sl?.length > 0 && item?.sl?.map((el, index1) => (
                                    <div className="all-market-body" key={index1}>
                                        <div className=""> <p>{el.sln}</p> </div>
                                        {el?.ss === 'SUSPEND' || false  ? <h2 className='suspend-bookmaker'> Suspended </h2>:null }
                                        <div className="all-back-lay-1">
                                            {/* back odds  */}
                                            <div className="all-box-bookmaker">
                                                <p> {el?.ss === 'SUSPEND'  ? null : el.b} </p>
                                            </div>
                                            {/* lay odds  */}
                                            <div className="all-box-bookmaker" style={{ backgroundColor: "#feafba" }}>
                                                <p> {el?.ss === 'SUSPEND'  ? null : el.l} </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="min-max-bookmaker">
                                <p className='min-max'>Min: {item?.mms
                                    ?.split('-')?.[0]} | Max: {item?.mms
                                        ?.split('-')?.[1]}</p>
                            </div>
                        </div>

                    </div>
                )) : null
            }
        </div>
    )
}
export default BookmakerOddsMarket