import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ToogleButton from '../../component/toggleButton/ToogleButton';
import { postCaller, updateCaller, getCaller } from '../../services/api';
import { AiOutlineClose, AiOutlineCloseCircle} from 'react-icons/ai';
import { motion } from "framer-motion";
import '../user/user.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { showToast, showConfirmationDialog, showSuccessMessage } from '../../utilities/toastHelper';
import Button from '../../component/button/Button';

const UserCreateForm = ({ modalOpen, setModalOpen, updateItem, setUpdateItem, isAgent }) => {
  const [isDeclared, setIsDeclared] = useState(0);
  const [userIdError, setUserIdError] = useState('');
  const [ipAddresses, setIpAddresses] = useState([]);
  const [ipAddress, setIpAddress] = useState('');
  const [addressName, setAddressName] = useState('');

  const navigate = useNavigate();
  const { state } = useLocation();
  const updateAgentData = state?.passData ?? {};
  const isAddMode = !updateAgentData?.id;

  const AgentSchema = Yup.object().shape({
    user_id: Yup.string().matches(/^\S*$/, 'Whitespace is not allowed').required('User Id Required'),
    name: Yup.string().required('Name is Required'),
    password: Yup.string().when('isAddMode', {is: true, then: Yup.string().required("Password is required"), otherwise: Yup.string().notRequired()}),
    confirmPassword: Yup.string().when('isAddMode', {is: true, then: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Both passwords need to be the same'), otherwise: Yup.string().notRequired()}),
    managerPassword: Yup.string().required("Manager Password is required"),
  });

  const checkButtonState = () => {
    let flag = true;
  
    for (const key in formik?.values) {
      if (formik?.values[key] !=="") {
        flag = false;
      } else {
        flag = true;
        break;
      }
    }

    if (!flag && formik?.values?.confirmPassword !== formik?.values?.password) {
      flag = true;
    }

    return flag;
  }

  let userType = localStorage.getItem('user_role').toLowerCase();

  let formikData = {
    user_id: updateAgentData?.user_id || "",
    name: updateAgentData?.name || "",
    managerPassword: "",
    isAddMode: isAddMode
  }

  if(isAddMode){
    formikData.password= "";
    formikData.confirmPassword = "";
  }

  const formik = useFormik({
    initialValues: formikData,
    validationSchema: AgentSchema,
    onSubmit: async (values) => {
      values.ip_address_list = ipAddresses;
      values.user_role = userType === 'admin' ? 'MANAGER' : 'AGENT';
      values.is_declared = userType === 'admin' ? null : isDeclared;
      
      if (isAddMode) {
        const result = await showConfirmationDialog('You Want to Create Manager');
        if (result.isConfirmed) {
          const { success, message } = await postCaller(`api/v1/users/addAgents`, values);
  
          if (success === true && message !== "ALREADY_REGISTERED") {
            showSuccessMessage(message);
            navigate('/user');
          } else {
            showToast(message, true);
          }
        }
      } else {
        const res = await updateCaller(`api/v1/users/updateAgents?id=${updateAgentData.id}`, values);
        if (res.success) {
          showSuccessMessage("Updated successfully!");
          navigate('/user');
        } else {
          showToast(res.message, true);
        }
      }
    },
  });

  const handleDeclareResult = () => {
    setIsDeclared(prev => (prev === 0 ? 1 : 0));
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    formik.setFieldValue(name, value);

    if (name === 'user_id') {
      await checkUsernameAvailability(value);
    }
  };

  const checkUsernameAvailability = async (username) => {
    try {
      if (!username) {
        setUserIdError('');
        return;
      }

      const res = await getCaller(`api/v1/users/checkAvailability/${username}`);
      setUserIdError(res.data.available ? '' : 'User ID already exists');
    } catch (error) {
        console.error(error);
    }
  };

  useEffect(() => {
    if (updateAgentData?.ip_address_list) {
      const parsedIpList = JSON.parse(updateAgentData.ip_address_list);
      setIpAddresses(parsedIpList);
    }
  }, [updateAgentData]);

  const ipAddressRegex = /^(?:\d{1,3}\.){3}\d{1,3}$|^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/;

  const handleAddIp = () => {
    if (ipAddress && addressName) {
      if (!ipAddresses.some(item => item.ip === ipAddress)) {
        if (ipAddressRegex.test(ipAddress)) {
          const newIpEntry = { ip: ipAddress, name: addressName };
          setIpAddresses([...ipAddresses, newIpEntry]);
          setIpAddress('');
          setAddressName('');
        } else {
          showToast('Please enter a valid IP address format.', true);
        }
        setIpAddress('');
      } else {
        showToast('This IP address has already been added.', true);
      }
    } else {
      showToast('Please enter both IP address and address name.', true);
    }
  };
  
  const handleRemoveIp = (index) => {
    const updatedIps = ipAddresses.filter((_, i) => i !== index);
    setIpAddresses(updatedIps);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddIp();
    }
  };

  return (
    <div className="sport-container">
      <motion.div className="agent-modal" initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
        <div className='add-agent-head'>
          { userType  === 'admin' ? (
            <div>{isAddMode ? 'Add Manager' : 'Update Manager'}</div>
          ) : (
            <div>{isAddMode ? 'Add Agent' : 'Update Agent'}</div>
          )}
          <div className="ai-close">
            <AiOutlineClose onClick={() => navigate('/user')} />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='agent-add-form'>
            <div className="agent-input-body">
              <label htmlFor="user_id">USER ID <span style={{ color: 'red' }}>*</span></label><br />
              <input
                type="text"
                autoComplete='off'
                disabled={!isAddMode}
                name='user_id'
                onBlur={formik.handleBlur}
                value={formik.values.user_id||""}
                className='agent_input'
                onChange={handleChange}
              /><br />
              {formik.touched.user_id && formik.errors.user_id && <div style={{ color: "red" }}>{formik.errors.user_id}</div>}
              {userIdError && <div style={{ color: 'red' }}>{userIdError}</div>}
            </div>
            <div className="agent-input-body">
              <label htmlFor="name">NAME <span style={{ color: 'red' }}>*</span></label><br />
              <input
                type="text"
                autoComplete="off"
                name='name'
                onBlur={formik.handleBlur}
                value={formik.values.name || ""}
                className='agent_input'
                onChange={formik.handleChange}
              />
              {formik.errors.name && formik.touched.name && <span className="error" style={{ color: 'red' }}>{formik.errors.name}</span>}
            </div>
            <div className="agent-input-body ip-address-form">
              <div className='multiSelect-label-wrap'>
                <label htmlFor="addressName">IP Address Name</label>
                <label htmlFor="ipAddress">IP Address</label>
              </div>
              <div className="input-container">
                <div className="multiSelect-wrap">
                  <div className='multiSelect-inp-wrap'>
                    <input type="text" autoComplete="off" name="addressName" value={addressName} onChange={(e) => setAddressName(e.target.value)} onKeyDown={handleKeyDown} className="agent_input" placeholder="Enter Address Name"/>
                    <span className='multiSelect-divide'></span>
                    <input type="text" autoComplete = "off" name='ipAddress' value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} onKeyDown={handleKeyDown} className="agent_input" placeholder="Enter IP Address"/>
                  </div>
                  {ipAddresses.length > 0 && (
                    <div className="selected-ip-container">
                      {ipAddresses.map((ipEntry, index) => (
                        <div key={index} className="ip-item">
                          {ipEntry.name} ({ipEntry.ip})
                          <AiOutlineCloseCircle className="remove-ip-icon" onClick={() => handleRemoveIp(index)} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <button type="button" onClick={handleAddIp} className="add-ip-btn">+ Add IP</button>
              </div>
            </div>
            {isAddMode ? (
              <>
                <div className="agent-input-body">
                  <label htmlFor="password">PASSWORD <span style={{ color: 'red' }}>*</span></label><br />
                  <input
                    type="password"
                    autoComplete="new-password"
                    name='password'
                    onBlur={formik.handleBlur}
                    value={formik.values.password||""}
                    onChange={formik.handleChange}
                    className='agent_input'
                  />
                  {formik.errors.password && formik.touched.password && <span className="error" style={{ color: 'red' }}>{formik.errors.password}</span>}
                </div>
                <div className="agent-input-body">
                  <label htmlFor="confirmPassword">CONFIRM PASSWORD <span style={{ color: 'red' }}>*</span></label><br />
                  <input
                    type="password"
                    autoComplete="off"
                    name='confirmPassword'
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword || ""}
                    className='agent_input'
                    onChange={formik.handleChange}
                  />
                  {formik.errors.confirmPassword && formik.touched.confirmPassword && <span className="error" style={{ color: "red" }}>{formik.errors.confirmPassword}</span>}
                </div>
                {userType !== 'admin' && (
                  <div className='agent-permission-container'>
                    <p className='agent-permission'>PERMISSION</p>
                    <div className='agent-declare-result'>
                      <div className='declare-result'>
                        <div className='r-body'>DECLARED RESULTS</div>
                        <div>
                          <ToogleButton defaultChecked={true} onChange={handleDeclareResult} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>) : null
            }
          </div>
          <div className='agent-password-container'>

            <input
              type="text" 
              style={{ position: 'absolute', left: '-9999px' ,opacity: '0',zIndex: '-11111' }} 
              placeholder='search'
              autoComplete='off'
            />

            <input
              type="password"
              name='managerPassword'
              onBlur={formik.handleBlur}
              value={formik.values.managerPassword}
              autoComplete = "new-password"
              onChange={formik.handleChange}
              className="password-input"
              placeholder='Password '
            /><br />
            <div>
              <Button disabled={userIdError || checkButtonState()} name='Submit' type='submit' className='submit'
              />
            </div>
          </div>
          {formik.errors.managerPassword && formik.touched.managerPassword && <span className="error" style={{ color: 'red', marginLeft: '1rem' }}>{formik.errors.managerPassword}</span>}
        </form>
      </motion.div>
    </div>
  );
};

export default UserCreateForm;
