import React from 'react'
import { useNavigate } from 'react-router-dom'
import b from '../../assets/b-image.png'
import f from '../../assets/f-image.png'
import rules from '../../assets/rules.png'
const InplaySport = ({ inplayData, sportName, setEventTab, eventTab, sportId }) => {
  //console.log(sportId)
  const navigate = useNavigate()
 const handleInplay=(item)=>{
  navigate(`/inplayevents/:${item.event_id}`, {
    state: {
      data: item
    }
  })
 }
  return (
    <div className="all-inplay-match">
      <div className="inplay-body">
        <h3>{sportName} ({inplayData?.length}) </h3>
        <div className="live-btn-container">
          <img src={rules} alt="" />
          <button type='button' className={`btn-index ${eventTab === 0 ? 'active-btn-index' : null}`} onClick={() => setEventTab(0)}>Live</button>
          <button type='button' className={`btn-index ${eventTab === sportId ? 'active-btn-index' : null}`} onClick={() => setEventTab(sportId)}>Upcoming</button>
        </div>
      </div>
      <div className="inplay-content">
        <div className="inplay-match">
          {
            inplayData?.length > 0 ? inplayData?.map((el, i) => (
              <div className="inplay-gap" key={i}>
                 {/* <p>{el.sport_name}</p> */}
                <p className='league-name'>{el.league_name}</p>
                <div className="inplay-odd" onClick={() => handleInplay(el)}>
                  <div className="event-name-container">
                    <div>
                      <p className='sport-name'>{el?.event_name}</p>
                      <p className='sport-time'> {el.is_custom===1?(new Date(el?.event_date?.slice(0,el?.event_date?.length - 5)?.replace('T', " "))).toLocaleString():(new Date(el?.event_date)).toLocaleString()} </p>
                    </div>
                    <div className="fancy-book-container">
                      {el?.has_bookmaker === 1 ? <p className='bookmaker'> <img src={b} alt="" /> </p> : null}
                      {el?.has_fancy === 1 ? <p className='bookmaker' id='fancy'> <img src={f} alt="" /> </p> : null}
                    </div>
                  </div>
                  <div className='inplay-odds-container'>
                    
                   {
                     
                        el?.runners?.length>0 && el?.runners?.map((item, i) => (
                       <>
                         <div className='inplay-box-container' key={i}>
                          <div className="inplay-box" ><p>{item?.ex?.b[0]?.p??"-"}</p></div>
                          <div className="inplay-box-1"><p>{item?.ex?.l[0]?.p??"-"}</p></div>
                         </div>
                         
                       </>
                         
                        ))
                   }
                    
                  </div>
                  <div className="min-max-container">
                    <p className='min-max'>Min:100 | Max:200k</p>
                  </div>
                </div>
              </div>
            )) : null
          }
        </div>
      </div>
    </div>
  )
}

export default InplaySport