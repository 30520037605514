import React from 'react';
import '../customGames/custom.css';
import { useLocation, useNavigate } from 'react-router-dom';

const MarketSettingsTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="tab-container">
      <div  className={`tab-box ${location.pathname === '/setting/market_settings' ? 'active-result-tab' : null}`} onClick={() => navigate('/setting/market_settings')}>
        <h4> Market Limits </h4>
      </div>

      <div className={`tab-box ${location.pathname === '/setting/odds_gap' ? 'active-result-tab' : null}`} onClick={() => navigate('/setting/odds_gap')}>
        <h4> Odds Gap </h4>
      </div>

      <div className={`tab-box ${location.pathname === '/setting/back_setting' ? 'active-result-tab' : null}`} onClick={() => navigate('/setting/back_setting')}>
        <h4> Manage Back Fields </h4>
      </div>
    </div>
  )
};

export default MarketSettingsTab;