import React from 'react'
import ShowManager from './ShowManager'
import ShowAgent from './ShowAgent'
import { motion } from "framer-motion";
import '../user/user.css'
const ShowUser = () => {
    let userType = localStorage.getItem('user_role').toLowerCase();
    return (
        <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}>
            <div className="wrapper-head">
                <h3> {userType === 'admin' ? 'All Manager' : 'All Agents'} </h3>
            </div>
            <div >
                {userType === 'admin' ? <ShowManager /> : <ShowAgent />}
            </div>
        </motion.div>
    )
}

export default ShowUser
