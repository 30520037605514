import React from 'react'
import ErrorBoundary from '../../../error/ErrorBoundary';
import '../leagueFromSport/leagueManage.css'
import LeagueAccordian from './LeagueAccordian';
const LeagueDetails = ({ leagueData, setLeagueData }) => {
  return (
    <div className="">
      {
        leagueData?.length > 0 ? leagueData?.map((league,i) => (<LeagueAccordian key={i} index={i} leagueData={leagueData} setLeagueData={setLeagueData} league={league}  />)):<ErrorBoundary/>
      }
    </div>
  )
}
export default LeagueDetails