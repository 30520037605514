import React, { useState, useEffect } from 'react'
import { getCaller, postCaller } from '../../services/api'
import Pagination from '../../component/pagination/Pagination'
import ResultTab from './WorliMatkaResultTab'
import '../result/result.css'
import { motion } from "framer-motion";
import Button from '../../component/button/Button';
import CONSTANTS from '../../utilities/constants'
import ServerToBrowserTime from './serverToBrowser'; 
import Result from '../result/result';
import getLocalData from '../../utilities/LocalStorageData.utilities'
import { showToast, showSuccessMessage } from '../../utilities/toastHelper';

const WorliMatkaPendingMatchResult = ({ submitResult }) => {
  const [pendingMatchData, setPendingMatchData] = useState([])
  const [results, setResults] = useState({});
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchedPages, setFetchedPages] = useState({});
  const [scope, setScope] = useState({})
  const  limit = 100;
  useEffect(() => {

    const {result}= getLocalData('scope')
    const userType= getLocalData('user_role')
    setScope({result,userType})

    async function getSport() {
      await getPendingMatchResult()
    }
    getSport()
  }, [])

  const getPendingMatchResult = async (page =1) => {
    if (fetchedPages[page]) {
      setPendingMatchData(fetchedPages[page]);
      if(page !==1) return
  }
  
    const resultResponse = await getCaller(`api/v1/worli/matches/pendingresult?page=${page}&limit=${limit}`)
    const filteredData =resultResponse?.data
    setPendingMatchData(filteredData)
    setTotalPage(resultResponse?.pagination?.totalPages)
    setFetchedPages((prev) => ({ ...prev, [page]: filteredData }));
  }

  const handlePageChange = async(page) => {
    setCurrentPage(page);
    await getPendingMatchResult(page)
  };

  const handleAddResult = async (i, e) => {

    if (CONSTANTS.WORLI.ALLOWED_RESULT.length > 0 && (!CONSTANTS.WORLI.ALLOWED_RESULT.includes(parseInt(results[`result-${i}`])))) {
      const Title =  'Invalid Result Value'
      showToast (Title , true)
      return
    }
    const response = await postCaller(`api/v1/worli/matches/result/${e.market_id}`, { matchTime: ((e.match_time).split('T')[0]), result: results[`result-${i}`] })
    if (response.success) {
      showSuccessMessage("Match result has been added")
      setResults({})
    } else {
      showToast (response.message , true)
    }
  await getPendingMatchResult();
  }
  const handleResultChange = (e, id) => {
    const newResults = { ...results, [id]: e.target.value };
    setResults(newResults);
   
  };
  
  let completeData = pendingMatchData?.length && pendingMatchData?.filter((el) => true);

  return (
    <> 
      <Result />
      <ResultTab />
      <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}>

        <div className="result-sport-content">
          <div className="result-event-select"></div>
          <div className="result-event-select"></div>
          <div className="result-event-select"></div>
        </div>

        <div className="table-border">
          <div className="wrapper-body">
            <div className="wrapper-content-1">
            </div>
          </div>
          <div style={{ overflowX: "auto" }}>
            <table width="100%"  >
              <thead>
                <tr className='custom-row-result' >
                  <th>Match Id</th>
                  <th> Match Title </th>
                  <th> Match Type </th>
                  <th> Market Time </th>
                  <th> Result</th>
                  <th> Action </th>
                </tr>
              </thead>
              <tbody>
                {
                  completeData?.length > 0 ?
                    completeData?.map((e, i) => (
                      <tr className='custom-row-result' key={i}>
                        <td>{e.id}</td>
                        <td style={{ textTransform: "capitalize", width: "25px" }}> {e.match_title} </td>
                        <td >{e.match_type}  </td>
                        <td > 
                           <ServerToBrowserTime serverTime={e.match_time} />   
                        </td>
                        <td > <input id={`result-${i}`} type='text' placeholder='Enter Result' style={{ height: "35px" }} value={results[`result-${i}`] || ''} onChange={(e) => handleResultChange(e, `result-${i}`)} ></input>  </td>
                        <td ><Button className='submit' name='Set Result' type='submit'  onClick={() => handleAddResult(i, e)} disabled={(scope?.userType==='manager' && !scope?.result?.add) || (results[`result-${i}`]===''||!results[`result-${i}`])} /> </td>
                      </tr>
                    )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
                }
              </tbody>

            </table>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPage}
          onPageChange={handlePageChange}
        />
      </motion.div>
    </>
  )
}

export default WorliMatkaPendingMatchResult