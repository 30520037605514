import React, { useCallback, useEffect, useState } from 'react'
import { RiFootballFill } from 'react-icons/ri'
import ErrorBoundary from '../../error/ErrorBoundary'
import { getCaller } from '../../services/api'
import '../../userPannel/inplayAllMatches/userPannel.css'
import InplaySport from './InplaySport'

const UserSportTab = () => {
  const [eventTab, setEventTab] = useState(0)
  const [getSportData, setGetSportData] = useState([]);
  const [inplayData, setInplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sportName, setSportName] = useState("")
  const [sportId, setSportId] = useState("")

  const getAllData = useCallback(async () => {
    try {
      const res = await getCaller('api/v1/sports/management/getSport');
      const sportData = res.data
      sportData.unshift({ id: 0, name: "inplay" })
      setGetSportData(sportData)
      getMatchFromSport(res?.data[0])
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }, [])
  useEffect(() => {
    getAllData();
  }, [getAllData]);


  const getMatchFromSport = async (e, i) => {
    setLoading(true);
    setEventTab(e.id)
    let name = e.name
    let id = e.id
    setSportId(id)
    setSportName(name)
    //console.log(e.name.length)
    if (e.name === 'inplay' && e.id === 0) {
      const res = await getCaller(`api/v1/events/matches/inplay`)
      setInplayData(res?.data)
    }
    else {
      const res = await getCaller(`api/v1/events/matches/${e.id}`)
      setInplayData(res.data)
    }
    setLoading(false);
  }

  //console.log(inplayData)
  return (
    <>
      <ul className='navbar'>
      {
            getSportData?.length ? getSportData?.map((e, i) => (
              <li key={i} className={`user-tab ${eventTab === e.id ? 'user-tab-active' : null} `}
                onClick={() => getMatchFromSport(e)}>
                <div className="nav-link-tab">
                <RiFootballFill style={{ fontSize: "1.5rem" }} />
                <p className="nav-item">{e.name}</p>
                </div>
              </li>
            )) : <ErrorBoundary />
          }
        
      </ul>
    
      {
        loading ?
          <div className="loader-wrapper">
            <div className='loader'>
            </div>
          </div> : <InplaySport sportId={sportId} eventTab={eventTab} setEventTab={setEventTab} sportName={sportName} inplayData={inplayData} setInplayData={setInplayData} />
      }
    </>
  )
}

export default UserSportTab