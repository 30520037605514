import React, { useEffect, useState } from 'react'
import BookmakerOddsMarket from './bookmakerMarketOdds/BookmakerOddsMarket'
import MatchOddsMarket from './matchOdssMarket/MatchOddsMarket'
import UserLayout from '../UserLayout'
import { useLocation, useParams } from 'react-router-dom'
import socketIOClient from "socket.io-client";
import FancyMarketTab from './fancyMarket/FancyMarketTab'
import '../inplayAllOdds/inplayTab.css'
const ENDPOINT = "ws://3.110.13.16:9811";
const InplayTab = () => {
  const { id } = useParams();
 // console.log(id)
  const [inplayIndex, setInplayIndex] = useState(0)
  const { state } = useLocation()
  const eventData = state?.data;
 // console.log(eventData)
  const [socket, setSocket] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const [matchOdds, setMatchOdds] = useState();
  const [bookmaker, setBookmaker] = useState();
  const [fancy, setFancy] = useState({});
  const tabsData = [
    { id: 1, name: "All" }, { id: 1, name: "Match Odds" }, { id: 1, name: "Bookmaker" }, { id: 1, name: "Fancy" }
  ]
  useEffect(() => {
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  useEffect(() => {
    if (!socket || !eventData)
      return;
    socket.on("connect", () => {
      socket.emit("sub", eventData.market_id, eventData.event_id);
      setSocketConnected(true);
    });
    return () => setSocketConnected(false);
  }, [socket, eventData]);

  useEffect(() => {
    if (!socketConnected)
      return;
    const handleMatchOdds = (newMatchOdds) => {
      if (!newMatchOdds)
        return;
      setMatchOdds((oldMatchOdds) => {
        let matchOdds = {
          mid: newMatchOdds.mid,
          st: newMatchOdds.st || oldMatchOdds.st,
          runners: oldMatchOdds?.runners || []
        };
        for (let runner of newMatchOdds.runners) {
          const oldRunner = oldMatchOdds?.runners.find(e => e?.sid === runner?.sid)
          if (!oldRunner)
            matchOdds.runners.push(runner);
          else
            oldRunner.ex = {
              l: runner?.ex?.l || oldRunner?.ex?.l,
              b: runner?.ex?.b || oldRunner?.ex?.b
            }
        }
        return matchOdds;
      });
    }
    const handleBookmakerOdds = (newBookmakerOdds) => {
     
      if (!newBookmakerOdds)
        return;
      setBookmaker((oldBookmakerOdds) => {
        let bookmakerOdds = {
          eid: newBookmakerOdds.eid,
        }
        let marketList = [];
        for (let ml of newBookmakerOdds?.ml || []) {
          const oldMl = oldBookmakerOdds?.ml.find(e => e?.eid === ml?.eid)
          if (!oldMl) {
            marketList.push(ml);
            continue;
          }
          ml.sl = oldMl?.sl?.map(osl => {
            const nsl = ml?.sl?.find(sl => sl?.si === osl?.si);
            if (nsl)
              return { ...osl, ...nsl }
            return osl;
          });
          marketList.push({ ...oldMl, ...ml })
        }
        bookmakerOdds.ml = marketList;
       // console.log(oldBookmakerOdds,"jkhgfdh", newBookmakerOdds,"new",bookmakerOdds,"diff")
        return bookmakerOdds;
      })
    }
    const handleFancyOdds = (newFancyOdds) => {
      if (!newFancyOdds)
        return;
      setFancy((oldFancyOdds) => {
        let fancyOdds = {
          eid: newFancyOdds.eid,
        }
        let marketList = [];
        for (let oldMarket of oldFancyOdds?.ml || []) {
          const newMarket = newFancyOdds?.ml?.find(e => e?.mi === oldMarket?.mi);
          if (!newMarket) {
            marketList.push(oldMarket);
            continue;
          }
          marketList.push({ ...oldMarket, ...newMarket });
        }
        for (let newMarket of newFancyOdds.ml) {
          const oldMarket = oldFancyOdds?.ml?.find(e => e?.mi === newMarket?.mi);
          if (!oldMarket)
            marketList.push(newMarket);
        }
        fancyOdds.ml = marketList;
       // console.log(oldFancyOdds, newFancyOdds, fancyOdds)
        return fancyOdds;
      })
    }
    socket.on('App\\\\Events\\\\SportsBroadcastData', handleMatchOdds);
    socket.on('App\\\\Events\\\\BroadcastBookmaker', handleBookmakerOdds);
    socket.on('App\\\\Events\\\\BroadcastFancy', handleFancyOdds);
  }, [socketConnected, setMatchOdds, socket]);

  return (
    <>
      <UserLayout>
        <div className='inplay-match-container'>
          <div className="inplay-match-body-content">
            <div className="inplay-match-body">
              <p className='inplay-time'> {eventData?.is_custom===1?(new Date(eventData?.event_date?.slice(0,eventData?.event_date?.length - 5)?.replace('T', " "))).toLocaleString():(new Date(eventData?.event_date)).toLocaleString()} </p> {eventData?.inplay === true ? <div className='button-inplay'>inplay</div> : null}
            </div>
            <h4> {eventData?.event_name?.replace("v","vs")} </h4>
          </div>
          <div className="inplay-tab-container">
            {
              tabsData.map((el, i) => (
                <div key={i} className={`inplay-tab ${inplayIndex === i ? 'active-inplay-tab' : ''} `} onClick={() => setInplayIndex(i)}> <p>{el.name}</p> </div>
              ))
            }
          </div>
        </div>
        <div className="" hidden={inplayIndex !== 0}>
          <MatchOddsMarket matchOdds={matchOdds} setMatchOdds={setMatchOdds} eventData={eventData} />
          <BookmakerOddsMarket bookmaker={bookmaker} setBookmaker={setBookmaker} />
          <FancyMarketTab fancy={fancy} setFancy={setFancy} />
        </div>
        <div className="" hidden={inplayIndex !== 1}>
          <MatchOddsMarket eventData={eventData} matchOdds={matchOdds} setMatchOdds={setMatchOdds} />
        </div>
        <div className="" hidden={inplayIndex !== 2}>
          <BookmakerOddsMarket bookmaker={bookmaker} setBookmaker={setBookmaker} />
        </div>
        <div className="" hidden={inplayIndex !== 3}>
          <FancyMarketTab fancy={fancy} setFancy={setFancy} />
        </div>
      </UserLayout>
    </>
  )
}

export default InplayTab