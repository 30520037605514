import '../addEvent/addevent.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getCaller, postCaller } from '../../services/api';
import { showToast, showSuccessMessage } from "../../utilities/toastHelper";

const AddMarketSetting = () => {
    const navigate = useNavigate();
    const [sportList, setSportList] = useState([]);
    const [selectedSportId, setSelectedSportId] = useState(null);
    const [marketCount, setMarketCount] = useState('');
    const [marketSettings, setMarketSettings] = useState({});

    useEffect(() => {
      const getAllData = async () => {
        const res = await getCaller("api/v1/sports/management/getSport");

        let filteredSports = res.data.filter((sport) => ["cricket", "tennis", "soccer"].includes(sport.slug));
        filteredSports.push({ id: 0, name: "custom" });

        setSportList(filteredSports);
      };
      getAllData();
    }, []);

    useEffect(() => {
        if (selectedSportId || selectedSportId === 0) {
            const fetchMarketSettings = async () => {
                const marketListResponse = await getCaller(`api/v1/settings/defaultSettings/${selectedSportId}`);
                setMarketSettings(marketListResponse.data);
            };

            fetchMarketSettings();
        }
    }, [selectedSportId]);

    const handleSubmit = async () => {
        const updatedMarketData = {
            sport_id: selectedSportId,
            market_count: marketCount,
            market_limits: marketSettings,
        };

        try {
            const eventResponse = await postCaller(`api/v1/settings/addSportSettings`, updatedMarketData);
            if (eventResponse.message === 'SUCCESS') {
                showSuccessMessage("Market settings have been added successfully.");
                navigate('/setting/market_settings');
            } else {
                showToast(eventResponse.message, true);
            }
        } catch (error) {
            showToast("Failed to add market settings", true);
        }
    };

    const handleChange = (field, market, value) => {
        setMarketSettings(prevSettings => ({
            ...prevSettings,
            [market]: {
                ...prevSettings[market],
                [field]: value
            }
        }));
    };

    const isSubmitDisabled = (!selectedSportId && selectedSportId !== 0) || marketCount === '' || marketCount <= 0;

    return (
        <>
            <div className="wrapper">
                <div className="wrapper-head"><h3> Add New Market Limit Settings </h3></div>
                <div className="result-sport-container">
                    <div className="result-sport-content">
                        <div className="select-container">
                            <label htmlFor="sport"> Select Sport </label> <br />
                            <Select
                                options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                                onChange={(selectedOption) => setSelectedSportId(selectedOption.value)}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        outline: "none",
                                        height: "2.5rem",
                                        marginTop: "0.2rem",
                                        border: "1px solid #cbcbcb",
                                        borderRadius: "0.25rem",
                                        width: "100%",
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 20,
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        marginTop: "-7px"
                                    })
                                }}
                                placeholder="Select Sport"
                            />
                        </div>
                    </div>
                </div>

                <div className="input-container market-count">
                    <label htmlFor="marketCount"> Enter Market Count </label>
                    <input 
                        type="number" 
                        id="marketCount" 
                        value={marketCount} 
                        onChange={(e) => setMarketCount(e.target.value)} 
                        placeholder="Enter Market Count" 
                        className="market-count-input"
                        min="1"
                    />
                </div>

                <div className="market-accordian-body">
                    <div className="table-border tableRes">
                        <table width="100%">
                            <thead>
                                <tr className='headingRes'>
                                    <th>Market</th>
                                    <th>Stake Limit</th>
                                    <th>Odd Limit</th>
                                    <th>Inplay Stake Limit</th>
                                    <th>Inplay Max Odd Limit</th>
                                    <th>Max Market Limit</th>
                                    <th>Inplay Max Market Limit</th>
                                    <th>Delay</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(marketSettings).length > 0 && Object.keys(marketSettings).map((market, index) => (
                                    <tr key={index} className='custom-row'>
                                        <td>{market}</td>
                                        <td>
                                            <input 
                                                autoComplete="off"
                                                type="number"
                                                placeholder="stake limit"
                                                value={marketSettings[market].stake_limit || ''}
                                                className="league-detail-input"
                                                onChange={(e) => handleChange('stake_limit', market, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                autoComplete="off"
                                                type="number"
                                                placeholder="odd limit"
                                                value={marketSettings[market].odd_limit || ''}
                                                className="league-detail-input"
                                                onChange={(e) => handleChange('odd_limit', market, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                autoComplete="off"
                                                type="number"
                                                placeholder="inplay stake limit"
                                                value={marketSettings[market].inplay_stake_limit || ''}
                                                className="league-detail-input"
                                                onChange={(e) => handleChange('inplay_stake_limit', market, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                autoComplete="off"
                                                type="number"
                                                placeholder="inplay max odd limit"
                                                value={marketSettings[market].inplay_max_odd_limit || ''}
                                                className="league-detail-input"
                                                onChange={(e) => handleChange('inplay_max_odd_limit', market, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                autoComplete="off"
                                                type="number"
                                                placeholder="max market limit"
                                                value={marketSettings[market].max_market_limit || ''}
                                                className="league-detail-input"
                                                onChange={(e) => handleChange('max_market_limit', market, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                autoComplete="off"
                                                type="number"
                                                placeholder="inplay max market limit"
                                                value={marketSettings[market].inplay_max_market_limit || ''}
                                                className="league-detail-input"
                                                onChange={(e) => handleChange('inplay_max_market_limit', market, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                autoComplete="off"
                                                type="number"
                                                placeholder="delay"
                                                value={marketSettings[market].delay || ''}
                                                className="league-detail-input"
                                                onChange={(e) => handleChange('delay', market, e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <button className="update-league-btn centerBtn" type="button" disabled={isSubmitDisabled} onClick={handleSubmit}>Submit </button>
            </div>
        </>
    );
};

export default AddMarketSetting;