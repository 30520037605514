import '../user/user.css';
import '../addEvent/addevent.css';

import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { updateCaller } from '../../services/api';
import { showToast, showSuccessMessage } from '../../utilities/toastHelper';

const ScopeToManager = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const scope = JSON.parse(state?.passData?.scope);
    const userId = state?.passData?.id;

    const [formState, setFormState] = useState(scope);

    const handleCheckboxChange = (sectionKey, permissionKey) => {
        setFormState((prevState) => {
            const section = prevState[sectionKey];
            let updatedSection = {
                ...section,
                [permissionKey]: !section[permissionKey],
            };

            if (sectionKey !== "rollback" && permissionKey !== 'read' && !section[permissionKey] && !updatedSection.read) {
                updatedSection.read = true;
            } else if (sectionKey !== "rollback" && permissionKey === 'read' && !updatedSection.read) {
                Object.keys(updatedSection).map(sec => {
                    updatedSection[sec] = false;
                });
            }

            return {
                ...prevState,
                [sectionKey]: updatedSection,
            };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const updateResponse = await updateCaller(`api/v1/users/updateUser/${userId}`, { scope: formState });

            if (updateResponse.success) {
                showSuccessMessage('Scope has been successfully assigned to the manager');
                navigate('/user');
            } else {
                showToast(updateResponse.message, true);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="sport-container">
            <div className="modal-container-one leagueStyle" style={{ left: "54px", width: 'auto', position: "relative" }}>
                <div className="add-agent-head">
                    <div>Scope To Manager</div>
                    <div className="ai-close"><AiOutlineClose onClick={() => navigate('/user')} /></div>
                </div>

                <div className='agent-add-form'>
                    <form onSubmit={handleSubmit}>
                        <div className="table-border" style={{ border: 'none' }}>
                            <div className="scope-container">
                                {formState ? (
                                    Object.keys(formState).map((e, index) => (
                                        <div key={index}>
                                            <div className="scope-key">{e}</div>
                                            <div className="scope-values">
                                                {Object.keys(formState[e]).map((subKey) => (
                                                    <div key={subKey} style={{ marginBottom: '5px' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={formState[e][subKey]}
                                                            onChange={() => handleCheckboxChange(e, subKey)}
                                                        />
                                                        <span style={{ margin: '0px 5px' }}>{subKey}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                        <div className='change-password-container'>
                            <button type="submit" className="basic-button submit" style={{ width: "100px", left: '75%' }}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ScopeToManager;
