import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './layout/Dashboard';
import AddEvent from './pages/addEvent/AddEvent';
import CustomLeague from './pages/customGames/CustomLeague';
import CustomMatches from './pages/customGames/CustomMatches';
import CustomSport from './pages/customGames/CustomSport';
import Login from './pages/Login/Login';
import AddDownlineMatch from './pages/downline/ManagerManageMarket/AddDownlineMatch'
import Downline from './pages/downline/Downline';
import FancyResult from './pages/result/FancyResult';
import MatchResult from './pages/result/MatchResult';
import ProtectedRoute from './services/ProtectedRoute';
import ResultTab from './pages/result/ResultTab';
import CustomGamesTab from './pages/customGames/CustomGamesTab';
import CustomAddSport from './pages/customGames/CustomAddSport';
import CustomAddLeague from './pages/customGames/CustomAddLeague';
import CustomAddMatch from './pages/customGames/CustomAddMatch';
import UserChangePassword from './pages/user/UserChangePassword';
import UserCreateForm from './pages/user/UserCreateForm';
import AgentCreateForm from './pages/user/AgentCreateForm';
import PendingMatch from './pages/result/PendingMatch';
import PendingFancy from './pages/result/PendingFancy';
import AgentManageMarket from './pages/downline/agentManageMarket/AgentManageMarket'
import ShowAssignMarket from './pages/downline/ManagerManageMarket/ShowAssignMarket';
import LeagueManagement from './pages/leagueSettings/LeagueManagement';
import PageNotFound from './error/PageNotFound';
import ManageSkyBookmaker from './pages/manageOutsideMarket/ManageSkyBookmaker';
import ManageSkyFancy from './pages/manageOutsideMarket/ManageSkyFancy';
import ManageTossBookmaker from './pages/manageOutsideMarket/ManageTossBookmaker';
import ShowUser from './pages/user/ShowUser';
import SportManagement from './pages/eventManage/SportManagement';
import Home from './userPannel/Home';
import InplayTab from './userPannel/inplayAllOdds/InplayTab';
import PendingBookmaker from './pages/result/PendingBookmaker';
import ShowBookmakerResult from './pages/result/ShowBookmakerResult';
import ShowAllClients from './pages/client/ShowAllClients';

import EditClient from './pages/client/EditClient';
import AddClient from './pages/client/AddClient';
import Bets from './pages/bets/bets';
import ManageWorliMatka from './pages/worliMatka/ManageWorliMatka';
import AddWorliMatkaMatch from './pages/worliMatka/WorliMatkaAddMatch';
import WorliMatkaMatchResult from './pages/worlimatkaResults/WorliMatkaMatchResult';
import WorliMatkaPendingMatchResult from './pages/worlimatkaResults/WorliMatkaPendingMatch';
import AddRacingEvent from './pages/addRacingEvent/AddRacingEvent';
import HorseRacingLeagueCounryManagement from './pages/racingLeagueSettings/racingCountyManagement';
import HorseRacingLeagueVenueManagement from './pages/racingLeagueSettings/racingVenueManagement';
import HorseRacingManagement from './pages/racingEventManage/SportManagement';
import WorliMatkaBets from './pages/worliMatkaBets/worliMatkaBets';
import RacingResult from './pages/racingResult/racingResult';
import AssignLeagueToManager from './pages/user/AssignLeagueToManager.jsx';
import ScopeToManager from './pages/user/ScopeToManager.jsx';
import MarketSettingList from './pages/settings/MarketSettingList.jsx';
import AddMarketSetting from './pages/settings/AddMarketSetting.jsx';
import OddsGap from './pages/settings/OddsGap.jsx';
import ManageBackSetting from './pages/settings/ManageBackSetting.jsx';

function App() {
  let isAuthenticated = false;
  useEffect(() => {
    localStorage.getItem('token')
  }, [isAuthenticated])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={isAuthenticated ? <Navigate to='/dashboard' /> : <Login />} />
          <Route exact path='/dashboard' element={<ProtectedRoute Children={<Dashboard />} />} />
          <Route exact path='/home' element={<Home />} />
          <Route exact path='/inplayevents/:id' element={<InplayTab />} />
          <Route exact path='/editClient' element={<EditClient />} />
          <Route exact path="/AddClient" element={<ProtectedRoute Children={<AddClient />} />} />

          <Route exact path='/eventmanagement' element={<ProtectedRoute Children={<SportManagement />} />} />
          <Route exact path='/league_setting' element={<ProtectedRoute Children={<LeagueManagement />} />} />
          <Route exact path='/downline' element={<ProtectedRoute Children={<Downline />} />} />
          <Route exact path='/user' element={<ProtectedRoute Children={<ShowUser />} />} />
          <Route exact path='/clients' element={<ProtectedRoute Children={<ShowAllClients />} />} />
          <Route path='/downline/manager_manage_market' element={<ProtectedRoute Children={<AddDownlineMatch />} />} />
          <Route path='/downline/assign_market' element={<ProtectedRoute Children={<ShowAssignMarket />} />} />
          <Route path='/agent/manage_market' element={<ProtectedRoute Children={<AgentManageMarket />} />} />
          <Route exact path="/addevent" element={<ProtectedRoute Children={<AddEvent />} />} />
          <Route exact path="/custom" element={<ProtectedRoute Children={<CustomGamesTab />} />} />
          <Route exact path="/custom_games/custom_sport" element={<ProtectedRoute Children={<CustomSport />} />} />
          <Route exact path="/custom_games/custom_league" element={<ProtectedRoute Children={<CustomLeague />} />} />
          <Route exact path="/custom_games/custom_matches" element={<ProtectedRoute Children={<CustomMatches />} />} />
          <Route exact path="/results" element={<ProtectedRoute Children={<ResultTab />} />} />
          <Route exact path="/results/bookmaker_result" element={<ProtectedRoute Children={<ShowBookmakerResult />} />} />
          <Route exact path="/results/fancy_result" element={<ProtectedRoute Children={<FancyResult />} />} />
          <Route exact path="/results/match_result" element={<ProtectedRoute Children={<MatchResult />} />} />
          <Route exact path="/results/pending_match" element={<ProtectedRoute Children={<PendingMatch />} />} />
          <Route exact path="/results/pending_fancy" element={<ProtectedRoute Children={<PendingFancy />} />} />
          <Route exact path="/results/pending_bookmaker" element={<ProtectedRoute Children={<PendingBookmaker />} />} />
          <Route exact path="/custom_add_sport" element={<ProtectedRoute Children={<CustomAddSport />} />} />
          <Route exact path="/custom_add_league" element={<ProtectedRoute Children={<CustomAddLeague />} />} />
          <Route exact path="/custom_add_match" element={<ProtectedRoute Children={<CustomAddMatch />} />} />
          <Route exact path="/user_create_form" element={<ProtectedRoute Children={<UserCreateForm />} />} />

          <Route exact path="/create_agent" element={<ProtectedRoute Children={<AgentCreateForm />} />} />
          <Route exact path="/edit_agent" element={<ProtectedRoute Children={<UserCreateForm />} />} />
          <Route exact path="/user_change_password" element={<ProtectedRoute Children={<UserChangePassword />} />} />
          <Route exact path="/assign_league_to_manager" element={<ProtectedRoute Children={<AssignLeagueToManager />} />} />
          <Route exact path="/assign_scope_to_manager" element={<ProtectedRoute Children={<ScopeToManager />} />} />
          <Route exact path="/manage_sky_bookmaker" element={<ProtectedRoute Children={<ManageSkyBookmaker />} />} />
          <Route exact path="/manage_toss_bookmaker" element={<ProtectedRoute Children={<ManageTossBookmaker />} />} />
          <Route exact path="/manage_sky_fancy" element={<ProtectedRoute Children={<ManageSkyFancy />} />} />

          <Route exact path="/bets" element={<ProtectedRoute Children={<Bets />} />} />

          <Route exact path="/manage/worli-matka" element={<ProtectedRoute Children={<ManageWorliMatka />} />} />
          <Route exact path="/add/worli-matka/match" element={<ProtectedRoute Children={<AddWorliMatkaMatch />} />} />
          <Route exact path="/results/worli-matka-result/match_result" element={<ProtectedRoute Children={<WorliMatkaMatchResult />} />} />
          <Route exact path='/results/worli-matka-result/pending_match' element={<ProtectedRoute Children={<WorliMatkaPendingMatchResult />} />} />
          <Route exact path="/bets/worli-matka-bets" element={<ProtectedRoute Children={<WorliMatkaBets />} />} />
          <Route exact path='/addracingevent' element={<ProtectedRoute Children={<AddRacingEvent />} />} />
          <Route exact path='/league_setting/racingcountry' element={<ProtectedRoute Children={<HorseRacingLeagueCounryManagement />} />} />
          <Route exact path='/league_setting/racingvenue' element={<ProtectedRoute Children={<HorseRacingLeagueVenueManagement />} />} />
          <Route exact path='/eventmanagement/racing' element={<ProtectedRoute Children={<HorseRacingManagement />} />} />
          <Route exact path="/results/racing" element={<ProtectedRoute Children={<RacingResult />} />} />

          <Route exact path="/setting/market_settings" element={<ProtectedRoute Children={<MarketSettingList />} />} />
          <Route exact path="/setting/AddMarketSetting" element={<ProtectedRoute Children={<AddMarketSetting />} />} />
          <Route exact path="/setting/odds_gap" element={<ProtectedRoute Children={<OddsGap />} />} />
          <Route exact path="/setting/back_setting" element={<ProtectedRoute Children={<ManageBackSetting />} />} />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;