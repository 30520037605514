import React from "react";
import "../user/user.css";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { postCaller } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Button from "../../component/button/Button";
import { showToast, showSuccessMessage } from "../../utilities/toastHelper";

const AddClient = () => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
        client_name: "",
    },

    validate: (values) => {
        const errors = {};
        if (!values.client_name.trim()) {
            errors.client_name = "Client Name is required";
        }
        return errors;
    },

    onSubmit: async (values, { setSubmitting }) => {
        try {
            if (Object.keys(formik.errors).length === 0) {
                const res = await postCaller(`api/v1/clients/addClient`, values);
                if (res.success === true) {
                    showSuccessMessage("Client has been added successfully!");
                    setTimeout(() => {
                        navigate("/clients");
                    }, 0);
                } else {
                    showToast(res.message, true);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setSubmitting(false);
        }
    },
  });

  return (
    <>
        <div className="sport-container">
            <div className="modal-container-one">
                <div className="add-agent-head">
                    <div> Add Client </div>
                    <div className="ai-close">
                        <AiOutlineClose onClick={() => navigate("/clients")} />
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="agent-add-form">
                        <div className="agent-input-body">
                            <label htmlFor="client_name">{" "}Client Name <span style={{ color: "red" }}>*</span>{" "}</label>{" "}<br />
                            <input type="text" autoComplete="off" name="client_name" onBlur={formik.handleBlur} value={formik.values.client_name} onChange={formik.handleChange} className="agent_input"/>
                            {
                                formik.touched.client_name && formik.errors.client_name ? (
                                    <div className="error">{formik.errors.client_name}</div>
                                ) : null
                            }
                        </div>
                        <div className="change-password-container">
                            <Button type={"submit"} name={"Submit"} className={"submit"} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  );
};

export default AddClient;
