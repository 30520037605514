import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
	if (!isOpen) return null;

	return (
		<div
			onClick={onClose}
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				background: "rgba(0, 0, 0, 0.6)",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				animation: "fadeIn 0.3s ease",
				zIndex: 1000,
			}}
		>
			<div
				onClick={(e) => e.stopPropagation()} 
				style={{
					background: "#fff",
					borderRadius: "10px",
					boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
					padding: "20px",
					maxWidth: "400px",
					width: "100%",
					textAlign: "center",
					animation: "scaleUp 0.3s ease",
					position: 'relative',
				}}
			>
				<button
					onClick={onClose}
					style={{
						position: "absolute",
						top: "2px",
						right: "8px",
						background: "transparent",
						border: "none",
						fontSize: "15px",
						cursor: "pointer",
					}}
				>
					&#x2716;
				</button>
				{children}
			</div>
		</div>
	);
};

export default Modal;
