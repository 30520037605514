import React from 'react'
import { getCaller, updateCaller } from '../../../services/api'
import {useState ,useEffect } from 'react';
import AgentGetBookmaker from './AgentGetBookmaker';
import AgentGetAutoBookmaker from './AgentGetAutoBookmaker';
import AgentGetMainMarket from './AgentGetMainMarket';
import AgentGetFancy from './AgentGetFancy';
import '../downline.css'
import ErrorBoundary from '../../../error/ErrorBoundary';
import { showToast } from '../../../utilities/toastHelper';

const AgentManageMarket = () => {
    const [marketAssignData, setMarketAssignData] = useState([]);
    const [susTime, updateSusTime] = useState("");
    const [interval1, setInterval1] = useState("");
    const [sus, setSus] = useState(localStorage.getItem('user_sus'));
    const [isSus, setIsSus] = useState(false);
    let userType = localStorage?.getItem('user_role')?.toLowerCase();

    const setUpdateTime = () => {
        const newSus = localStorage.getItem('user_sus');
        let newTime = new Date(); 
        newTime.setSeconds(newTime.getSeconds() + +newSus);
        updateSusTime(newTime);
        setSus(newSus);
        setIsSus(false);
    }
    
    const getAssignMarket = async () => {
        if (userType==='agent'){
            const res = await getCaller(`api/v1/users/showAssignMarketAgent`);
            setUpdateTime();
            setMarketAssignData(res?.data);
            
            if(res?.data?.bookmaker?.runners){
                const runners = JSON.parse(res?.data?.bookmaker?.runners);
                if(runners){
                    res.data.bookmaker.runners = runners;
                }
            }
        }
        
    }
    
    useEffect(() => {
       
        const intervalId = setInterval(() => {
            getAssignMarket();
            
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);
   
    useEffect(()=>{

        const handleKeyPress = async (event) => {
            if (event.ctrlKey && event.keyCode === 81) {
                try {
                    const res=await updateCaller(`api/v1/users/suspend`);
                    if (res.success) {
                        showToast ("All markets are suspended" , false)
                        setSus(0);
                        setIsSus(true);
                    } else {
                        showToast (res.message , true)
                    }

                } catch (error) {
                    console.error(error);
                }
            } else if (event.ctrlKey && event.keyCode === 88) {
                try {
                    event.preventDefault();
                    const res=await updateCaller(`api/v1/users/open`);
                    if (res.success) {
                        showToast ("All markets are opened." , false)
                        setSus(1);
                        setIsSus(null);
                    } else {
                        showToast (res.message , true)
                    }

                } catch (error) {
                    console.error(error);
                }
            }
        }

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };   
    })

    useEffect(() => {
        if(susTime && sus){
            setInterval1(oldInterval => {
                clearInterval(oldInterval);
                const inter = setInterval( async () => {
                    try {
                        if(susTime && sus && (susTime < new Date())){
                            await updateCaller(`api/v1/users/suspend`);
                            clearInterval(inter);
                            setSus(0);
                            setIsSus(true);
                        }   
                    } catch (error) {
                        console.error(error);
                    }
                }, 1000);
                return inter;
            });

        }
    }, [susTime, sus])

    useEffect(() => {
        return () => {
            clearInterval(interval1);
        };
    }, []);

    return (
        <div>
            {
                !marketAssignData.id === "NO DATA FOUND" ? <ErrorBoundary /> :
                    <div className="wrapper">
                        <div>
                            {
                            marketAssignData?.market_type === "main_market" ? <AgentGetMainMarket marketAssignData={marketAssignData} setMarketAssignData={setMarketAssignData} setUpdateTime={setUpdateTime} isSus={isSus} />:
                            marketAssignData?.market_type === "bookmaker" ?  marketAssignData?.bookmaker?.is_auto ?
                            <AgentGetAutoBookmaker marketAssignData={marketAssignData} setMarketAssignData={setMarketAssignData} setUpdateTime={setUpdateTime} isSus={isSus} /> : 
                            <AgentGetBookmaker marketAssignData={marketAssignData} setMarketAssignData={setMarketAssignData} setUpdateTime={setUpdateTime} isSus={isSus} /> 
                            : marketAssignData?.market_type === "fancy" ? <AgentGetFancy marketAssignData={marketAssignData} setMarketAssignData={setMarketAssignData} setUpdateTime={setUpdateTime} isSus={isSus} /> : null}
                        </div>
                    </div>
            }
        </div>

    )
}

export default AgentManageMarket