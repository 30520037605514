import "../user/user.css";
import "../client/clients.css";
import { motion } from "framer-motion";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ClientAccordian from "./ClientAccordian";
import React, { useEffect, useState } from "react";
import Button from "../../component/button/Button";
import { deleteCaller, getCaller } from "../../services/api";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { showToast, showDeleteMessage, showConfirmationDialog } from "../../utilities/toastHelper";

const ShowAllClients = () => {
  const navigate = useNavigate();
  const [clientOpen, setClientOpen] = useState(false);
  const [oneClientData, setOneClientData] = useState([]);
  const [allClientsData, setAllClientsData] = useState([]);
  const [searchField, setSearchField] = useState("");

  const getAllClients = async () => {
    const res = await getCaller(`api/v1/clients/allClient`);
    setAllClientsData(res.data);
  };

  useEffect(() => {
    getAllClients();
  }, []);

  const onSearchChange = (e) => {
    setSearchField(e.target.value);
  };

  const filteredClients = allClientsData.filter((client) => {
    const search = searchField.toLowerCase();
    const clientId = client.client_id ? String(client.client_id).toLowerCase() : '';
    const clientName = client.client_name ? client.client_name.toLowerCase() : '';
  
    return clientId.includes(search) || clientName.includes(search);
  });

  const handleClient = async (item, i) => {
    if (clientOpen === item.client_id) {
      setClientOpen(null);
    } else {
      const res = await getCaller(`api/v1/clients/clientFindById?client_id=${item.client_id}`);
      setOneClientData(res);
      setClientOpen(item.client_id);
    }
  };

  const handleDeleteClient = async (item) => {
    const result = await showConfirmationDialog("You want to delete this Client?");

    if (result.isConfirmed) {
      const res = await deleteCaller(`api/v1/clients/deleteClient?client_id=${item.client_id}`);
      if (res.success === true) {
        showDeleteMessage("Your Client Deleted Successfully!");

        setTimeout(() => {
          getAllClients();
        }, 0);
      } else {
        showToast(res.message, true);
      }
    }
  };

  return (
    <motion.div className="league-setting-container" initial={{ opacity: 0, x: "-100vh" }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
      <div className="wrapper-head">
        <h3>All Clients</h3>
      </div>
      <div className="table-border">
        <div className="wrapper-body">
          <div className="wrapper-content">
            <div className="">
              <input type="search" autoComplete="off" name="" id="" placeholder="Search Clients" className="result-input" onChange={onSearchChange}/>
            </div>

            <div>
              <Button className={"submit"} name={"Add Clients"} type={"button"} onClick={() => navigate("/AddClient")}/>
            </div>
          </div>
        </div>

        <div style={{ overflowX: "auto" }}>
          <table width="100%">
            <thead>
              <tr className="custom-row">
                <th> Client Id </th>
                <th> Client Name </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                filteredClients?.length > 0 ? (
                  filteredClients?.map((el, i) => (
                    <React.Fragment key={i}>
                      <tr className="custom-row">
                        <td data-label="#"> {el.client_id} </td>

                        <td data-label="UID">
                          <div className={`accordion ${clientOpen === el.client_id ? "active" : "inactive"}`} onClick={() => handleClient(el, i)}>
                            {
                              clientOpen === el.client_id ? (
                                <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }}/></>
                              ) : (
                                <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }}/></>
                              )
                            }{" "}
                            {el.client_name}
                          </div>
                        </td>

                        <td>
                          <div className="user-btn-action">
                            <div className="edit-btn delete-btn" onClick={() => handleDeleteClient(el)}>
                              <MdDelete style={{ fontSize: "1.5rem", color: "white" }}/>
                            </div>
                          </div>
                        </td>
                      </tr>

                      {
                        clientOpen === el.client_id &&
                        <tr>
                          <td colSpan={10}>
                            <div className="client-container"><ClientAccordian oneClientData={oneClientData}/></div>
                          </td>
                        </tr>
                      }
                    </React.Fragment>
                  ))
                ) : (
                  <tr className="">
                    <td className="no-data-coloumn" align="center" colSpan={10}>
                      No Data
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </motion.div>
  );
};

export default ShowAllClients;
